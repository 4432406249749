import moment from 'moment'
import { useEffect } from 'react'

export const TODAY = 'today'
export const YESTERDAY = 'yesterday'
export const WEEK = 'week'
export const MONTH = 'month'
export const YEAR = 'year'
export const ALL = 'all'

const useActiveRangeFilter = (dateFilter, callback) => (
    useEffect(() => {
        if (dateFilter !== null) {
            let from
            let till
            const now = moment.utc()
            if (dateFilter === TODAY) {
                from = moment(now).clone().startOf('day')
                till = moment(now).clone().endOf('day')
            }
            if (dateFilter === YESTERDAY) {
                from = moment(now).clone().add(-1, 'days').startOf('day')
                till = moment(now).clone().add(-1, 'days').endOf('day')
            }
            if (dateFilter === WEEK) {
                from = moment(now).clone().startOf('isoweek')
                till = moment(now).clone().endOf('isoweek')
            }
            if (dateFilter === MONTH) {
                from = moment(now).clone().startOf('month')
                till = moment(now).clone().endOf('month')
            }
            if (dateFilter === YEAR) {
                from = moment(now).clone().startOf('year')
                till = moment(now).clone().endOf('month')
            }
            if (dateFilter === ALL) {
                from = moment.utc(0).clone()
                till = moment(now).clone()
            }
            callback(from, till)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter])
)

export default useActiveRangeFilter
