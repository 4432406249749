import React from 'react'
import moment from 'moment'
import {
    Switch,
    TimePicker,
} from 'antd'
import { gql } from '@apollo/client'

export const FORMAT = 'HH:mm'

const FRAGMENT = gql`
    fragment OpeningHoursItem on OpeningHour {
        id
        day
        isOpen
        openingTime
        closingTime
    }
`

const OpeningHoursItem = ({
    index,
    formState,
    openingHour,
}) => {
    const {
        hasError,
        getErrorMessages,
        getValue,
        getInputProps,
        handleChangeIsOpen,
    } = formState
    return (
        <div className="flex justify-between">
            <div className="flex flex-1 items-center ">
                <span className="w-1/3">
                    {moment().weekday(openingHour.day).format('dddd')}
                </span>
                <span className="w-2/3">
                    <Switch
                        className="mr-4"
                        checked={getValue(`openingHours.${index}.isOpen`)}
                        onChange={(checked) => handleChangeIsOpen(`openingHours.${index}`, checked)}
                    />
                    {openingHour.isOpen ? 'Open' : 'Closed'}
                </span>
            </div>
            <div className="flex flex-1 items-center">
                {openingHour.isOpen && (
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <TimePicker
                                className="w-full"
                                format={FORMAT}
                                hourStep={1}
                                minuteStep={1}
                                {...getInputProps(`openingHours.${index}.openingTime`)}
                            />
                            <span className="mx-2">
                                to
                            </span>
                            <TimePicker
                                className="w-full"
                                format={FORMAT}
                                hourStep={1}
                                minuteStep={1}
                                {...getInputProps(`openingHours.${index}.closingTime`)}
                            />
                        </div>
                        {hasError(`openingHours.${index}.openingTime`) && (
                            getErrorMessages(`openingHours.${index}.openingTime`).map((message) => (
                                <span
                                    key={`${message}/${index}`}
                                    className="text-red-400"
                                >
                                    {message}
                                </span>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

OpeningHoursItem.FRAGMENT = FRAGMENT

export default OpeningHoursItem
