import React from 'react'
import { Popconfirm, Tooltip } from 'antd'
import {
    EyeOutlined,
    EyeInvisibleOutlined,
} from '@ant-design/icons'
import usePermissions from '../../hooks/usePermisssions'

const getPopconfirmMessage = (available) => {
    if (available) {
        return 'Are you sure you want to make this asset unavailable?'
    }
    return 'Are you sure you want to make this asset available?'
}

const AssetAvailabilityButton = ({
    available,
    onClick,
}) => {
    const { isAdmin } = usePermissions()
    if (!isAdmin) {
        if (available) {
            return <EyeOutlined style={{ cursor: 'not-allowed' }} />
        }
        return (
            <EyeInvisibleOutlined style={{ cursor: 'not-allowed' }} />
        )
    }
    return (
        <Popconfirm
            title={getPopconfirmMessage(available)}
            onConfirm={onClick}
            okText="Yes"
            cancelText="No"
        >
            {(() => {
                if (available) {
                    return (
                        <Tooltip title="Disable asset">
                            <EyeOutlined />
                        </Tooltip>
                    )
                }
                return (
                    <Tooltip title="Enable asset">
                        <EyeInvisibleOutlined />
                    </Tooltip>
                )
            })()}
        </Popconfirm>
    )
}

export default AssetAvailabilityButton
