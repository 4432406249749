import { gql, useMutation } from '@apollo/client'
import AssetForm from './AssetForm'
import PriceAdjustmentsTable from './PriceAdjustmentsTable'

const mutation = gql`
    mutation updateAsset($id: Int!, $input: UpdateAssetInput!) {
        updateAsset(id: $id, input: $input) {
            asset {
                ...AssetForm
                productVariations {
                    ...PriceAdjustmentsTable
                }
            }
        }
    }
    ${AssetForm.FRAGMENT}
    ${PriceAdjustmentsTable.FRAGMENT}
`

const useUpdateAssetMutation = (options) => (
    useMutation(mutation, options)
)

export default useUpdateAssetMutation
