import useAppState from './useAppState'

export const ROLES = {
    ADMIN: 'admin',
    CUSTOMER: 'customer',
}

const usePermissions = () => {
    const { currentUser, isLoading } = useAppState()
    if (isLoading) {
        return {
            isAdmin: false,
            isCustomer: false,
        }
    }
    const { slug } = currentUser.role
    return {
        isAdmin: slug === ROLES.ADMIN,
        isCustomer: slug === ROLES.CUSTOMER,
    }
}

export default usePermissions
