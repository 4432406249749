import React from 'react'
import { notification } from 'antd'
import { useParams } from 'react-router-dom'
import * as routes from '../../../routes'
import SettingsPageError from '../../layout/SettingsPageError'
import UpdateAssetPage from './UpdateAssetPage'
import useUpdateAssetMutation from './useUpdateAssetMutation'
import useUpdateAssetFormState from './useUpdateAssetFormState'
import useUpdateAssetPageQuery from './useUpdateAssetPageQuery'
import useAppState from '../../hooks/useAppState'

const activeMenuItem = 'assets'

const UpdateAssetPageWithState = () => {
    const params = useParams()
    const { assetId } = params
    const { units, currencies } = useAppState()
    const [updateAsset, { loading: isUpdating }] = useUpdateAssetMutation()
    const {
        error,
        isFetching,
        asset,
    } = useUpdateAssetPageQuery({ variables: { id: parseInt(assetId, 10) } })
    const formState = useUpdateAssetFormState(asset, { units, currencies })

    if (error) {
        return (
            <SettingsPageError
                activeMenuItem={activeMenuItem}
            />
        )
    }

    const handleSubmit = async () => {
        const isValid = formState.validate()
        if (isValid) {
            try {
                const input = formState.valuesToInput()
                const { data } = await updateAsset({
                    variables: {
                        id: asset.id,
                        input,
                    }
                })
                notification.success({
                    message: 'Asset successfully updated!',
                    description: `Asset ${data.updateAsset.asset.name} was successfully updated!`,
                })
            } catch (e) {
                notification.error({
                    message: 'Asset not updated!',
                    description: `Something went wrong. The following error occured: ${e}`,
                })
            }
        }
    }

    return (
        <UpdateAssetPage
            routes={routes}
            isLoading={isFetching}
            isUpdating={isUpdating}
            formState={formState}
            onSubmit={handleSubmit}
            activeMenuItem={activeMenuItem}
        />
    )
}

export default UpdateAssetPageWithState
