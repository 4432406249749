import React from 'react'
import { Input, Button, Form } from 'antd'
import FormItem from '../../util/form/FormItem'
import OrderSummary from './OrderSummary'

const CreateOrderAuthenticateForm = ({
    formState,
    isLoading,
    assetName,
    onCancel,
    onConfirm,
    productVariation,
}) => {
    const {
        values,
        errors,
        getNativeInputProps,
    } = formState
    const customerName = typeof values.customer !== 'undefined' ? values.customer.name : ''
    return (
        <>
            <OrderSummary
                side={values.side}
                assetName={assetName}
                customerName={customerName}
                quantity={values.quantity}
                unitCode={productVariation.unitCode}
                currencyCode={productVariation.currencyCode}
                spotPrice={values.spotPrice}
            />
            <p>Confirm the order by entering your password.</p>
            <Form>
                <FormItem error={errors.password}>
                    <Input
                        type="password"
                        placeholder="Fill in your password"
                        disabled={isLoading}
                        {...getNativeInputProps('password')}
                    />
                </FormItem>
                <div className="flex align-center justify-between">
                    <Button
                        type="secondary"
                        disabled={isLoading}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!values.password || isLoading}
                        onClick={onConfirm}
                    >
                        Confirm
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default CreateOrderAuthenticateForm
