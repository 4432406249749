import React from 'react'
import clsx from 'clsx'
import { Select, InputNumber } from 'antd'
import roundTo from 'round-to'
import { calculateStep } from '@gunvor-trading/shared/utils/units'
import { getRoundingForUnit, unitTo, getPreferredUnits } from '../../../util/units'
import * as constants from '../../../util/constants'
import PreferredUnits from '../PreferredUnits'

const { Option } = Select
const {
    UNIT_CODE_MILLIGRAM: MG,
} = constants

const convertAndRoundQuantity = (quantity, unitCode, newUnitCode) => {
    const convertedQuantity = unitTo(quantity, unitCode, newUnitCode)
    const roundedQuantity = roundTo(convertedQuantity, getRoundingForUnit(newUnitCode))
    return roundedQuantity
}

const UnitInputWithConverter = ({
    error,
    value,
    onChange,
    unitCode,
    onChangeUnitCode,
    code,
    isRounded = true,
    disabled,
}) => {
    const units = getPreferredUnits(code)

    return (
        <PreferredUnits code={code}>
            {() => (
                <div>
                    <div className="flex">
                        <div className="w-full">
                            <InputNumber
                                className="w-full"
                                value={convertAndRoundQuantity(value, MG, unitCode)}
                                decimalSeparator=","
                                onChange={(input) => {
                                    const convertedInput = unitTo(input, unitCode, MG)
                                    if (convertedInput) {
                                        onChange(convertedInput)
                                    }
                                }}
                                precision={getRoundingForUnit(unitCode)}
                                disabled={disabled}
                                min={0}
                                step={calculateStep(isRounded, unitCode)}
                            />
                        </div>
                        <div className="w-32">
                            <Select
                                className={
                                    clsx(
                                        'border-gray-200',
                                        typeof error !== 'undefined' && 'border-red-500',
                                    )
                                }
                                value={unitCode}
                                onChange={onChangeUnitCode}
                                disabled={disabled}
                            >
                                {units.map((unit) => (
                                    <Option
                                        key={unit}
                                        value={unit}
                                    >
                                        {unit}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <p className="text-sm text-red-500">
                        {error}
                    </p>
                </div>
            )}
        </PreferredUnits>
    )
}

export default UnitInputWithConverter
