import { useLazyQuery, gql } from '@apollo/client'

export const CREATE_ORDER_QUERY = gql`
    query createOrderQuery($customerId: Int!) {
        customerAssets(customerId: $customerId) {
            id
            asset {
                id
                name
                code
            }
            productVariations {
                id
                name
                currencyCode
                unitCode
            }
        }
    }
`

const useCreateOrderQuery = () => {
    const [
        getData,
        {
            error,
            loading,
            data = {},
        }
    ] = useLazyQuery(CREATE_ORDER_QUERY)

    const {
        customerAssets = [],
    } = data

    return ([
        getData,
        {
            error,
            isFetching: loading,
            customerAssets,
        }
    ])
}

export default useCreateOrderQuery
