import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import MarketDataPlaceholder from './MarketDataPlaceholder'
import CustomerMarketDataWithQuery from './CustomerMarketDataWithQuery'

const CustomerMarketDataContainer = ({
    customerId,
    side,
    productVariationId,
    assetCode,
    unitCode,
    currencyCode,
    onCompleteMarketData,
}) => {
    const hasSelectedAllRequiredFields = (
        typeof customerId !== 'undefined'
        && typeof side !== 'undefined'
        && typeof assetCode !== 'undefined'
        && productVariationId !== null
        && unitCode !== null
        && currencyCode !== null
    )
    return (
        <div className="flex items-center justify-center h-40">
            {hasSelectedAllRequiredFields
                ? (
                    <CustomerMarketDataWithQuery
                        customerId={customerId}
                        side={side}
                        productVariationId={productVariationId}
                        assetCode={assetCode}
                        unitCode={unitCode}
                        currencyCode={currencyCode}
                        onCompleteMarketData={onCompleteMarketData}
                    />
                )
                : (
                    <MarketDataPlaceholder
                        text="Select a customer and product variation to see the spotprice."
                        icon={(
                            <SearchOutlined
                                className="text-3xl mr-4"
                            />
                        )}
                    />
                )}
        </div>
    )
}

export default CustomerMarketDataContainer
