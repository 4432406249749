import React from 'react'
import { Button } from 'antd'
import UpdateCustomerOpeningHoursItem from './UpdateCustomerOpeningHoursItem'

const UpdateOpeningHoursForm = ({
    isUpdating,
    formState,
    onUpdate,
}) => {
    const { values } = formState
    return (
        <form className="w-full lg:w-5/6 p-4 rounded-lg bg-gray-50">
            {values.customerOpeningHours.map((openingHour, index) => (
                <div
                    key={openingHour.id}
                    className="mb-4"
                >
                    <UpdateCustomerOpeningHoursItem
                        path={`customerOpeningHours.${index}`}
                        formState={formState}
                    />
                </div>
            ))}
            <div className="flex justify-end">
                <Button
                    loading={isUpdating}
                    disabled={isUpdating}
                    type="primary"
                    onClick={onUpdate}
                >
                    Update
                </Button>
            </div>
        </form>
    )
}

export default UpdateOpeningHoursForm
