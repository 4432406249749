import React from 'react'
import { Input, Form } from 'antd'
import ConfirmModal from './ConfirmModal'
import FormState from './form/FormState'

const INITIAL_VALUES = {
    safeWord: '',
}

const ConfirmInputModal = ({
    children,
    safeWord,
    onConfirm,
    ...props
}) => (
    <FormState
        initialValues={INITIAL_VALUES}
    >
        {({
            values,
            handleChange,
        }) => (
            <ConfirmModal
                confirmDisabled={values.safeWord !== safeWord}
                onConfirm={onConfirm}
                {...props}
            >
                {children}

                <Form onFinish={onConfirm}>
                    <Input
                        name="safeWord"
                        value={values.safeWord}
                        onChange={handleChange}
                    />
                </Form>
            </ConfirmModal>
        )}
    </FormState>
)

export default ConfirmInputModal
