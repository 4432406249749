import useDashdotFormState, { createFormValidation as dashdotCreateFormValidation } from 'use-form-state'

export const createFormValidation = dashdotCreateFormValidation

export default function useFormState(initialValues, options) {
    const formState = useDashdotFormState(initialValues, options)
    return {
        ...formState,
        antdErrors: new Map(formState.errors.map((i) => [i.path, i.message])),
    }
}
