import React from 'react'
import { Tag, Popover } from 'antd'
import CurrencyValue from '../../../../util/CurrencyValue'
import CustomerAssetLogItem from './CustomerAssetLogItem'
import * as constants from '../../../../../util/constants'

const {
    ADJUSTMENT_ACCURACY,
    CONVERSION_ACCURACY,
    DUTIES_ROUNDING,
    DUTIES_ACCURACY,
    PRICE_ACCURACY,
} = constants

const CustomerAssetUpdatedItem = ({
    appEvent,
}) => {
    const {
        customer, createdAt, user, payload,
    } = appEvent
    const {
        name,
        code,
        productVariations = [],
        premium,
        density,
        conversionRateExtra,
    } = payload
    return (
        <CustomerAssetLogItem
            createdAt={createdAt}
            user={user}
        >
            <div>
                <strong>{name} ({code})</strong>
                {' '}
                for
                {' '}
                <strong>{customer.name}</strong>
                {' '}
                updated.
                {' '}
                <Popover
                    trigger="click"
                    content={(
                        <ul>
                            <li>Name: <strong>{name}</strong></li>
                            <li>Code: <strong>{code}</strong></li>
                            <li>Premium: <strong><CurrencyValue value={premium} /></strong></li>
                            <li>Conversion rate extra:
                                <strong>{conversionRateExtra / CONVERSION_ACCURACY}</strong>
                            </li>
                            <li>Density: <strong>{density / ADJUSTMENT_ACCURACY}</strong></li>
                            {productVariations.map(({
                                name: variationName,
                                unitCode,
                                currencyCode,
                                loadingFee,
                                bioFee,
                                duties,
                            }) => (
                                <li key={`${variationName}/${currencyCode}/${unitCode}`}>
                                    {variationName}/{currencyCode}/{unitCode}
                                    <ul>
                                        <li>
                                            Loading fee:
                                            {' '}
                                            <strong>
                                                <CurrencyValue
                                                    formatValue={false}
                                                    value={loadingFee / PRICE_ACCURACY}
                                                />
                                            </strong>
                                        </li>
                                        <li>
                                            Bio fee:
                                            {' '}
                                            <strong>
                                                <CurrencyValue
                                                    formatValue={false}
                                                    value={bioFee / PRICE_ACCURACY}
                                                />
                                            </strong>
                                        </li>
                                        <li>
                                            Duties:
                                            {' '}
                                            <strong>
                                                <CurrencyValue
                                                    formatValue={false}
                                                    rounding={DUTIES_ROUNDING}
                                                    value={duties / DUTIES_ACCURACY}
                                                />
                                            </strong>
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    )}
                >
                    <Tag>Data</Tag>
                </Popover>
            </div>
        </CustomerAssetLogItem>
    )
}

export default CustomerAssetUpdatedItem
