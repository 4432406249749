import React from 'react'
import {
    Tag,
    Table,
    Button,
} from 'antd'
import {
    MoreOutlined,
    DownloadOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import InfiniteScroll from '../../util/InfiniteScroll'
import CurrencyValue from '../../util/CurrencyValue'
import * as constants from '../../../util/constants'
import { getCurrencySymbol } from '../../../util/currency'
import CustomerOrdersTableDetails from './CustomerOrdersTableDetails'
import CustomerOrdersTableActionDropdown from './CustomerOrdersTableActionDropdown'

const {
    SIDE_SELL,
    CONVERSION_ACCURACY,
} = constants

const getColumns = ({
    onEditOrder,
    onExportOrders,
}) => [{
    title: (
        <Button
            type="primary"
            icon={<DownloadOutlined />}
            size="small"
            onClick={onExportOrders}
            ghost
        />
    ),
    key: 'side',
    dataIndex: 'side',
    render: (side) => (
        <Tag
            color={side === SIDE_SELL ? 'green' : 'blue'}
        >
            {side}
        </Tag>
    ),
    align: 'center',
}, {
    dataIndex: 'reference',
    title: 'Ref.',
    render: (reference) => reference !== null && reference,
    align: 'left',
}, {
    dataIndex: 'createdAt',
    title: 'Traded on',
    render: (createdAt) => createdAt !== null && moment(createdAt).format('DD/MM'),
    align: 'left',
}, {
    title: 'Asset',
    key: 'asset',
    dataIndex: 'asset',
    render: (asset) => (
        `${asset.name} - ${asset.code}`
    ),
    align: 'center',
}, {
    key: 'quantity',
    title: 'Transfer',
    dataIndex: 'quantity',
    render: (quantity, {
        quantityInUnit,
        quantityUnitCode,
    }) => quantity !== null && (
        <>
            {quantityInUnit}
            {quantityUnitCode}
        </>
    ),
}, {
    dataIndex: 'price',
    title: 'Price',
    render: (price, order) => price !== null && (
        <>
            <CurrencyValue
                value={price}
                currencyCode={order.currencyCode}
                displayCurrencyCode={getCurrencySymbol(order.currencyCode)}
            />
            /
            {order.priceUnitCode}
        </>
    ),
    align: 'left',
}, {
    dataIndex: 'currencyConversion',
    title: 'EUR/USD',
    render: (currencyConversion) => currencyConversion !== null && (
        currencyConversion / CONVERSION_ACCURACY
    ),
}, {
    title: 'Action',
    dataIndex: 'uid',
    render: (uid, order) => (
        <CustomerOrdersTableActionDropdown
            order={order}
            onEditOrder={onEditOrder}
        >
            <Button
                ghost
                icon={<MoreOutlined />}
                type="primary"
                shape="circle"
                className="ant-btn-no-border"
            />
        </CustomerOrdersTableActionDropdown>
    ),
    align: 'center',
}]

const CustomerOrdersTable = ({
    loading,
    filters,
    customerOrders,
    isFetchingMore,
    hasMore,
    onEditOrder,
    onFetchMore,
    onExportOrders,
    onApplyStatusFilter,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        hasMore={hasMore}
        onFetchMore={onFetchMore}
    >
        <Table
            rowKey="uid"
            columns={getColumns({
                filters,
                onEditOrder,
                onExportOrders,
                onApplyStatusFilter,
            })}
            dataSource={customerOrders}
            pagination={false}
            loading={loading}
            expandedRowRender={CustomerOrdersTableDetails}
        />
    </InfiniteScroll>
)

export default CustomerOrdersTable
