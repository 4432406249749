export const delay = (time, value) => new Promise((resolve) => {
    setTimeout(resolve, time, value)
})

export const addToCollection = (obj) => (collection) => [...collection, obj]

export const addToTopOfCollection = (obj) => (collection) => [obj, ...collection]

export const replaceInCollectionWhereId = (obj) => (collection) => collection.map((item) => (
    item.id === obj.id ? obj : item
))

export const replaceEdgeInCollectionWhereNode = (obj) => (collection) => collection.map((edge) => (
    edge.node.id === obj.id ? { ...edge, node: obj } : edge
))

export const removeFromCollectionWhereId = (id) => (collection) => collection.filter(
    (item) => item.id !== id
)

export const removeEdgeFromCollectionWhereNodeId = (id) => (collection) => collection.filter(
    (edge) => edge.node.id !== id
)

export const whereMatchingPriceAdjustment = (unitCode, currencyCode) => (adjustment) => (
    adjustment.unitCode === unitCode
    && adjustment.currencyCode === currencyCode
)

export const where = (...keys) => (...values) => (obj) => keys.every((key) => (
    key.includes('!') ? !values.includes(obj[key.replace('!', '')]) : values.includes(obj[key])
))

export const removeSpaces = (string) => string.replace(/\s+/g, '')

export const flatten = (a, b) => [...a, ...b]

export const xor = (...arrays) => arrays.reduce(
    (newArray, currentArray) => newArray.concat(
        (currentArray.filter((item) => {
            if (!newArray.includes(item)) {
                return item
            }
            newArray.splice(newArray.indexOf(item), 1)
            return null
        })),
    ), [],
)

export const createRange = (value, percentage = 10) => {
    const max = value * (1 + percentage / 100)
    const min = value * (1 - percentage / 100)
    return { max, min }
}

export const omitNull = (obj) => {
    const newObj = { ...obj }
    Object.keys(obj).filter((k) => obj[k] === null).forEach((k) => delete (newObj[k]))
    return newObj
}
