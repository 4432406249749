import React from 'react'
import CustomerAssetLogItem from './CustomerAssetLogItem'

const CustomerAssetCreatedItem = ({
    appEvent
}) => {
    const {
        customer, user, createdAt, payload
    } = appEvent
    const { name, code } = payload
    return (
        <CustomerAssetLogItem
            isCreated
            createdAt={createdAt}
            user={user}
        >
            <div>
                <strong>{name} ({code})</strong>
                {' '}
                for
                {' '}
                <strong>{customer.name || 'Deleted Customer'}</strong>
                {' '}
                added.
            </div>
        </CustomerAssetLogItem>
    )
}

export default CustomerAssetCreatedItem
