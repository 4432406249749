import dotProp from 'dot-prop-immutable'
import useFormState from '../../hooks/useFormState'
import { validation } from '../update-asset/useUpdateAssetFormState'
import {
    PRICE_ACCURACY,
    ADJUSTMENT_ACCURACY,
    CONVERSION_ACCURACY,
    DUTIES_ACCURACY,
} from '../../../util/constants'

const toFormValues = ({
    asset = {},
    density,
    premium,
    conversionRateExtra,
    productVariations = []
}) => ({
    asset,
    name: asset.name,
    code: asset.code,
    premium: premium !== null ? premium / PRICE_ACCURACY : null,
    density: density !== null ? density / ADJUSTMENT_ACCURACY : null,
    conversionRateExtra: conversionRateExtra !== null
        ? conversionRateExtra / CONVERSION_ACCURACY : null,
    productVariations: productVariations.map((adjustment) => ({
        id: adjustment.id,
        name: adjustment.name !== null ? adjustment.name : adjustment.productVariation.name,
        unitCode: adjustment.unitCode,
        currencyCode: adjustment.currencyCode,
        bioFee: adjustment.bioFee !== null ? adjustment.bioFee / PRICE_ACCURACY : null,
        loadingFee: adjustment.loadingFee !== null ? adjustment.loadingFee / PRICE_ACCURACY : null,
        duties: adjustment.duties !== null ? adjustment.duties / DUTIES_ACCURACY : null,
        productVariation: adjustment.productVariation
    })),
})

const valuesToInput = ({
    density,
    premium,
    conversionRateExtra,
    productVariations,
}) => ({
    density: density !== null ? density * ADJUSTMENT_ACCURACY : null,
    premium: premium !== null ? premium * PRICE_ACCURACY : null,
    conversionRateExtra: conversionRateExtra !== null
        ? conversionRateExtra * CONVERSION_ACCURACY : null,
    productVariations: productVariations.map(({
        id,
        bioFee,
        loadingFee,
        duties,
    }) => ({
        id,
        bioFee: bioFee !== null ? Math.round(bioFee * PRICE_ACCURACY) : null,
        loadingFee: loadingFee !== null ? Math.round(loadingFee * PRICE_ACCURACY) : null,
        duties: duties !== null ? Math.round(duties * DUTIES_ACCURACY) : null,
    }))
})

const useUpdateCustomerAssetFormState = (customerAsset, options) => {
    const formState = useFormState(
        toFormValues(customerAsset),
        {
            validation,
            valuesToInput,
            ...options,
        },
    )

    const handleAddCustomerAssetValue = (path, finalPath, accuracy = 1) => {
        const newValue = dotProp.get(formState.values, finalPath) / accuracy
        formState.handleChange(path, newValue)
    }

    const handleRemoveCustomerAssetValue = (path) => {
        formState.handleChange(path, null)
    }

    return {
        ...formState,
        handleAddCustomerAssetValue,
        handleRemoveCustomerAssetValue,
    }
}

export default useUpdateCustomerAssetFormState
