import { createFormValidation } from 'use-form-state'
import useFormState from '../../hooks/useFormState'
import * as formValidation from '../../../validation'
import * as constants from '../../../util/constants'

const {
    SIDE_BUY,
} = constants

const validation = createFormValidation([
    {
        path: 'assetId',
        validate: formValidation.isValue,
        message: 'Please select a asset.',
    }, {
        path: 'quantity',
        validate: (value) => (
            formValidation.isValue(value) && formValidation.isGreaterThan(0)(value)
        ),
        message: 'Quantity must be greater than 0.',
    }
])

const valuesToInput = ({
    assetId,
    side,
    password,
    spotPrice,
    quantity,
    currencyConversion,
}) => ({
    assetId,
    side,
    password,
    spotPrice: parseInt(spotPrice * 100, 10),
    quantity,
    currencyConversion,
})

const useCreateOrderForm = (initialValues, options) => (
    useFormState(
        {
            spotPrice: 0,
            quantity: 0,
            side: SIDE_BUY,
            ...initialValues,
        },
        {
            validation,
            valuesToInput,
            ...options,
        },
    )
)

export default useCreateOrderForm
