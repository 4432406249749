import { gql, useQuery } from '@apollo/client'

export const APP_SETTINGS_PAGE_QUERY = gql`
    query appSettings {
        appSettings {
            minTradeLimit
            maxTradeLimit
            tradeLimitUnitCode
        }
    }
`

const useFetchAppSettingsPageData = () => {
    const {
        data = {},
        loading,
        error,
    } = useQuery(APP_SETTINGS_PAGE_QUERY)
    const { appSettings = {} } = data

    return {
        error,
        isFetching: loading,
        appSettings,
    }
}

export default useFetchAppSettingsPageData
