import { createFormValidation } from 'use-form-state'
import * as constants from '@gunvor-trading/shared/utils/constants'
import useFormState from '../../hooks/useFormState'

const {
    UNIT_CODE_METRIC_TON: TON,
} = constants

const customerToFormValues = ({
    name,
    minTradeLimit: customerMinTradeLimit,
    maxTradeLimit: customerMaxTradeLimit,
    tradeLimitUnitCode: customerTradeLimitUnitCode,
    tradeLimit = {},
}) => {
    const areTradeLimitsSet = customerMinTradeLimit !== null
        && typeof customerMinTradeLimit !== 'undefined'
        && customerMaxTradeLimit !== null
        && typeof customerMaxTradeLimit !== 'undefined'
        && customerTradeLimitUnitCode !== null
        && typeof customerTradeLimitUnitCode !== 'undefined'
    const minTradeLimit = customerMinTradeLimit || tradeLimit.minTradeLimit || 0
    const maxTradeLimit = customerMaxTradeLimit || tradeLimit.maxTradeLimit || 0
    const tradeLimitUnitCode = customerTradeLimitUnitCode || tradeLimit.tradeLimitUnitCode || TON

    return {
        name,
        areTradeLimitsSet,
        minTradeLimit,
        maxTradeLimit,
        tradeLimitUnitCode,
    }
}

const validation = createFormValidation([{
    path: 'name',
    validate: (name) => typeof name !== 'undefined' && name !== null && name !== '',
    message: 'Name is required',
}, {
    path: 'minTradeLimit',
    validate: (minTradeLimit) => typeof minTradeLimit !== 'undefined' && minTradeLimit > 0,
    message: 'Minimum trade limit is required and must be larger than 0',
}, {
    path: 'maxTradeLimit',
    validate: (maxTradeLimit) => typeof maxTradeLimit !== 'undefined' && maxTradeLimit > 0,
    message: 'Maximum trade limit is required and must be larger than 0',
}])

const toCustomerInput = ({
    name,
    areTradeLimitsSet,
    minTradeLimit,
    maxTradeLimit,
    tradeLimitUnitCode,
}) => {
    const inputMinTradeLimit = areTradeLimitsSet ? minTradeLimit : null
    const inputMaxTradeLimit = areTradeLimitsSet ? maxTradeLimit : null
    const inputTradeLimitUnitCode = areTradeLimitsSet ? tradeLimitUnitCode : null
    return {
        name,
        minTradeLimit: inputMinTradeLimit,
        maxTradeLimit: inputMaxTradeLimit,
        tradeLimitUnitCode: inputTradeLimitUnitCode,
    }
}

const useUpdateCustomerSettingsForm = (customer, options) => {
    const formState = useFormState(
        customerToFormValues(customer),
        {
            validation,
            valuesToInput: toCustomerInput,
            ...options,
        },
    )

    const { areTradeLimitsSet } = formState.values
    const handleEditTradeLimits = () => {
        if (areTradeLimitsSet === true) {
            return formState.setValues({
                areTradeLimitsSet: !areTradeLimitsSet,
                minTradeLimit: customer.tradeLimit.minTradeLimit,
                maxTradeLimit: customer.tradeLimit.maxTradeLimit,
                tradeLimitUnitCode: customer.tradeLimit.tradeLimitUnitCode,
            })
        }
        return formState.handleChange('areTradeLimitsSet', !areTradeLimitsSet)
    }

    return {
        ...formState,
        handleEditTradeLimits,
    }
}

export default useUpdateCustomerSettingsForm
