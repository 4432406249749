import React from 'react'
import * as constants from '../../../../util/constants'
import Loading from '../../../util/Loading'
import Tag from '../../../util/Tag'
import AssetCardPrice from './AssetCardPrice'
import AssetCardSelect from './AssetCardSelect'

const { SIDE_BUY } = constants
const STATUS_UNAVAILABLE = 'UNAVAILABLE'

const AssetCard = ({
    isFetching,
    asset,
    marketData = {},
    currencyCode,
    currencySymbol,
    unitCode,
    productVariationId,
    onChangeProductVariationId,
}) => {
    const {
        name,
        suspended: isSuspended,
        available: isAvailable,
        productVariations,
    } = asset
    const {
        status,
        previousBuyPrice,
        buyPrice,
    } = marketData

    let isTrading = true
    let headerClass = 'flex justify-between mb-4'
    if (status === STATUS_UNAVAILABLE) {
        isTrading = false
        headerClass = 'flex justify-between'
    }
    const hasProductVariation = asset.productVariations.length > 0

    return (
        <div className="w-full md:w-2/3 lg:w-1/2 mb-8 border-2 border-gray-200 p-4">
            {isFetching ? (
                <div className="flex justify-center items-center">
                    <Loading />
                </div>
            ) : (
                <>
                    <div className={headerClass}>
                        <h1 className="text-3xl mb-0">{name}</h1>
                        {isTrading
                            ? (
                                <div className="flex">
                                    <div className="mt-1">
                                        {(() => {
                                            if (!isAvailable) {
                                                return <Tag>UNAVAILABLE</Tag>
                                            }
                                            if (isSuspended) {
                                                return <Tag>SUSPENDED</Tag>
                                            }
                                            return null
                                        })()}
                                    </div>
                                    {hasProductVariation && (
                                        <AssetCardSelect
                                            productVariationId={productVariationId}
                                            onChangeProductVariationId={onChangeProductVariationId}
                                            productVariations={productVariations}
                                        />
                                    )}
                                </div>
                            )
                            : (
                                <div className="mt-4">
                                    <Tag>MARKET CLOSED</Tag>
                                </div>
                            )}
                    </div>
                    {!hasProductVariation && (
                        <p>No price adjustments available</p>
                    )}
                    {isTrading && hasProductVariation && (
                        <AssetCardPrice
                            side={SIDE_BUY}
                            currencyCode={currencyCode}
                            currencySymbol={currencySymbol}
                            unitCode={unitCode}
                            previousPrice={previousBuyPrice}
                            price={buyPrice}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default AssetCard
