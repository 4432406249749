import * as validation from '../index'

export const isValidEmail = (email) => (
    validation.isValue(email)
    && validation.isEmail(email)
)

export const isValidPassword = (email) => (
    validation.isValue(email)
)

export const isValidLoginForm = (values) => (
    isValidEmail(values.email)
    && isValidPassword(values.password)
)
