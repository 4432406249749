import React from 'react'
import * as cache from '../../../util/cache'
import FormState from '../../util/form/FormState'
import { graphqlErrorsToFormErrorsDEPRECATED } from '../../../util/graphql'
import useAppState from '../../hooks/useAppState'
import { isValidChoosePasswordForm } from '../../../validation/choose-password/choosePassword'
import { getChoosePasswordFormErrors } from '../../../validation/choose-password/choosePasswordErrors'
import ChoosePasswordForm from './ChoosePasswordForm'
import useChoosePasswordMutation from './useChoosePasswordMutation'

const ChoosePasswordWithState = ({
    resetToken,
    email,
}) => {
    const appState = useAppState()
    const [choosePassword, { loading }] = useChoosePasswordMutation()
    return (
        <FormState
            isValidForm={isValidChoosePasswordForm}
            getFormErrors={getChoosePasswordFormErrors}
            initialValues={{
                password: '',
                confirmPassword: '',
            }}
        >
            {({
                values,
                errors,
                checkValidation,
                handleChange,
                updateErrors,
            }) => (
                <ChoosePasswordForm
                    isLoading={loading}
                    values={values}
                    errors={errors}
                    onChange={handleChange}
                    onSubmit={async () => {
                        if (checkValidation()) {
                            try {
                                const response = await choosePassword({
                                    variables: {
                                        input: {
                                            token: resetToken,
                                            email,
                                            password: values.password,
                                        },
                                    },
                                })
                                await cache.setAccessToken(response.data.choosePassword.accessToken)
                                appState.refresh()
                            } catch (e) {
                                updateErrors(graphqlErrorsToFormErrorsDEPRECATED(e.graphQLErrors))
                            }
                        }
                    }}
                />
            )}
        </FormState>
    )
}

export default ChoosePasswordWithState
