import React from 'react'
import dotProp from 'dot-prop-immutable'
import { InputNumber, Button } from 'antd'
import {
    EditOutlined,
    CloseOutlined,
} from '@ant-design/icons'
import { PRICE_ACCURACY } from '../../../util/constants'

const PriceAdjustmentInputNumber = ({
    name,
    finalName,
    formState,
    accuracy = PRICE_ACCURACY,
    onAdd,
    onRemove,
    ...inputProps
}) => {
    const {
        values,
        getInputProps,
    } = formState
    const originalValue = dotProp.get(values, name)
    let finalValue = originalValue
    if (finalValue === null) {
        finalValue = dotProp.get(values, finalName) / accuracy
    }
    return (
        <div className="flex">
            <InputNumber
                {...getInputProps(name)}
                disabled={originalValue === null}
                min={0}
                value={finalValue}
                className="flex-1"
                {...inputProps}
            />
            {originalValue === null ? (
                <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => onAdd(name, finalName, accuracy)}
                />
            ) : (
                <Button
                    danger
                    icon={<CloseOutlined />}
                    onClick={() => onRemove(name, finalName, accuracy)}
                />
            )}
        </div>
    )
}

export default PriceAdjustmentInputNumber
