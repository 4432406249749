import React from 'react'
import FormState from '../../util/form/FormState'
import { graphqlErrorsToFormErrorsDEPRECATED } from '../../../util/graphql'
import { isValidForgotPasswordForm } from '../../../validation/forgot-password/forgotPassword'
import { getForgotPasswordFormErrors } from '../../../validation/forgot-password/forgotPasswordErrors'
import ForgotPasswordForm from './ForgotPasswordForm'
import useResetPasswordMutation from './useResetPasswordMutation'

const ForgotPasswordWithState = () => {
    const [resetPassword, { loading, error }] = useResetPasswordMutation()
    return (
        <FormState
            isValidForm={isValidForgotPasswordForm}
            getFormErrors={getForgotPasswordFormErrors}
            inititalState={{
                passwordIsReset: false,
            }}
            initialValues={{
                email: '',
            }}
        >
            {({
                state,
                setState,
                values,
                errors,
                checkValidation,
                handleChange,
                updateErrors,
            }) => (
                <ForgotPasswordForm
                    isLoading={loading}
                    passwordIsReset={state.passwordIsReset}
                    values={values}
                    errors={errors}
                    error={error}
                    onChange={handleChange}
                    onSubmit={async () => {
                        if (checkValidation()) {
                            try {
                                await resetPassword({
                                    variables: { input: { ...values } },
                                })
                                setState({ passwordIsReset: true })
                            } catch (e) {
                                updateErrors(graphqlErrorsToFormErrorsDEPRECATED(e.graphQLErrors))
                            }
                        }
                    }}
                />
            )}
        </FormState>
    )
}

export default ForgotPasswordWithState
