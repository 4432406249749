import React from 'react'
import 'antd/dist/antd.less'
import '../styles/tailwind.output.css'
import AppRouter from './AppRouter'
import AppLoading from './AppLoading'
import AppUnavailable from './AppUnavailable'

const App = ({
    history,
    isLoading,
    isAvailable,
    isAuthenticated,
}) => {
    if (isLoading) {
        return <AppLoading />
    }
    if (!isAvailable) {
        return <AppUnavailable />
    }
    return (
        <AppRouter
            history={history}
            isAuthenticated={isAuthenticated}
        />
    )
}

export default App
