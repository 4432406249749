import { createFormValidation } from 'use-form-state'
import * as validation from '../../../../validation'
import useFormState from '../../../hooks/useFormState'
import { UNIT_CODE_METRIC_TON, CURRENCY_CODE_USD } from '../../../../util/constants'

const setInitialFormValues = () => ({
    email: '',
    firstName: '',
    lastName: '',
    phone: '+32',
    sendEmail: false,
    preferredUnitCode: UNIT_CODE_METRIC_TON,
    preferredCurrencyCode: CURRENCY_CODE_USD,
})

export const customerUserValidation = createFormValidation([{
    path: 'email',
    validate: (email) => validation.isValue(email) && validation.isEmail(email),
    message: 'Please fill in a valid email.',
}, {
    path: 'firstName',
    validate: (firstName) => validation.isValue(firstName),
    message: 'Please fill in a first name.',
}, {
    path: 'lastName',
    validate: (lastName) => validation.isValue(lastName),
    message: 'Please fill in a last name.',
}, {
    path: 'phone',
    validate: (phone) => validation.isValue(phone) && phone[phone.length - 1] !== '_',
    message: 'Please fill in a phone number.',
}, {
    path: 'preferredUnitCode',
    validate: (preferredUnitCode) => validation.isValue(preferredUnitCode),
    message: 'Please select a unit.',
}, {
    path: 'preferredCurrencyCode',
    validate: (preferredCurrencyCode) => validation.isValue(preferredCurrencyCode),
    message: 'Please select a currency.',
}])

const useCreateCustomerUserForm = (options) => (
    useFormState(
        setInitialFormValues(),
        {
            customerUserValidation,
            ...options,
        },
    )
)

export default useCreateCustomerUserForm
