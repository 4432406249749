import React from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { MailOutlined } from '@ant-design/icons'

const UsersTableResendMailButton = ({
    onResendEmailInvitation,
}) => (
    <Tooltip title="Send invitation">
        <Popconfirm
            title="Do you want to resend an invitation mail to this user?"
            onConfirm={onResendEmailInvitation}
            okText="Yes"
            cancelText="No"
        >
            <Button
                ghost
                icon={<MailOutlined />}
                type="primary"
                shape="circle"
                size="small"
                className="border-none"
            />
        </Popconfirm>
    </Tooltip>
)

export default UsersTableResendMailButton
