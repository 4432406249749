import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export default function initializeSentry() {
    const { REACT_APP_ENV, REACT_APP_SENTRY_DSN } = process.env
    if (REACT_APP_ENV !== 'local') {
        Sentry.init({
            dsn: REACT_APP_SENTRY_DSN,
            environment: REACT_APP_ENV,
            integrations: [new Integrations.BrowserTracing()],
        })
    }
}
