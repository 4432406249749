import roundTo from 'round-to'
import * as constants from './constants'

const {
    UNIT_CODE_GRAM: G,
    UNIT_CODE_KILOGRAM: KG,
    UNIT_CODE_METRIC_TON: TON,
    UNIT_CODE_CUBM: CUBM,
    UNIT_CODE_LITER: L,
    KILOGRAM_ROUND,
    GRAM_ROUND,
    TON_ROUND,
    CUBM_ROUND,
    LITER_ROUND,
    CONVERSION_TABLE_MASS,
    CONVERSION_TABLE_VOLUME,
    AVAILABLE_VOLUMES,
    AVAILABLE_MASS,
    ROUND_DOWN,
    ROUND_UP,
    DENSITY_ACCURACY_RATE,
} = constants

export const volumeToMass = (value, density) => (value * density) / DENSITY_ACCURACY_RATE
export const massToVolume = (value, density) => (value / density) * DENSITY_ACCURACY_RATE

export const unitIsVolume = (unitCode) => !!AVAILABLE_VOLUMES.some((code) => code === unitCode)
export const unitIsMass = (unitCode) => !!AVAILABLE_MASS.some((code) => code === unitCode)

export const getRoundingForUnit = (unitCode) => {
    switch (unitCode) {
        case KG:
            return KILOGRAM_ROUND
        case G:
            return GRAM_ROUND
        case TON:
            return TON_ROUND
        case CUBM:
            return CUBM_ROUND
        case L:
            return LITER_ROUND
        default:
            return 0
    }
}

export const unitToMilligram = (
    value, unitCode, density = DENSITY_ACCURACY_RATE
) => {
    let conversion
    let valueToConvert = value
    if (unitIsVolume(unitCode)) {
        conversion = CONVERSION_TABLE_VOLUME[unitCode]
        valueToConvert = volumeToMass(value, density)
    } else {
        conversion = CONVERSION_TABLE_MASS[unitCode]
    }
    if (typeof conversion === 'undefined') {
        throw new Error(`Unknown unit code '${unitCode}'.`)
    }
    return valueToConvert * conversion
}

export const milligramToUnit = (
    value, unitCode, density = DENSITY_ACCURACY_RATE, rounding = {}
) => {
    let conversion
    let valueToConvert = value
    if (unitIsVolume(unitCode)) {
        conversion = CONVERSION_TABLE_VOLUME[unitCode]
        valueToConvert = massToVolume(value, density)
    } else {
        conversion = CONVERSION_TABLE_MASS[unitCode]
    }
    if (typeof conversion === 'undefined') {
        throw new Error(`Unknown unit code '${unitCode}'.`)
    }
    const finalRounding = rounding.value || getRoundingForUnit(unitCode)
    const finalValue = valueToConvert / conversion
    if (rounding.direction === ROUND_DOWN) {
        return roundTo.down(finalValue, finalRounding)
    }
    if (rounding.direction === ROUND_UP) {
        return roundTo.up(finalValue, finalRounding)
    }
    return roundTo(finalValue, finalRounding)
}

export const unitTo = (value, unitCodeFrom, unitCodeTo, density, rounding) => {
    // First convert to milligram to convert back to the final unit
    const valueInMilligram = unitToMilligram(value, unitCodeFrom, density)
    return milligramToUnit(valueInMilligram, unitCodeTo, density, rounding)
}

export const roundUnitTo = (value, unitCodeFrom, unitCodeTo, rounding = null) => {
    if (rounding !== null) {
        return roundTo(unitTo(value, unitCodeFrom, unitCodeTo), rounding)
    }
    switch (unitCodeTo) {
        case G:
            return roundTo(unitTo(value, unitCodeFrom, unitCodeTo), GRAM_ROUND)
        case KG:
            return roundTo(unitTo(value, unitCodeFrom, unitCodeTo), KILOGRAM_ROUND)
        default:
            throw new Error(`Unknown unit code '${unitCodeTo}'.`)
    }
}

export const calculateStep = (isRounded, currentUnit) => {
    if (!isRounded) {
        return 1 / 10 ** getRoundingForUnit(currentUnit)
    }
    return 1
}

export const getTradeLimit = (
    tradeLimit,
    tradeLimitUnitCode,
    unitCode,
    density,
    rounding,
) => {
    // From mass to volume
    if (unitIsMass(tradeLimitUnitCode) && unitIsVolume(unitCode)) {
        return milligramToUnit(tradeLimit, unitCode, density, rounding)
    }
    // From volume to mass
    if (unitIsVolume(tradeLimitUnitCode) && unitIsMass(unitCode)) {
        const volumeInMass = volumeToMass(tradeLimit, density)
        return milligramToUnit(volumeInMass, unitCode, undefined, rounding)
    }
    // When mass to mass or volume to volume
    return milligramToUnit(tradeLimit, unitCode, undefined, rounding)
}

export const getTotalInTradeLimitUnit = (orders, tradeLimitUnitCode) => (
    orders.reduce((totalAcc, order) => {
        const { quantity, density } = order
        let quantityInTradeLimitUnit = quantity
        if (unitIsVolume(tradeLimitUnitCode)) {
            quantityInTradeLimitUnit = roundTo(massToVolume(quantity, density), 0)
        }
        return totalAcc + quantityInTradeLimitUnit
    }, 0)
)
