import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import {
    GoldOutlined,
    TeamOutlined,
    DollarOutlined,
    SettingOutlined,
    ClockCircleOutlined,
    OrderedListOutlined,
} from '@ant-design/icons'
import * as routes from '../../routes'

const SettingsSidebarMenu = ({
    activeMenuItem,
}) => (
    <Menu
        mode="vertical"
        selectedKeys={[activeMenuItem]}
        style={{ borderRight: 0 }}
    >
        <Menu.Item key="users">
            <Link to={routes.users()}>
                <TeamOutlined /> Users
            </Link>
        </Menu.Item>
        <Menu.Item key="assets">
            <Link to={routes.assets()}>
                <GoldOutlined /> Assets
            </Link>
        </Menu.Item>
        <Menu.Item key="marketData">
            <Link to="/market-data">
                <DollarOutlined /> Market data
            </Link>
        </Menu.Item>
        <Menu.Item key="openingHours">
            <Link to="/opening-hours">
                <ClockCircleOutlined /> Opening hours
            </Link>
        </Menu.Item>
        <Menu.Item key="logs">
            <Link to={routes.logs()}>
                <OrderedListOutlined /> Logs
            </Link>
        </Menu.Item>
        <Menu.Item key="appSettings">
            <Link to={routes.appSettings()}>
                <SettingOutlined /> Settings
            </Link>
        </Menu.Item>
    </Menu>
)

export default SettingsSidebarMenu
