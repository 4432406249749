import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const { Search } = Input

const OrdersSearchInput = ({
    onSearchOrder,
    searchOrderRef,
    onResetOrderSearch,
}) => {
    const [localOrderRef, setLocalOrderRef] = useState(searchOrderRef)

    useEffect(() => {
        setLocalOrderRef(searchOrderRef)
    }, [searchOrderRef])

    return (
        <Search
            className="w-44"
            value={localOrderRef}
            placeholder={localOrderRef || '# Order ref.'}
            enterButton={<SearchOutlined />}
            onChange={(e) => {
                const inputValue = e.target.value
                if (inputValue === '') {
                    onResetOrderSearch()
                    setLocalOrderRef()
                } else {
                    setLocalOrderRef(inputValue)
                }
            }}
            onSearch={(orderRef) => onSearchOrder(orderRef)}
            allowClear={searchOrderRef !== null}
        />
    )
}

export default OrdersSearchInput
