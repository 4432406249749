import React from 'react'
import moment from 'moment'
import {
    Button,
    Switch,
    TimePicker,
} from 'antd'
import {
    EditOutlined,
    CloseOutlined,
} from '@ant-design/icons'
import { gql } from '@apollo/client'
import OpeningHoursItem from '../opening-hours/OpeningHoursItem'

export const FORMAT = 'HH:mm'

const FRAGMENT = gql`
    fragment UpdateCustomerOpeningHoursItem on CustomerOpeningHour {
        id
        isOpen
        openingTime
        closingTime
        openingHour {
            ...OpeningHoursItem
        }
    }
    ${OpeningHoursItem.FRAGMENT}
`

const UpdateCustomerOpeningHoursItem = ({
    path,
    formState,
}) => {
    const {
        hasError,
        getErrorMessages,
        getValue,
        handleChange,
        handleChangeIsOpen,
        handleAddCustomerOpeningHour,
        handleRemoveCustomerOpeningHour,
    } = formState
    const originalValue = getValue(path)
    let finalValue = originalValue
    const isOverWritten = originalValue.openingTime === null
        && originalValue.closingTime === null
        && originalValue.isOpen === null
    if (isOverWritten) {
        finalValue = originalValue.openingHour
    }
    return (
        <div className="flex justify-between">
            <div className="flex flex-1 items-center ">
                <span className="w-1/3">
                    {moment().weekday(originalValue.day).format('dddd')}
                </span>
                <span className="w-2/3">
                    <Switch
                        disabled={isOverWritten}
                        className="mr-4"
                        checked={finalValue.isOpen === true}
                        onChange={(checked) => handleChangeIsOpen(path, checked)}
                    />
                    {finalValue.isOpen ? 'Open' : 'Closed'}
                </span>
            </div>
            <div className="flex flex-1 items-center">
                {finalValue.isOpen && (
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <TimePicker
                                disabled={isOverWritten}
                                className="w-full"
                                format={FORMAT}
                                hourStep={1}
                                minuteStep={1}
                                name={`${path}.openingTime`}
                                value={finalValue.openingTime}
                                onChange={(value) => handleChange(`${path}.openingTime`, value)}
                            />
                            <span className="mx-2">
                                to
                            </span>
                            <TimePicker
                                disabled={isOverWritten}
                                className="w-full"
                                format={FORMAT}
                                hourStep={1}
                                minuteStep={1}
                                name={`${path}.closingTime`}
                                value={finalValue.closingTime}
                                onChange={(value) => handleChange(`${path}.closingTime`, value)}
                            />
                        </div>
                        {hasError(`${path}.openingTime`) && (
                            getErrorMessages(`${path}.openingTime`).map((message) => (
                                <span
                                    key={`${message}/${path}`}
                                    className="text-red-400"
                                >
                                    {message}
                                </span>
                            ))
                        )}
                    </div>
                )}
            </div>
            {isOverWritten ? (
                <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => handleAddCustomerOpeningHour(path)}
                />
            ) : (
                <Button
                    danger
                    icon={<CloseOutlined />}
                    onClick={() => handleRemoveCustomerOpeningHour(path)}
                />
            )}
        </div>
    )
}

UpdateCustomerOpeningHoursItem.FRAGMENT = FRAGMENT

export default UpdateCustomerOpeningHoursItem
