import { gql, useMutation } from '@apollo/client'

export const CREATE_ORDER_FRAGMENT = gql`
    fragment OrderPageOrder on Order {
        id
        price
        quantity
        unitCode
        currencyCode
        createdAt
        customer {
            id
            name
        }
    }
`

const CREATE_ORDER_MUTATION = gql`
   mutation createCustomerOrder($input: CustomerOrderInput!, $customerId: Int!) {
        createCustomerOrder(input: $input, customerId: $customerId) {
            order {
                ...OrderPageOrder
            }
        }
    }
    ${CREATE_ORDER_FRAGMENT}
`

const useCreateOrderMutation = (options) => (
    useMutation(CREATE_ORDER_MUTATION, options)
)

export default useCreateOrderMutation
