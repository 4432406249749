import { useQuery, gql } from '@apollo/client'
import MarketDataTable from './MarketDataTable'

export const query = gql`
    query marketDataPageQuery {
        allMarketData {
            ...MarketDataTable
        }
    }
    ${MarketDataTable.FRAGMENT}
`

const useMarketDataPageQuery = (options) => {
    const {
        data = {},
        loading,
        error,
    } = useQuery(query, options)
    const { allMarketData } = data
    return {
        error,
        loading,
        allMarketData,
    }
}

export default useMarketDataPageQuery
