import React, { useEffect } from 'react'
import usePolling from '../../../hooks/usePolling'
import MarketDataError from './MarketDataError'
import MarketDataLoading from './MarketDataLoading'
import CustomerMarketData from './CustomerMarketData'
import MarketDataUnavailable from './MarketDataUnavailable'
import useCustomerMarketDataQuery from './useCustomerMarketDataQuery'
import { SIDE_SELL } from '../../../../util/constants'

const STATUS_UNAVAILABLE = 'UNAVAILABLE'

const getSidePricing = (side, marketData) => (
    side === SIDE_SELL ? marketData.sellPrice : marketData.buyPrice
)

const CustomerMarketDataWithQuery = ({
    customerId,
    side,
    productVariationId,
    assetCode,
    unitCode,
    currencyCode,
    onCompleteMarketData,
}) => {
    const {
        data = {},
        error,
        loading,
        stopPolling,
        startPolling,
    } = useCustomerMarketDataQuery({
        variables: {
            customerId,
            assetCode,
            productVariationId,
        },
    })

    usePolling(startPolling, stopPolling)

    useEffect(() => {
        const { adjustedCustomerMarketData } = data
        if (adjustedCustomerMarketData) {
            const spotPrice = getSidePricing(side, adjustedCustomerMarketData)
            onCompleteMarketData({
                spotPrice,
                currencyConversion: adjustedCustomerMarketData.currencyConversion,
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.adjustedCustomerMarketData, data.currencyConversion])

    if (loading) {
        return <MarketDataLoading />
    }
    const { adjustedCustomerMarketData } = data
    if (typeof error !== 'undefined') {
        return <MarketDataError error={error.message} />
    }
    if (adjustedCustomerMarketData === null) {
        return <MarketDataError />
    }
    if (adjustedCustomerMarketData.status === STATUS_UNAVAILABLE) {
        return <MarketDataUnavailable assetName={assetCode} />
    }
    return (
        <CustomerMarketData
            unitCode={unitCode}
            side={side}
            currencyCode={currencyCode}
            spotPrice={getSidePricing(side, data.adjustedCustomerMarketData)}
            adjustedCustomerMarketData={data.adjustedCustomerMarketData}
        />
    )
}

export default CustomerMarketDataWithQuery
