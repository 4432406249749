import React from 'react'
import { gql } from '@apollo/client'
import { Input, Table } from 'antd'
import { DUTIES_ACCURACY } from '@gunvor-trading/shared/utils/constants'
import UnitsSelectWithCache from '../../util/form/UnitSelectWithCache'
import CurrencySelectWithCache from '../../util/form/CurrencySelectWithCache'
import PriceAdjustmentInputNumber from './PriceAdjustmentInputNumber'

const { Column } = Table

const FRAGMENT = gql`
    fragment CustomerPriceAdjustmentsTable on CustomerProductVariation {
        id
        name
        unitCode
        currencyCode
        loadingFee
        bioFee
        duties
        productVariation {
            id
            name
            unitCode
            currencyCode
            loadingFee
            bioFee
            duties
        }
    }
`

const CustomerPriceAdjustmentsTable = ({
    formState,
}) => {
    const {
        values,
        getInputProps,
        getNativeInputProps,
        handleAddCustomerAssetValue,
        handleRemoveCustomerAssetValue,
    } = formState
    return (
        <Table
            rowKey="id"
            pagination={false}
            dataSource={values.productVariations}
            className="mb-3"
        >
            <Column
                title="Name"
                dataIndex="name"
                render={(value, record, index) => (
                    <Input
                        disabled
                        {...getNativeInputProps(`productVariations.${index}.name`)}
                    />
                )}
            />
            <Column
                title="Currency"
                dataIndex="currencyCode"
                render={(value, record, index) => (
                    <CurrencySelectWithCache
                        disabled
                        {...getInputProps(`productVariations.${index}.currencyCode`)}
                    />
                )}
            />
            <Column
                title="Unit"
                dataIndex="unitCode"
                render={(value, record, index) => (
                    <UnitsSelectWithCache
                        disabled
                        {...getInputProps(`productVariations.${index}.unitCode`)}
                    />
                )}
            />
            <Column
                title="Loading fee"
                dataIndex="loadingFee"
                render={(value, record, index) => (
                    <PriceAdjustmentInputNumber
                        formState={formState}
                        name={`productVariations.${index}.loadingFee`}
                        finalName={`productVariations.${index}.productVariation.loadingFee`}
                        precision={2}
                        step={0.01}
                        onAdd={handleAddCustomerAssetValue}
                        onRemove={handleRemoveCustomerAssetValue}
                    />
                )}
            />
            <Column
                title="Bio fee"
                dataIndex="bioFee"
                render={(value, record, index) => (
                    <PriceAdjustmentInputNumber
                        formState={formState}
                        name={`productVariations.${index}.bioFee`}
                        finalName={`productVariations.${index}.productVariation.bioFee`}
                        precision={2}
                        step={0.01}
                        onAdd={handleAddCustomerAssetValue}
                        onRemove={handleRemoveCustomerAssetValue}
                    />
                )}
            />
            <Column
                title="Duties"
                dataIndex="duties"
                render={(value, record, index) => (
                    <PriceAdjustmentInputNumber
                        formState={formState}
                        name={`productVariations.${index}.duties`}
                        finalName={`productVariations.${index}.productVariation.duties`}
                        accuracy={DUTIES_ACCURACY}
                        precision={4}
                        step={0.0001}
                        onAdd={handleAddCustomerAssetValue}
                        onRemove={handleRemoveCustomerAssetValue}
                    />
                )}
            />
        </Table>
    )
}

CustomerPriceAdjustmentsTable.FRAGMENT = FRAGMENT

export default CustomerPriceAdjustmentsTable
