import { createFormValidation } from 'use-form-state'
import useFormState from '../../hooks/useFormState'
import { isValue } from '../../../validation/index'
import {
    PRICE_ACCURACY,
    ADJUSTMENT_ACCURACY,
    CONVERSION_ACCURACY,
    DUTIES_ACCURACY,
} from '../../../util/constants'

const toFormValues = ({
    name = '',
    code = '',
    premium = 0,
    density = 0,
    conversionRateExtra = 0,
    productVariations = [],
}) => ({
    name,
    code,
    premium: premium / PRICE_ACCURACY,
    density: density / ADJUSTMENT_ACCURACY,
    conversionRateExtra: conversionRateExtra / CONVERSION_ACCURACY,
    productVariations: productVariations.map((adjustment) => ({
        id: adjustment.id,
        name: adjustment.name,
        unitCode: adjustment.unitCode,
        currencyCode: adjustment.currencyCode,
        bioFee: adjustment.bioFee / PRICE_ACCURACY,
        loadingFee: adjustment.loadingFee / PRICE_ACCURACY,
        duties: adjustment.duties / DUTIES_ACCURACY,
    }))
})

export const validation = createFormValidation([{
    path: 'name',
    validate: (name) => isValue(name),
    message: 'Please fill in a name',
}, {
    path: 'code',
    validate: (code) => isValue(code),
    message: 'Please fill in a code',
}, {
    path: 'productVariations.*.name',
    validate: (value) => isValue(value),
    message: 'Please fill in a name',
}])

const valuesToInput = ({
    name,
    code,
    density,
    premium,
    conversionRateExtra,
    productVariations,
}) => ({
    name,
    code,
    density: density * ADJUSTMENT_ACCURACY,
    premium: premium * PRICE_ACCURACY,
    conversionRateExtra: conversionRateExtra * CONVERSION_ACCURACY,
    productVariations: productVariations.map((adjustment) => ({
        id: adjustment.id,
        name: adjustment.name,
        unitCode: adjustment.unitCode,
        currencyCode: adjustment.currencyCode,
        bioFee: Math.round(adjustment.bioFee * PRICE_ACCURACY),
        loadingFee: Math.round(adjustment.loadingFee * PRICE_ACCURACY),
        duties: Math.round(adjustment.duties * DUTIES_ACCURACY),
    }))
})

const useUpdateAssetFormState = (initialValues, options) => {
    const { units, currencies } = options
    const formState = useFormState(
        toFormValues(initialValues),
        {
            validation,
            valuesToInput,
            ...options,
        },
    )

    const handleAddPriceAdjustment = () => {
        const productVariations = [...formState.values.productVariations]
        const newPriceAdjustment = {
            name: '',
            unitCode: units ? units[0].code : null,
            currencyCode: currencies ? currencies[0].code : null,
            bioFee: 0,
            loadingFee: 0,
            duties: 0,
        }
        formState.setValues({
            productVariations: [
                ...productVariations,
                newPriceAdjustment,
            ]
        })
    }

    const handleRemovePriceAdjustment = (index) => {
        const productVariations = [...formState.values.productVariations]
        productVariations.splice(index, 1)
        formState.setValues({
            productVariations,
        })
    }

    return {
        ...formState,
        handleAddPriceAdjustment,
        handleRemovePriceAdjustment,
    }
}

export default useUpdateAssetFormState
