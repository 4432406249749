import React from 'react'
import { useParams } from 'react-router-dom'
import useModalState from '../../hooks/useModalState'
import CustomerPage from '../../layout/CustomerPage'
import CustomerPageError from '../../layout/CustomerPageError'
import ExportOrdersToFileModalWithState from '../orders/export-orders/ExportOrdersModalWithState'
import CustomerOrdersPage from './CustomerOrdersPage'
import EditOrderModalWithState from './EditOrderModalWithState'
import useFetchCustomerOrdersPageData from './useFetchCustomerOrdersPageData'

const CustomerOrdersPageWithState = () => {
    const params = useParams()
    const customerId = parseInt(params.customerId, 10)
    const editOrderModalState = useModalState()
    const exportOrdersToFileModalState = useModalState()
    const {
        error,
        isFetching,
        customer,
        customerOrders,
        hasMoreCustomerOrders,
        isFetchingMore,
        handleFetchMoreCustomerOrders,
        refetch,
    } = useFetchCustomerOrdersPageData(customerId)

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem="orders"
            />
        )
    }

    return (
        <CustomerPage
            customerId={customerId}
            isLoading={isFetching}
            activeMenuItem="orders"
        >
            <CustomerOrdersPage
                customerId={customerId}
                customerName={customer.name}
                isFetchingMore={isFetchingMore}
                hasMore={hasMoreCustomerOrders}
                customerOrders={customerOrders}
                onFetchMore={handleFetchMoreCustomerOrders}
                onExportOrders={() => (
                    exportOrdersToFileModalState.open({ customerId, dateRange: [] })
                )}
                onEditOrder={(order) => editOrderModalState.open({ order })}
                refetch={refetch}
            />
            {exportOrdersToFileModalState.isOpen && (
                <ExportOrdersToFileModalWithState
                    onExportOrdersToFileComplete={exportOrdersToFileModalState.close}
                    closeModal={exportOrdersToFileModalState.close}
                    {...exportOrdersToFileModalState.initialState}
                />
            )}
            {editOrderModalState.isOpen && (
                <EditOrderModalWithState
                    onUpdateOrderCompleted={() => {
                        refetch()
                        editOrderModalState.close()
                    }}
                    onCloseModal={editOrderModalState.close}
                    {...editOrderModalState.initialState}
                />
            )}
        </CustomerPage>
    )
}

export default CustomerOrdersPageWithState
