import React from 'react'
import { Button } from 'antd'
import OpeningHoursItem from './OpeningHoursItem'

const OpeningHoursForm = ({
    isSubmitting,
    formState,
    onSubmit,
}) => {
    const { values } = formState
    return (
        <form className="w-full lg:w-2/3 p-4 rounded-lg bg-gray-50">
            {values.openingHours.map((openingHour, index) => (
                <div
                    key={openingHour.id}
                    className="mb-4"
                >
                    <OpeningHoursItem
                        index={index}
                        openingHour={openingHour}
                        formState={formState}
                    />
                </div>
            ))}
            <div className="flex justify-end">
                <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type="primary"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </div>
        </form>
    )
}

export default OpeningHoursForm
