import React from 'react'
import { Popconfirm, Tooltip } from 'antd'
import {
    StopOutlined,
    StockOutlined,
} from '@ant-design/icons'
import usePermissions from '../../hooks/usePermisssions'

const getPopconfirmMessage = (suspended) => {
    if (suspended) {
        return 'Are you sure you want to allow this asset for trading?'
    }
    return 'Are you sure you want to suspend this asset from trading?'
}

const AssetSuspendButton = ({
    suspended,
    onClick,
}) => {
    const { isAdmin } = usePermissions()
    if (!isAdmin) {
        if (suspended) {
            return <StockOutlined style={{ cursor: 'not-allowed' }} />
        }
        return (
            <StopOutlined style={{ cursor: 'not-allowed' }} />
        )
    }
    return (
        <Popconfirm
            title={getPopconfirmMessage(suspended)}
            onConfirm={onClick}
            okText="Yes"
            cancelText="No"
        >
            {(() => {
                if (suspended) {
                    return (
                        <Tooltip title="Activate asset">
                            <StockOutlined />
                        </Tooltip>
                    )
                }
                return (
                    <Tooltip title="Suspend asset">
                        <StopOutlined />
                    </Tooltip>
                )
            })()}
        </Popconfirm>
    )
}

export default AssetSuspendButton
