import React from 'react'
import { Modal } from 'antd'
import CreateOrderForm from './CreateOrderForm'
import CreateOrderAuthenticateForm from './CreateOrderAuthenticateForm'

const CreateOrderModal = ({
    productVariation,
    asset = {},
    customerAssets,
    formState,
    isOpen,
    isCreating,
    isAuthenticating,
    onClose,
    onCancel,
    onConfirm,
    onSubmitOrder,
    onChangeCustomer,
    onChangeProductVariation,
}) => (
    <Modal
        title="Create new order"
        visible={isOpen}
        width={750}
        closable={!isCreating}
        maskClosable={!isCreating}
        confirmLoading={isCreating}
        footer={null}
        onCancel={onClose}
    >
        {isAuthenticating ? (
            <CreateOrderAuthenticateForm
                formState={formState}
                isLoading={isCreating}
                assetName={asset.name}
                onCancel={onCancel}
                onConfirm={onConfirm}
                productVariation={productVariation}
            />
        ) : (
            <CreateOrderForm
                asset={asset}
                customerAssets={customerAssets}
                formState={formState}
                onSubmit={onSubmitOrder}
                productVariation={productVariation}
                onChangeCustomer={onChangeCustomer}
                onChangeProductVariation={onChangeProductVariation}
            />
        )}
    </Modal>
)

export default CreateOrderModal
