import React from 'react'
import {
    Tag,
    Table,
    Button,
    Tooltip,
} from 'antd'
import {
    FilePdfOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { CONVERSION_ACCURACY } from '@gunvor-trading/shared/utils/constants'
import { SIDE_SELL } from '../../../util/constants'
import CurrencyValue from '../../util/CurrencyValue'

const generateColumns = () => [
    {
        align: 'left',
        children: [
            {
                key: 'side',
                dataIndex: 'side',
                render: (side) => (
                    <Tag
                        color={side === SIDE_SELL ? 'green' : 'blue'}
                    >
                        {side}
                    </Tag>
                ),
                align: 'center',
            }, {
                key: 'receit',
                dataIndex: 'receitUrl',
                align: 'right',
                render: (receitUrl, order) => receitUrl !== null && order.orderId !== null && (
                    <Tooltip title="Get receipt">
                        <Button
                            type="link"
                            icon={<FilePdfOutlined />}
                            size="large"
                            href={receitUrl}
                            target="_blank"
                            className="mb-2"
                        />
                    </Tooltip>
                ),
            }],
    }, {
        title: <h2 className="text-2xl">Customers</h2>,
        align: 'left',
        children: [{
            dataIndex: 'createdAt',
            title: 'Traded on',
            render: (createdAt) => createdAt !== null && moment(createdAt).format('DD/MM HH:mm'),
            align: 'left',
            width: 110,
        }, {
            dataIndex: 'reference',
            title: 'Ref.',
            render: (reference) => reference !== null && reference,
            align: 'left',
        }, {
            key: 'customer',
            title: 'Name',
            dataIndex: 'customer',
            render: (customer) => customer !== null && customer.name,
        }, {
            key: 'quantity',
            title: '',
            dataIndex: 'quantity',
            align: 'right',
            render: (quantity, order) => quantity !== null && (
                <strong>
                    {order.quantityInUnit}
                    {order.quantityUnitCode}
                </strong>
            ),
        }, {
            dataIndex: 'price',
            title: 'Price',
            render: (price, order) => price !== null && (
                <strong>
                    <CurrencyValue
                        value={price}
                        currencyCode={order.currencyCode}
                    />
                    /
                    {order.priceUnitCode}
                </strong>
            ),
            align: 'left',
        }, {
            dataIndex: 'currencyConversion',
            title: 'EUR/USD',
            render: (currencyConversion) => currencyConversion !== null && (
                currencyConversion / CONVERSION_ACCURACY
            ),
            align: 'right',
            width: 150,
        }],
    },
]

const OrdersTable = ({
    orders,
    onExportOrders,
}) => (
    <Table
        rowKey="uid"
        size="middle"
        columns={(
            generateColumns({
                orders,
                onExportOrders,
            })
        )}
        dataSource={orders}
        pagination={false}
    />
)

export default OrdersTable
