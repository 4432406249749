import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import {
    GoldOutlined,
    TeamOutlined,
    LeftOutlined,
    DollarOutlined,
    SettingOutlined,
    FileDoneOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons'
import * as routes from '../../routes'

const CustomerSidebarMenu = ({
    customerId,
    activeMenuItem,
}) => (
    <>
        <div className="p-4">
            <Link to={routes.customers()}>
                <LeftOutlined className="mr-4" />Back to overview
            </Link>
        </div>
        <Menu
            mode="vertical"
            selectedKeys={[activeMenuItem]}
            style={{ borderRight: 0 }}
        >
            <Menu.Item key="orders">
                <Link to={routes.customerOrders(customerId)}>
                    <FileDoneOutlined /> Orders
                </Link>
            </Menu.Item>
            <Menu.Item key="users">
                <Link to={routes.customerUsers(customerId)}>
                    <TeamOutlined /> Users
                </Link>
            </Menu.Item>
            <Menu.Item key="assets">
                <Link to={routes.customerAssetSettings(customerId)}>
                    <GoldOutlined /> Assets
                </Link>
            </Menu.Item>
            <Menu.Item key="prices">
                <Link to={routes.customerPrices(customerId)}>
                    <DollarOutlined /> Prices
                </Link>
            </Menu.Item>
            <Menu.Item key="openingHours">
                <Link to={`/customer/${customerId}/opening-hours`}>
                    <ClockCircleOutlined /> Opening hours
                </Link>
            </Menu.Item>
            <Menu.Item key="settings">
                <Link to={routes.customerSettings(customerId)}>
                    <SettingOutlined /> Settings
                </Link>
            </Menu.Item>
        </Menu>
    </>
)

export default CustomerSidebarMenu
