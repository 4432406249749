import React from 'react'
import { Link } from 'react-router-dom'
import {
    Affix,
    Button,
    PageHeader,
} from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import SettingsPage from '../SettingsPage'
import AssetForm from './AssetForm'

const UpdateAssetPage = ({
    routes,
    isUpdating,
    isLoading,
    formState,
    onSubmit,
    activeMenuItem,
}) => (
    <SettingsPage
        isLoading={isLoading}
        activeMenuItem={activeMenuItem}
    >
        <Affix>
            <PageHeader style={{ backgroundColor: 'white' }}>
                <div className="flex justify-between">
                    <Link to={routes.assets()}>
                        <LeftOutlined
                            className="mr-4"
                            type="left"
                            theme="outlined"
                        />Back to assets
                    </Link>
                    <Button
                        type="primary"
                        onClick={onSubmit}
                        loading={isUpdating}
                    >
                        Save asset
                    </Button>
                </div>
            </PageHeader>
        </Affix>
        <AssetForm
            formState={formState}
        />
    </SettingsPage>
)

export default UpdateAssetPage
