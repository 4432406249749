import React from 'react'
import moment from 'moment'
import CurrencyValue from '../../util/CurrencyValue'
import { getCurrencySymbol } from '../../../util/currency'

export const ListItem = ({
    label,
    children,
}) => (
    <li className="flex list-none mb-1">
        <span className="flex-1 text-right mr-4">
            {label}:
        </span>
        <span className="flex-2">
            {children}
        </span>
    </li>
)

const CustomerOrdersTableDetails = ({
    price,
    notes,
    user,
    createdAt,
    totalPrice,
    currencyCode,
    priceUnitCode,
    quantityInUnit,
    quantityUnitCode,
}) => (
    <div className="flex">
        <div className="flex-col flex-1">
            <h3 className="text-xl mb-4">Pricing</h3>
            <ul className="mb-4">
                <ListItem label="Amount">
                    {quantityInUnit}
                    {quantityUnitCode}
                </ListItem>
                <ListItem label="Price per unit">
                    <CurrencyValue
                        value={price}
                        currencyCode={currencyCode}
                        displayCurrencyCode={getCurrencySymbol(currencyCode)}
                    />
                    /
                    {priceUnitCode}
                </ListItem>
                <ListItem label="Total price">
                    <CurrencyValue
                        isBold
                        value={totalPrice}
                        currencyCode={currencyCode}
                        displayCurrencyCode={getCurrencySymbol(currencyCode)}
                    />
                </ListItem>
                <ListItem label="Transfer">
                    {quantityInUnit}
                    {quantityUnitCode}
                </ListItem>
            </ul>
        </div>
        <div className="flex-col flex-1">
            <h3 className="text-xl mb-4">Client</h3>
            <ul className="mb-4">
                <ListItem label="Name">
                    {user.fullName}
                </ListItem>
                <ListItem label="Email">
                    {user.email}
                </ListItem>
                <ListItem label="Phone">
                    {user.phone}
                </ListItem>
            </ul>
            <h3 className="text-xl mb-4">Other details</h3>
            <ul className="mb-4">
                <ListItem label="Traded on">
                    {createdAt !== null && moment(createdAt).format('DD/MM HH:mm')}
                </ListItem>
                <ListItem label="Notes">
                    {notes}
                </ListItem>
            </ul>
        </div>
    </div>
)

export default CustomerOrdersTableDetails
