import { gql, useMutation } from '@apollo/client'

const mutation = gql`
    mutation toggleSuspendAsset($id: Int!) {
        toggleSuspendAsset(id: $id) {
            asset {
                id
                suspended
            }
        }
    }
`

const useToggleSuspendAssetMutation = (options) => (
    useMutation(mutation, options)
)

export default useToggleSuspendAssetMutation
