import * as validation from '../index'

export const isValidPassword = (password) => (
    validation.isValue(password)
)

export const isPasswordLongEnough = (password) => (
    validation.isLongerOrEqualThan(6)(password)
)

export const isValidConfirmPassword = (password, confirmPassword) => (
    validation.isValue(password)
    && validation.isValue(confirmPassword)
    && validation.isEqual(password, confirmPassword)
)

export const isValidChoosePasswordForm = (values) => (
    isValidPassword(values.password)
    && isPasswordLongEnough(values.password)
    && isValidConfirmPassword(values.password, values.confirmPassword)
)
