import React from 'react'
import { gql } from '@apollo/client'
import TopBar from '../util/TopBar'

const FRAGMENT = gql`
    fragment CustomerTopBar on Customer {
        id
        name
    }
`

const CustomerTopBar = ({
    customerName = '',
    rightChildren,
}) => (
    <TopBar
        leftChildren={<h1 className="font-black text-3xl">{customerName}</h1>}
        rightChildren={rightChildren}
    />
)

CustomerTopBar.FRAGMENT = FRAGMENT

export default CustomerTopBar
