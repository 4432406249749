import React from 'react'
import {
    Form,
    Input,
    Row,
    Col,
} from 'antd'
import { ROLES } from '../../../util/roles'
import FormItem from '../../util/form/FormItem'
import HasPermissions from '../../util/HasPermissions'
import UnitInputWithConverter from '../../util/form/UnitInputWithConverter'
import TradeLimitsToggleButton from './TradeLimitsToggleButton'

const CustomerSettingsForm = ({
    formState,
}) => {
    const {
        getInputProps,
        getNativeInputProps,
        antdErrors,
        handleEditTradeLimits,
        values,
        handleChange,
    } = formState

    return (
        <HasPermissions roles={[ROLES.ADMIN]}>
            {(hasPermission) => (
                <Form layout="vertical">
                    <form className="form">
                        <Row>
                            <h2 className="text-2xl">Name</h2>
                        </Row>
                        <Row>
                            <Col md={10} sm={24}>
                                <FormItem
                                    error={antdErrors.get('name')}
                                >
                                    <Input
                                        {...getNativeInputProps('name')}
                                        disabled={!hasPermission}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <h2 className="text-2xl">Daily Limits</h2>
                        </Row>
                        <Row gutter={16}>
                            <Col md={10} sm={24}>
                                <FormItem
                                    label="Minimum quantity limit"
                                    className="w-full font-medium"
                                >
                                    <UnitInputWithConverter
                                        {...getInputProps('minTradeLimit')}
                                        error={antdErrors.get('minTradeLimit')}
                                        code="settings"
                                        isRounded={false}
                                        disabled={!formState.values.areTradeLimitsSet}
                                        unitCode={values.tradeLimitUnitCode}
                                        onChangeUnitCode={(value) => {
                                            handleChange('tradeLimitUnitCode', value)
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col md={10} sm={24}>
                                <FormItem
                                    label="Maximum quantity limit"
                                    className="w-full font-medium"
                                >
                                    <UnitInputWithConverter
                                        {...getInputProps('maxTradeLimit')}
                                        error={antdErrors.get('maxTradeLimit')}
                                        code="settings"
                                        isRounded={false}
                                        disabled={!formState.values.areTradeLimitsSet}
                                        unitCode={values.tradeLimitUnitCode}
                                        onChangeUnitCode={(value) => {
                                            handleChange('tradeLimitUnitCode', value)
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col md={4} sm={24}>
                                <div className="mt-7">
                                    <TradeLimitsToggleButton
                                        areTradeLimitsSet={formState.values.areTradeLimitsSet}
                                        onToggleEditTradeLimits={handleEditTradeLimits}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Form>
            )}
        </HasPermissions>
    )
}

export default CustomerSettingsForm
