import { gql, useMutation } from '@apollo/client'
import UpdateCustomerAssetForm from './UpdateCustomerAssetForm'
import CustomerPriceAdjustmentsTable from './CustomerPriceAdjustmentsTable'

const mutation = gql`
    mutation updateCustomerAsset($id: Int!, $input: UpdateCustomerAssetInput!) {
        updateCustomerAsset(id: $id, input: $input) {
            customerAsset {
                ...UpdateCustomerAssetForm
                productVariations {
                    ...CustomerPriceAdjustmentsTable
                }
            }
        }
    }
    ${UpdateCustomerAssetForm.FRAGMENT}
    ${CustomerPriceAdjustmentsTable.FRAGMENT}
`

const useUpdateCustomerAssetMutation = (options) => (
    useMutation(mutation, options)
)

export default useUpdateCustomerAssetMutation
