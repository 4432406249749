import React from 'react'
import {
    Affix, Button, Row, Col,
} from 'antd'
import { milligramToUnit } from '@gunvor-trading/shared/utils/units'
import { ROLES } from '../../../util/roles'
import CustomerTopBar from '../../layout/CustomerTopBar'
import HasPermissions from '../../util/HasPermissions'
import CustomerSettingsForm from './CustomerSettingsForm'
import DeleteCustomerButton from './DeleteCustomerButton'

const CustomerUsersPage = ({
    formState,
    customer,
    onCustomerSettingsFormSubmit,
    onCustomerDeleteConfirm,
    isDeletingCustomer,
}) => {
    const tradeLimitUnitCode = customer.tradeLimitUnitCode || customer.tradeLimit.tradeLimitUnitCode

    return (
        <>
            <Affix>
                <CustomerTopBar
                    customerName={customer.name}
                    rightChildren={(
                        <div className="mr-4">
                            <HasPermissions roles={[ROLES.ADMIN]}>
                                {(hasPermission) => hasPermission && (
                                    <>
                                        <Row>
                                            <Col span={8}>
                                                <Button
                                                    className="button"
                                                    onClick={onCustomerSettingsFormSubmit}
                                                    type="primary"
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                            <Col span={8}>
                                                <DeleteCustomerButton
                                                    customer={customer}
                                                    onCustomerDeleteConfirm={onCustomerDeleteConfirm}
                                                    isDeletingCustomer={isDeletingCustomer}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </HasPermissions>
                        </div>
                    )}
                />
            </Affix>
            <CustomerSettingsForm
                formState={formState}
                onSubmit={onCustomerSettingsFormSubmit}
            />
            <Row>
                <p className="font-medium">Remaining daily limit</p>
            </Row>
            <Row>
                {milligramToUnit(customer.dailyTradeLimit, tradeLimitUnitCode)}
                {' '}
                {tradeLimitUnitCode}
            </Row>
        </>
    )
}

export default CustomerUsersPage
