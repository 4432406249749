import React, { useState } from 'react'
import { notification } from 'antd'
import CreateOrderModal from './CreateOrderModal'
import useCreateOrderQuery from './useCreateOrderQuery'
import useCreateOrderMutation from './useCreateOrderMutation'
import useCreateOrderFormState from './useCreateOrderFormState'
import { whereId } from '../../util/index'

const CreateOrderModalWithState = ({
    assetId,
    isOpen,
    closeModal,
    onCreateOrderCompleted = () => {},
}) => {
    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const [productVariation, setProductVariation] = useState(null)
    const formState = useCreateOrderFormState({
        assetId,
    })
    const customerId = parseInt(formState.values.customerId, 10)

    const [
        getCustomerAssets,
        { customerAssets }] = useCreateOrderQuery(customerId)
    const customerAssetsMapped = customerAssets.map((item) => {
        const { asset } = item
        return { ...item, ...asset }
    })
    const asset = customerAssetsMapped.find(whereId(assetId))
    const [
        createOrder,
        { loading: isCreatingOrder },
    ] = useCreateOrderMutation({
        onCompleted: onCreateOrderCompleted,
    })

    const handleCreateOrder = async () => {
        if (formState.validate()) {
            try {
                const { unitCode, currencyCode } = productVariation
                const variables = {
                    customerId,
                    input: {
                        ...formState.valuesToInput(),
                        unitCode,
                        currencyCode,
                        productVariationId: productVariation.id,
                    },
                }
                await createOrder({ variables })
                formState.resetForm()
                notification.success({
                    message: 'Order Created!',
                    description: 'Order was successfully created!',
                })
            } catch (e) {
                notification.error({
                    message: 'Order not created!',
                    description: `Something went wrong. The following error occurred: ${e}`,
                })
            }
        }
    }

    const handleClose = () => {
        formState.resetForm()
        closeModal()
    }

    const handleChangeCustomer = (id, customer) => {
        formState.setValues({
            customerId: id,
            customer,
            productVariationId: null,
        })
        setProductVariation(null)
        if (typeof id !== 'undefined') {
            getCustomerAssets({ variables: { customerId: id } })
        }
    }

    const handleChangeProductVariation = (productVariationId) => {
        formState.setValues({
            productVariationId,
        })
        setProductVariation(asset?.productVariations.find(whereId(productVariationId)))
    }

    return (
        <CreateOrderModal
            productVariation={productVariation}
            customerAssets={customerAssetsMapped}
            asset={asset}
            formState={formState}
            isOpen={isOpen}
            isCreating={isCreatingOrder}
            isAuthenticating={isAuthenticating}
            onClose={handleClose}
            onCancel={() => setIsAuthenticating(false)}
            onConfirm={handleCreateOrder}
            onSubmitOrder={() => setIsAuthenticating(true)}
            onChangeCustomer={handleChangeCustomer}
            onChangeProductVariation={handleChangeProductVariation}
        />
    )
}

export default CreateOrderModalWithState
