import React from 'react'
import { Tag } from 'antd'
import { GoldOutlined } from '@ant-design/icons'
import PreferredUnitValue from '../../util/PreferredUnitValue'
import PreferredCurrencyValue from '../../util/PreferredCurrencyValue'

const AssetWalletCard = ({
    wallet,
}) => {
    const {
        asset,
        value,
        quantity,
    } = wallet
    const isZero = value === 0
    const isNegative = value < 0
    const isPositive = value > 0
    return (
        <div className="py-4 px-6 mr-4 border-1 rounded-lg">
            <div className="flex justify-between items-end mb-4">
                <div className="flex items-end">
                    <GoldOutlined
                        className="mr-2 text-3xl"
                    />
                    <h5 className="font-black text-2xl">{asset.name} </h5>
                </div>
                <Tag>{asset.code}</Tag>
            </div>
            <div className="flex justify-between items-end flex-wrap">
                <h3 className="font-black text-3xl">
                    <PreferredUnitValue
                        value={quantity}
                    />

                </h3>
                <h5 className="font-black text-2xl">
                    <PreferredCurrencyValue
                        isNegative={isNegative}
                        isPositive={isPositive}
                        value={Math.abs(value)}
                    />
                </h5>
            </div>
            <div>
                <span className="italic text-xs">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    Estimated {isZero ? 'value' : (isPositive ? 'credit' : 'debt')}
                </span>
            </div>
        </div>
    )
}

export default AssetWalletCard
