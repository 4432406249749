import moment from 'moment'
import useFormState, { createFormValidation } from '../../hooks/useFormState'
import { formatTimeString, formatToDbTime } from '../../../util/dates'
import { FORMAT } from './OpeningHoursItem'

export const formatTimeToFormValue = (time, format = FORMAT) => (
    time !== null
        ? moment.utc(time, format).local()
        : null
)

const toFormValues = ({
    openingHours,
}) => ({
    openingHours: openingHours
        .map((openingHour) => ({
            ...openingHour,
            openingTime: formatTimeToFormValue(openingHour.openingTime),
            closingTime: formatTimeToFormValue(openingHour.closingTime),
        }))
        .sort((a, b) => a.day - b.day)
})

const validation = createFormValidation([{
    path: 'openingHours.*.openingTime',
    validate: (openingTime, { isOpen, closingTime }) => (
        !isOpen || formatTimeString(closingTime) > formatTimeString(openingTime)
    ),
    message: 'Opening time must be before closing time.',
}])

const valuesToInput = ({
    openingHours,
}) => ({
    openingHours: openingHours.map(({
        id,
        isOpen,
        openingTime,
        closingTime,
    }) => ({
        id,
        isOpen,
        openingTime: isOpen ? formatToDbTime(openingTime) : null,
        closingTime: isOpen ? formatToDbTime(closingTime) : null,
    }))
})

const useUpdateOpeningHoursFormState = (initialValues, options) => {
    const formState = useFormState(
        toFormValues(initialValues),
        {
            validation,
            valuesToInput,
            ...options,
        }
    )

    const handleChangeIsOpen = (path, checked) => {
        const value = formState.getValue(path)
        formState.handleChange(path, {
            ...value,
            isOpen: checked,
            openingTime: null,
            closingTime: null,
        })
    }

    return {
        ...formState,
        handleChangeIsOpen,
    }
}

export default useUpdateOpeningHoursFormState
