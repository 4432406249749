import React from 'react'
import AssetCard from '../prices/assetcard/AssetCard'
import useFetchCustomerAssetCardData from './useFetchCustomerAssetCardData'

const POLL_FREQUENCY = 1000 * 3 // 3 sec

const CustomerAssetCardWithState = ({
    customerId,
    asset,
}) => {
    const { code, productVariations } = asset
    const [firstproduct] = productVariations
    const {
        error,
        isFetching,
        adjustedCustomerMarketData: initialMarketData,
        currencyCode,
        currencySymbol,
        unitCode,
        productVariationId,
        onChangeProductVariationId,
    } = useFetchCustomerAssetCardData(
        customerId,
        code,
        firstproduct,
        POLL_FREQUENCY,
        productVariations,
    )

    if (typeof error !== 'undefined') {
        return (
            <>
                <h4 className="text-lg">Something went wrong loading the marketdata for asset {asset.name}:</h4>
                <p>{error.message}</p>
            </>
        )
    }

    return (
        <AssetCard
            isFetching={isFetching}
            asset={asset}
            marketData={initialMarketData}
            currencyCode={currencyCode}
            currencySymbol={currencySymbol}
            unitCode={unitCode}
            productVariationId={productVariationId}
            onChangeProductVariationId={onChangeProductVariationId}
        />
    )
}

export default CustomerAssetCardWithState
