import { gql, useMutation } from '@apollo/client'

const mutation = gql`
    mutation toggleSuspendCustomerAsset($id: Int!) {
        toggleSuspendCustomerAsset(id: $id) {
            customerAsset {
                id
                suspended
            }
        }
    }
`

const useToggleSuspendCustomerAssetMutation = (options) => (
    useMutation(mutation, options)
)

export default useToggleSuspendCustomerAssetMutation
