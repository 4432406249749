import React from 'react'
import { Select } from 'antd'
import FormItem from './FormItem'
import { getProductVariationOption } from '../../../util/productVariations'

const { Option } = Select

const ProductVariationSelect = ({
    isRequired,
    disabled = false,
    productVariations = [],
    error,
    label,
    ...props
}) => (
    <FormItem
        isRequired={isRequired}
        label={label}
        error={error}
    >
        <Select
            disabled={disabled}
            placeholder="Select a product variation"
            {...props}
        >
            {productVariations.map((productVariation) => (
                <Option
                    key={productVariation.id}
                    value={productVariation.id}
                >
                    {getProductVariationOption(productVariation)}
                </Option>
            ))}
        </Select>
    </FormItem>
)

export default ProductVariationSelect
