import React from 'react'
import { useParams } from 'react-router-dom'
import CustomerPageError from '../../layout/CustomerPageError'
import useFetchCustomerPricesPageData from './useFetchCustomerPricesPageData'
import CustomerPricesPage from './CustomerPricesPage'

const CustomerPricesPageWithState = () => {
    const params = useParams()
    const customerId = parseInt(params.customerId, 10)
    const {
        error,
        isFetching,
        customerAssets,
        customer,
    } = useFetchCustomerPricesPageData(customerId)

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem="prices"
            />
        )
    }

    return (
        <CustomerPricesPage
            customer={customer}
            customerAssets={customerAssets}
            isFetching={isFetching}
        />
    )
}

export default CustomerPricesPageWithState
