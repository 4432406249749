import React from 'react'
import { Dropdown, Menu } from 'antd'
import {
    EditOutlined,
    FilePdfOutlined,
} from '@ant-design/icons'

export const ACTION_EDIT_ORDER = 'editOrder'
export const ACTION_DOWNLOAD_RECEIT = 'downloadReceit'

const CustomerOrdersTableActionDropdown = ({
    order,
    onEditOrder,
    children,
}) => {
    const handleSelect = (e) => {
        if (e.key === ACTION_EDIT_ORDER) {
            onEditOrder(order)
        }
    }
    return (
        <>
            <Dropdown
                overlay={(
                    <Menu
                        onClick={handleSelect}
                        className="border-1 border-gray-200"
                    >
                        {order.orderId !== null && (
                            <Menu.Item key={ACTION_EDIT_ORDER}>
                                <EditOutlined size="small" /> Edit notes
                            </Menu.Item>

                        )}
                        <Menu.Item key={ACTION_DOWNLOAD_RECEIT}>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={order.receitUrl}
                            >
                                <FilePdfOutlined size="small" /> Get receipt
                            </a>
                        </Menu.Item>
                    </Menu>
                )}
                placement="bottomRight"
                trigger={['click']}
            >
                {children}
            </Dropdown>
        </>
    )
}

export default CustomerOrdersTableActionDropdown
