import React from 'react'
import { gql } from '@apollo/client'
import {
    Col,
    Row,
    Form,
    Input,
} from 'antd'
import FormItem from '../../util/form/FormItem'
import { ADJUSTMENT_ACCURACY, CONVERSION_ACCURACY } from '../../../util/constants'
import PriceAdjustmentInputNumber from './PriceAdjustmentInputNumber'
import CustomerPriceAdjustmentsTable from './CustomerPriceAdjustmentsTable'

const FRAGMENT = gql`
    fragment UpdateCustomerAssetForm on CustomerAsset {
        id
        density
        premium
        conversionRateExtra
        asset {
            id
            name
            code
            density
            premium
            conversionRateExtra
        }
    }
`

const UpdateCustomerAssetForm = ({
    formState,
}) => {
    const {
        getNativeInputProps,
        antdErrors,
        handleAddCustomerAssetValue,
        handleRemoveCustomerAssetValue,
    } = formState
    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col md={12} sm={24}>
                    <FormItem
                        label="Name"
                        error={antdErrors.get('name')}
                    >
                        <Input
                            disabled
                            {...getNativeInputProps('name')}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col md={12} sm={24}>
                    <FormItem
                        label="Code"
                        error={antdErrors.get('code')}
                    >
                        <Input
                            disabled
                            {...getNativeInputProps('code')}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col md={6} sm={12}>
                    <FormItem
                        label="Premium"
                        error={antdErrors.get('premium')}
                    >
                        <PriceAdjustmentInputNumber
                            formState={formState}
                            name="premium"
                            finalName="asset.premium"
                            precision={2}
                            step={0.25}
                            onAdd={handleAddCustomerAssetValue}
                            onRemove={handleRemoveCustomerAssetValue}
                        />
                    </FormItem>
                </Col>
                <Col md={6} sm={12}>
                    <FormItem
                        label="Conversion rate extra"
                        error={antdErrors.get('conversionRateExtra')}
                    >
                        <PriceAdjustmentInputNumber
                            formState={formState}
                            name="conversionRateExtra"
                            finalName="asset.conversionRateExtra"
                            accuracy={CONVERSION_ACCURACY}
                            precision={4}
                            step={0.0001}
                            onAdd={handleAddCustomerAssetValue}
                            onRemove={handleRemoveCustomerAssetValue}
                        />
                    </FormItem>
                </Col>
                <Col md={6} sm={12}>
                    <FormItem
                        label="Density"
                        error={antdErrors.get('density')}
                    >
                        <PriceAdjustmentInputNumber
                            formState={formState}
                            name="density"
                            finalName="asset.density"
                            accuracy={ADJUSTMENT_ACCURACY}
                            precision={3}
                            step={0.001}
                            onAdd={handleAddCustomerAssetValue}
                            onRemove={handleRemoveCustomerAssetValue}
                        />
                    </FormItem>
                </Col>
            </Row>
            <h2 className="text-xl font-medium mb-4">Product Variations:</h2>
            <CustomerPriceAdjustmentsTable
                formState={formState}
            />
        </Form>
    )
}

UpdateCustomerAssetForm.FRAGMENT = FRAGMENT

export default UpdateCustomerAssetForm
