import React, {
    useContext, useState, useEffect, useRef
} from 'react'
import {
    Form,
    Table,
    InputNumber,
} from 'antd'
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
} from '@ant-design/icons'
import { gql } from '@apollo/client'

const EditableContext = React.createContext(null)

const { Column } = Table

const FRAGMENT = gql`
    fragment MarketDataTable on MarketData {
        id
        status
        buyPrice
        assetCurrencyCode
        isConnectedToStream
    }
`

const EditableRow = ({
    index,
    ...props
}) => {
    const [form] = Form.useForm()
    return (
        <Form
            form={form}
            component={false}
        >
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    )
}

const EditableCell = ({
    title,
    editable,
    dataIndex,
    record,
    onSave,
    children,
}) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const form = useContext(EditableContext)
    useEffect(() => {
        if (editing) {
            inputRef.current.focus()
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        })
    }

    const save = async () => {
        const values = await form.validateFields()
        toggleEdit()
        onSave({ ...record, ...values })
    }

    const childNode = children

    if (editable) {
        if (editing) {
            return (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <InputNumber
                        className="w-full"
                        ref={inputRef}
                        min={0}
                        precision={4}
                        step={0.0001}
                        decimalSeparator=","
                        onPressEnter={save}
                        onBlur={save}
                    />
                </Form.Item>
            )
        }
        return (
            <div
                className="py-[5px] px-3 hover:py-1 hover:px-[11px] hover:border-1 rounded-sm"
                tabIndex={0}
                role="button"
                onClick={toggleEdit}
                onKeyDown={toggleEdit}
            >
                {children}
            </div>
        )
    }
    return (
        <div
            className="py-[5px] px-3 hover:cursor-default"
        >
            {childNode}
        </div>
    )
}

const MarketDataTable = ({
    data,
    onUpdateRecord,
}) => (
    <Table
        pagination={false}
        components={{
            body: {
                row: EditableRow,
            },
        }}
        bordered
        dataSource={data}
    >
        <Column
            title="Name"
            dataIndex="assetCurrencyCode"
            width={140}
        />
        <Column
            title="Buy price"
            dataIndex="buyPrice"
            render={(value, record, index) => (
                <EditableCell
                    key={index}
                    record={{
                        ...record,
                        buyPrice: record.buyPrice / 10000
                    }}
                    editable={!record.isConnectedToStream}
                    dataIndex="buyPrice"
                    title="Buy price"
                    onSave={onUpdateRecord}
                >
                    {value / 10000}
                </EditableCell>
            )}
        />
        <Column
            align="center"
            title="Connected to stream?"
            dataIndex="isConnectedToStream"
            render={(isConnected) => (
                isConnected ? (
                    <CheckCircleTwoTone twoToneColor="green" />
                ) : (
                    <CloseCircleTwoTone twoToneColor="red" />
                )
            )}
            width={140}
        />
    </Table>
)

MarketDataTable.FRAGMENT = FRAGMENT

export default MarketDataTable
