import React from 'react'
import { notification } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import * as routes from '../../../routes'
import CustomerPageError from '../../layout/CustomerPageError'
import { removeFromCollectionWhereId } from '../../../util/index'
import CustomerAssetsPage from './CustomerAssetsPage'
import useCreateCustomerAssetMutation from './useCreateCustomerAssetMutation'
import useDeleteCustomerAssetMutation from './useDeleteCustomerAssetMutation'
import useToggleSuspendCustomerAssetMutation from './useToggleSuspendCustomerAssetMutation'
import useCustomerAssetsPageQuery, { updateCustomerAssetsPageData } from './useCustomerAssetsPageQuery'

const activeMenuItem = 'assets'

const CustomerAssetsPageWithState = () => {
    const params = useParams()
    const history = useHistory()
    const customerId = parseInt(params.customerId, 10)
    const [
        createCustomerAsset,
        { loading: isCreatingCustomerAsset },
    ] = useCreateCustomerAssetMutation()
    const [
        deleteCustomerAsset,
        { loading: isDeletingCustomerAsset },
    ] = useDeleteCustomerAssetMutation()
    const [toggleSuspendCustomerAsset] = useToggleSuspendCustomerAssetMutation()
    const {
        error,
        isFetching,
        assets,
        customer,
        customerAssets,
        refetchQueries,
    } = useCustomerAssetsPageQuery({ variables: { customerId } })

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem={activeMenuItem}
            />
        )
    }

    const handleCreateCustomerAsset = async (assetId) => {
        try {
            const { data } = await createCustomerAsset({
                variables: {
                    assetId,
                    customerId,
                },
                refetchQueries
            })
            notification.success({
                message: 'Asset Created!',
                description: `Asset ${data.createCustomerAsset.customerAsset.asset.name} successfully created`,
            })
        } catch (e) {
            notification.error({
                message: 'Something went wrong',
                description: `The following error occurred: ${e}`,
            })
        }
    }

    const handleToggleSuspendCustomerAsset = async (asset) => {
        try {
            const { data } = await toggleSuspendCustomerAsset({
                variables: {
                    id: asset.id,
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    toggleSuspendCustomerAsset: {
                        __typename: 'CustomerAssetPayload',
                        customerAsset: {
                            __typename: 'CustomerAsset',
                            ...asset,
                            suspended: !asset.suspended,
                        },
                    },
                },
            })
            let successMessage = 'Asset was successfully suspended'
            if (data.toggleSuspendCustomerAsset.customerAsset.suspended === false) {
                successMessage = 'Asset was successfully made available again'
            }
            notification.success({
                message: 'Asset Updated!',
                description: successMessage,
            })
        } catch (e) {
            notification.error({
                message: 'Something went wrong',
                description: `The following error occurred: ${e}`,
            })
        }
    }

    const handleDeleteCustomerAsset = async (asset) => {
        try {
            await deleteCustomerAsset({
                variables: { id: asset.id }
            })
            updateCustomerAssetsPageData({
                path: 'customerAssets',
                value: removeFromCollectionWhereId(asset.id),
                variables: { customerId },
            })
            notification.success({
                message: 'Asset Deleted!',
                description: 'The asset was successfully deleted',
            })
        } catch (e) {
            notification.error({
                message: 'Something went wrong',
                description: `The following error occurred: ${e}`,
            })
        }
    }

    return (
        <CustomerAssetsPage
            activeMenuItem={activeMenuItem}
            customer={customer}
            assets={assets}
            customerAssets={customerAssets}
            isLoading={isFetching}
            isCreatingCustomerAsset={isCreatingCustomerAsset}
            onCreateCustomerAsset={handleCreateCustomerAsset}
            onToggleSuspendCustomerAsset={handleToggleSuspendCustomerAsset}
            onEditCustomerAsset={(asset) => {
                history.push(routes.updateCustomerAsset(customerId, asset.id))
            }}
            isDeletingCustomerAsset={isDeletingCustomerAsset}
            onDeleteCustomerAsset={handleDeleteCustomerAsset}
        />
    )
}

export default CustomerAssetsPageWithState
