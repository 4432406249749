import React from 'react'
import { Alert } from 'antd'

const PriceAdjustmentInfoAlert = () => (
    <Alert
        showIcon
        className="my-4 bg-blue-50 border-blue-300"
        message="Customer price calculation"
        description={(
            <div>
                {/* eslint-disable-next-line max-len */}
                Customer <strong>buys</strong> from Gunvor: Offer price + premium x density x conversion rate + loading fee + bio fee + duties = Buy price
            </div>
        )}
    />
)

export default PriceAdjustmentInfoAlert
