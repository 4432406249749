import React from 'react'
import { Button } from 'antd'

const CustomerOrdersTableFilter = ({
    filters,
    onResetTableFilter,
}) => {
    const appliedFilters = []
    if (filters.orderId !== null) {
        appliedFilters.push(`Ref. ${filters.orderId}`)
    }

    if (appliedFilters.length <= 0) {
        return (
            <div className="flex items-center">
                <h3 className="text-xl">Applied Filters:</h3>
                <span className="ml-3">None</span>
            </div>
        )
    }

    return (
        <div className="flex items-center">
            <h3 className="text-xl">Applied Filters:</h3>
            {appliedFilters.map((filter) => (
                <div
                    key={filter}
                    className="ml-3"
                >
                    {filter}
                </div>
            ))}
            <div className="ml-3">
                <Button
                    size="small"
                    onClick={onResetTableFilter}
                >
                    Reset
                </Button>
            </div>
        </div>
    )
}

export default CustomerOrdersTableFilter
