import { gql, useQuery } from '@apollo/client'
import OpeningHoursItem from './OpeningHoursItem'

const query = gql`
    query openingsHoursPageQuery {
        openingHours {
            ...OpeningHoursItem
        }
    }
    ${OpeningHoursItem.FRAGMENT}
`

const useOpeningHoursPageQuery = (options) => {
    const {
        data = {},
        error,
        loading: isLoading,
        ...result
    } = useQuery(query, options)

    let openingHours = []
    if (typeof error !== 'undefined') {
        return {
            error,
            isLoading,
            openingHours
        }
    }

    if (isLoading) {
        return {
            isLoading,
            openingHours,
        }
    }

    ({ openingHours } = data)

    return {
        isLoading,
        openingHours,
        ...result
    }
}

export default useOpeningHoursPageQuery
