import React from 'react'
import CustomerPage from '../../layout/CustomerPage'
import CustomerTopBar from '../../layout/CustomerTopBar'
import CustomerAssetsList from './CustomerAssetsList'
import CustomerAssetCreateButton from './CustomerAssetCreateButton'

const CustomerAssetsPage = ({
    activeMenuItem,
    customer,
    assets,
    customerAssets,
    isLoading,
    isCreatingCustomerAsset,
    isDeletingCustomerAsset,
    onEditCustomerAsset,
    onCreateCustomerAsset,
    onDeleteCustomerAsset,
    onToggleSuspendCustomerAsset,
}) => (
    <CustomerPage
        customerId={customer.id}
        isLoading={isLoading}
        activeMenuItem={activeMenuItem}
    >
        <CustomerTopBar
            customerName={customer.name}
            rightChildren={(
                <CustomerAssetCreateButton
                    disabled={assets.length === customerAssets.length}
                    assets={assets.filter(({ id }) => (
                        !customerAssets.find(({ asset }) => asset.id === id)
                    ))}
                    loading={isCreatingCustomerAsset}
                    onCreateCustomerAsset={onCreateCustomerAsset}
                />
            )}
        />
        <CustomerAssetsList
            customerAssets={customerAssets}
            onToggleSuspendCustomerAsset={onToggleSuspendCustomerAsset}
            onEditCustomerAsset={onEditCustomerAsset}
            isDeletingCustomerAsset={isDeletingCustomerAsset}
            onDeleteCustomerAsset={onDeleteCustomerAsset}
        />
    </CustomerPage>
)

export default CustomerAssetsPage
