export const SIDE_BUY = 'BUY'
export const SIDE_SELL = 'SELL'

export const SIDES = [
    SIDE_BUY,
    SIDE_SELL,
]

export const CURRENCY_CODE_EUR = 'EUR'
export const CURRENCY_CODE_USD = 'USD'
export const UNIT_CODE_MILLIGRAM = 'mg'
export const UNIT_CODE_GRAM = 'g'
export const UNIT_CODE_KILOGRAM = 'kg'
export const UNIT_CODE_METRIC_TON = 'ton'

export const UNIT_CODE_MILLILITER = 'ml'
export const UNIT_CODE_LITER = 'l'
export const UNIT_CODE_CUBM = 'cubm'

export const CONVERSION_TABLE_MASS = {
    [UNIT_CODE_MILLIGRAM]: 1e0,
    [UNIT_CODE_GRAM]: 1e3,
    [UNIT_CODE_KILOGRAM]: 1e6,
    [UNIT_CODE_METRIC_TON]: 1e9,
}

export const CONVERSION_TABLE_VOLUME = {
    [UNIT_CODE_MILLILITER]: 1e3,
    [UNIT_CODE_LITER]: 1e6,
    [UNIT_CODE_CUBM]: 1e9,
}

export const AVAILABLE_MASS = [
    UNIT_CODE_METRIC_TON,
]

export const AVAILABLE_VOLUMES = [
    UNIT_CODE_LITER,
    UNIT_CODE_CUBM,
]

export const GRAM_ROUND = 0
export const OUNCE_ROUND = 0
export const KILOGRAM_ROUND = 0
export const TON_ROUND = 3
export const CUBM_ROUND = 3
export const LITER_ROUND = 0

export const ROUND_DOWN = 'down'
export const ROUND_UP = 'up'

export const DENSITY_ACCURACY_RATE = 1000
export const CURRENCY_ACCURACY_RATE = 1000
export const MARKET_DATA_RESOLVE_ACCURACY = 10
export const MARKET_DATA_ACCURACY_RATE = 10000
export const PRICE_ADJUSTMENT_ACCURACY = 100000
export const PRICE_ACCURACY = 100
export const END_PRICE_ACCURACY = 10
export const PRICE_ROUNDING = 2
export const CONVERSION_ACCURACY = 10000
export const DUTIES_ROUNDING = 4
export const DUTIES_ACCURACY = 10000
export const ADJUSTMENT_ACCURACY = 1000
