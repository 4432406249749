import { gql, useMutation } from '@apollo/client'
import MarketDataTable from './MarketDataTable'

const mutation = gql`
    mutation updateMarketDataMutation($id: Int!, $input: UpdateMarketDataInput!) {
        updateMarketData(id: $id, input: $input) {
            ...MarketDataTable
        }
    }
    ${MarketDataTable.FRAGMENT}
`

const useUpdateMarketDataMutation = (options) => (
    useMutation(mutation, options)
)

export default useUpdateMarketDataMutation
