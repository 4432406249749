import React from 'react'
import { Tag, Popover } from 'antd'
import { milligramToUnit } from '../../../../../util/units'
import { SIDE_SELL } from '../../../../../util/constants'
import PreferredUnits from '../../../../util/PreferredUnits'
import PreferredCurrencyValue from '../../../../util/PreferredCurrencyValue'
import TradeLogItem from './TradeLogItem'

const OrderCreatedItem = ({
    assets,
    appEvent,
}) => {
    const {
        customer, user, createdAt, payload,
    } = appEvent
    const {
        side, assetId, quantity, totalPrice,
    } = payload
    const isSell = side === SIDE_SELL
    const color = isSell ? 'green' : 'blue'
    return (
        <TradeLogItem
            isCreated
            createdAt={createdAt}
            user={user}
        >
            <div>
                Order for
                {' '}<strong>{customer.name}</strong>{' '}
                created
                {' '}
                <Popover
                    placement="bottom"
                    content={(
                        <div>
                            <ul>
                                <li>Asset:
                                    <strong>
                                        {assets.find(({ id }) => id === assetId).name}
                                    </strong>
                                </li>
                                <li>Quantity:
                                    <ul>
                                        <li>
                                            Total:
                                            {' '}
                                            <strong>
                                                <PreferredUnits>
                                                    {({ preferredUnitCode }) => (
                                                        `${milligramToUnit(quantity, preferredUnitCode)}${preferredUnitCode}`
                                                    )}
                                                </PreferredUnits>
                                            </strong>
                                        </li>
                                    </ul>
                                </li>
                                <li>Price:
                                    <ul>
                                        <li>
                                            Total:
                                            {' '}
                                            <strong>
                                                <PreferredCurrencyValue
                                                    value={Math.abs(totalPrice)}
                                                />
                                            </strong>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    )}
                >
                    <Tag color={color}>Data</Tag>
                </Popover>
            </div>
        </TradeLogItem>
    )
}

export default OrderCreatedItem
