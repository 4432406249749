import React from 'react'
import { gql } from '@apollo/client'
import {
    Col,
    Row,
    Form,
    Input,
    InputNumber
} from 'antd'
import FormItem from '../../util/form/FormItem'
import PriceAdjustmentsTable from './PriceAdjustmentsTable'

const FRAGMENT = gql`
    fragment AssetForm on Asset {
        id
        name
        code
        density
        premium
        conversionRateExtra
    }
`

const AssetForm = ({
    formState,
}) => {
    const {
        getInputProps,
        getNativeInputProps,
        antdErrors,
    } = formState
    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col md={12} sm={24}>
                    <FormItem
                        label="Name"
                        error={antdErrors.get('name')}
                    >
                        <Input
                            {...getNativeInputProps('name')}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col md={12} sm={24}>
                    <FormItem
                        label="Code"
                        error={antdErrors.get('code')}
                    >
                        <Input
                            disabled
                            {...getNativeInputProps('code')}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col md={6} sm={12}>
                    <FormItem
                        label="Premium"
                        error={antdErrors.get('premium')}
                    >
                        <InputNumber
                            {...getInputProps('premium')}
                            suffix="USD/MTON"
                            precision={2}
                            step={0.25}
                            decimalSeparator=","
                            className="w-full"
                        />
                    </FormItem>
                </Col>
                <Col md={6} sm={12}>
                    <FormItem
                        label="Conversion rate extra"
                        error={antdErrors.get('conversionRateExtra')}
                    >
                        <InputNumber
                            {...getInputProps('conversionRateExtra')}
                            min={0}
                            precision={4}
                            step={0.0001}
                            decimalSeparator=","
                            className="w-full"
                        />
                    </FormItem>
                </Col>
                <Col md={6} sm={12}>
                    <FormItem
                        label="Density"
                        error={antdErrors.get('density')}
                    >
                        <InputNumber
                            {...getInputProps('density')}
                            min={0}
                            precision={3}
                            step={0.001}
                            decimalSeparator=","
                            className="w-full"
                        />
                    </FormItem>
                </Col>
            </Row>
            <h2 className="text-xl font-medium mb-4">Product Variations:</h2>
            <PriceAdjustmentsTable
                formState={formState}
            />
        </Form>
    )
}

AssetForm.FRAGMENT = FRAGMENT

export default AssetForm
