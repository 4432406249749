import React from 'react'
import { gql } from '@apollo/client'
import { Select } from 'antd'
import FormItem from './FormItem'
import { whereId } from '../index'

const { Option } = Select

const FRAGMENT = gql`
    fragment AssetSelect on Asset {
        id
        name
        code
    }
`

const AssetSelect = ({
    isRequired,
    disabled = false,
    assets = [],
    error,
    label,
    value,
    ...props
}) => {
    const hasAssets = assets.length > 0
    const hasValue = typeof assets.find(whereId(value)) !== 'undefined'
    return (
        <FormItem
            isRequired={isRequired}
            label={label}
            error={error}
        >
            {hasAssets && !hasValue ? (
                <p>Asset not available</p>
            ) : (
                <Select
                    disabled={disabled}
                    placeholder="Select an asset"
                    value={hasValue && value}
                    {...props}
                >
                    {assets.map((option) => (
                        <Option
                            key={option.id}
                            value={option.id}
                        >
                            {option.name} - {option.code}
                        </Option>
                    ))}
                </Select>
            )}
        </FormItem>
    )
}

AssetSelect.FRAGMENT = FRAGMENT

export default AssetSelect
