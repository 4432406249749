// @TODO: remove export once all functions have moved
export * from '@gunvor-trading/shared/utils/constants'

export const TRADE_LIMIT_TYPE_FIXED = 'fixed'
export const TRADE_LIMIT_TYPE_MARGIN = 'margin'
export const TRADE_LIMIT_TYPES = [
    TRADE_LIMIT_TYPE_FIXED,
    TRADE_LIMIT_TYPE_MARGIN,
]

export const MIN_TRADE_LIMIT_MARGIN = 1
export const MAX_TRADE_LIMIT_MARGIN = 100

export const PUSHER_ADMIN_CHANNEL_IDENTIFIER = 'adminChannel'
export const PUSHER_ADMIN_CHANNEL_NAME = 'private-adminChannel'
export const PUSHER_CUSTOMER_CHANNEL_IDENTIFIER = 'customerChannel'
export const PUSHER_CUSTOMER_CHANNEL_NAME = 'private-customerChannel-' // Followed by customerId
