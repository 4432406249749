import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { customers } from '../../routes'
import CustomerPage from './CustomerPage'
import CustomerTopBar from './CustomerTopBar'
import CustomerPageContentPlaceholder from './CustomerPageContentPlaceholder'

const CustomerPageError = ({
    customerId,
    activeMenuItem,
}) => {
    const history = useHistory()

    return (
        <CustomerPage
            customerId={customerId}
            activeMenuItem={activeMenuItem}
        >
            <CustomerTopBar />
            <CustomerPageContentPlaceholder>
                <h3 className="text-xl">Failed to load page</h3>
                <Button
                    type="primary"
                    onClick={() => history.push(customers())}
                >
                    Back to overview
                </Button>
            </CustomerPageContentPlaceholder>
        </CustomerPage>
    )
}

export default CustomerPageError
