import React from 'react'
import clsx from 'clsx'
import { PRICE_ROUNDING } from '@gunvor-trading/shared/utils/constants'
import { formatCurrencyNotation, roundCurrency } from '../../util/currency'

const CurrencyValue = ({
    formatValue = true,
    isBold,
    isNegative,
    isPositive,
    value,
    currencyCode,
    displayCurrencyCode = currencyCode,
    rounding = PRICE_ROUNDING,
}) => (
    <span
        className={
            clsx(isBold && 'font-bold')
        }
    >
        {(isPositive || isNegative) && (
            <span
                className={
                    clsx(
                        'mr-1',
                        isPositive && 'text-green-500',
                        isNegative && 'text-red-500',
                    )
                }
            >
                {isPositive ? '+' : '-'}
            </span>
        )}
        <span>
            {formatValue
                ? formatCurrencyNotation(Math.abs(value), 'EUR')
                : roundCurrency(Math.abs(value), rounding)}
        </span>
        &nbsp;
        {displayCurrencyCode && (
            <span>
                {displayCurrencyCode}
            </span>
        )}
    </span>
)

export default CurrencyValue
