import React from 'react'
import {
    Route,
    Switch,
    Router,
} from 'react-router-dom'
import * as routes from '../routes'
import LoginPage from './pages/login/LoginPage'
import LogsPageWithState from './pages/logs/LogsPageWithState'
import UsersPageWithState from './pages/users/UsersPageWithState'
import AssetsPageWithState from './pages/assets/AssetsPageWithState'
import OrdersPageWithState from './pages/orders/OrdersPageWithState'
import PricesPageWithState from './pages/prices/PricesPageWithState'
import ForgotPasswordPage from './pages/forgot-password/ForgotPasswordPage'
import ChoosePasswordPage from './pages/choose-password/ChoosePasswordPage'
import CustomersPageWithState from './pages/customers/CustomersPageWithState'
import MarketDataPageWithState from './pages/market-data/MarketDataPageWithState'
import AppSettingsPageWithState from './pages/app-settings/AppSettingsPageWithState'
import UpdateAssetPageWithState from './pages/update-asset/UpdateAssetPageWithState'
import OpeningHoursPageWithState from './pages/opening-hours/OpeningHoursPageWithState'
import CustomerUsersPageWithState from './pages/customer-users/CustomerUsersPageWithState'
import CustomerAssetsPageWithState from './pages/customer-assets/CustomerAssetsPageWithState'
import CustomerPricesPageWithState from './pages/customer-prices/CustomerPricesPageWithState'
import CustomerOrdersPageWithState from './pages/customer-orders/CustomerOrdersPageWithState'
import CustomerBalancePageWithState from './pages/customer-balance/CustomerBalancePageWithState'
import CustomerSettingsPageWithState from './pages/customer-settings/CustomerSettingsPageWithState'
import UpdateCustomerAssetPageWithState from './pages/update-customer-asset/UpdateCustomerAssetPageWithState'
import CustomerOpeningHoursPageWithState from './pages/customer-opening-hours/CustomerOpeningHoursPageWithState'

const AppRouter = ({
    history,
    isAuthenticated,
    ...props
}) => {
    if (!isAuthenticated) {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path={routes.forgotPassword()}>
                        <ForgotPasswordPage {...props} />
                    </Route>
                    <Route exact path={routes.choosePassword()}>
                        <ChoosePasswordPage {...props} />
                    </Route>
                    <Route path={routes.login()}>
                        <LoginPage {...props} />
                    </Route>
                    <Route path="/">
                        <LoginPage {...props} />
                    </Route>
                </Switch>
            </Router>
        )
    }
    return (
        <Router history={history}>
            <Switch>
                <Route exact path={routes.assetOrders()}>
                    <OrdersPageWithState {...props} />
                </Route>
                <Route exact path={routes.customers()}>
                    <CustomersPageWithState {...props} />
                </Route>
                <Route exact path={routes.customer()}>
                    <CustomerBalancePageWithState {...props} />
                </Route>
                <Route exact path={routes.customerBalance()}>
                    <CustomerBalancePageWithState {...props} />
                </Route>
                <Route exact path={routes.customerOrders()}>
                    <CustomerOrdersPageWithState {...props} />
                </Route>
                <Route exact path={routes.customerPrices()}>
                    <CustomerPricesPageWithState {...props} />
                </Route>
                <Route exact path={routes.customerUsers()}>
                    <CustomerUsersPageWithState {...props} />
                </Route>
                <Route exact path={routes.customerSettings()}>
                    <CustomerSettingsPageWithState {...props} />
                </Route>
                <Route exact path={routes.customerAssetSettings()}>
                    <CustomerAssetsPageWithState {...props} />
                </Route>
                <Route exact path="/customer/:customerId/opening-hours">
                    <CustomerOpeningHoursPageWithState {...props} />
                </Route>
                <Route exact path={routes.users()}>
                    <UsersPageWithState {...props} />
                </Route>
                <Route exact path={routes.assets()}>
                    <AssetsPageWithState {...props} />
                </Route>
                <Route exact path={routes.logs()}>
                    <LogsPageWithState {...props} />
                </Route>
                <Route exact path={routes.appSettings()}>
                    <AppSettingsPageWithState {...props} />
                </Route>
                <Route exact path="/market-data">
                    <MarketDataPageWithState {...props} />
                </Route>
                <Route exact path={routes.updateAsset()}>
                    <UpdateAssetPageWithState {...props} />
                </Route>
                <Route exact path={routes.updateCustomerAsset()}>
                    <UpdateCustomerAssetPageWithState {...props} />
                </Route>
                <Route exact path={routes.prices()}>
                    <PricesPageWithState {...props} />
                </Route>
                <Route exact path="/opening-hours">
                    <OpeningHoursPageWithState {...props} />
                </Route>
                <Route path="/">
                    <OrdersPageWithState {...props} />
                </Route>
            </Switch>
        </Router>
    )
}

export default AppRouter
