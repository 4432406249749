import React from 'react'
import {
    Form, Input, Button, Alert,
} from 'antd'
import { Link } from 'react-router-dom'
import * as routes from '../../../routes'
import FormItem from '../../util/form/FormItem'

const LoginForm = ({
    isLoading,
    errorMessage,
    values,
    errors,
    onChange,
    onSubmit,
}) => (
    <Form layout="vertical">
        {errorMessage !== null
            && (
                <Alert
                    className="mb-4"
                    message="Login failed"
                    description={errorMessage}
                    type="error"
                    showIcon
                />
            )}
        <FormItem
            label="Email"
            error={errors.email}
        >
            <Input
                name="email"
                value={values.email}
                onChange={onChange}
            />
        </FormItem>
        <FormItem
            label="Password"
            error={errors.password}
        >
            <Input
                type="password"
                name="password"
                value={values.password}
                onChange={onChange}
            />
        </FormItem>
        <div className="flex items-center justify-between">
            <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                onClick={onSubmit}
            >
                Login
            </Button>
            <Link to={routes.forgotPassword()}>Forgot password?</Link>
        </div>
    </Form>
)

export default LoginForm
