import React from 'react'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'
import * as routes from '../../../routes'
import SettingsPageError from '../../layout/SettingsPageError'
import AssetsPage from './AssetsPage'
import useAssetsPageQuery from './useAssetsPageQuery'
import useToggleSuspendAssetMutation from './useToggleSuspendAssetMutation'
import useToggleAvailableAssetMutation from './useToggleAvailableAssetMutation'

const activeMenuItem = 'assets'

const AssetsPageWithState = () => {
    const history = useHistory()
    const [toggleSuspendAsset] = useToggleSuspendAssetMutation()
    const [toggleAvailableAsset] = useToggleAvailableAssetMutation()
    const {
        error,
        isFetching,
        assets,
    } = useAssetsPageQuery()

    if (typeof error !== 'undefined') {
        return (
            <SettingsPageError
                activeMenuItem={activeMenuItem}
            />
        )
    }

    const handleToggleSuspendAsset = async (asset) => {
        try {
            const { data } = await toggleSuspendAsset({
                variables: {
                    id: asset.id,
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    toggleSuspendAsset: {
                        __typename: 'AssetPayload',
                        asset: {
                            __typename: 'Asset',
                            ...asset,
                            suspended: !asset.suspended,
                        },
                    },
                },
            })
            let successMessage = 'Asset was successfully suspended'
            if (data.toggleSuspendAsset.asset.suspended === false) {
                successMessage = 'Asset was successfully allowed for trading'
            }
            notification.success({
                message: 'Success',
                description: successMessage,
            })
        } catch (e) {
            notification.error({
                message: 'Something went wrong',
                description: `The following error occurred: ${e}`,
            })
        }
    }

    const handleToggleAvailableAsset = async (asset) => {
        try {
            const { data } = await toggleAvailableAsset({
                variables: {
                    id: asset.id,
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    toggleAvailableAsset: {
                        __typename: 'AssetPayload',
                        asset: {
                            __typename: 'Asset',
                            ...asset,
                            available: !asset.available,
                        },
                    },
                },
            })
            let successMessage = 'Asset was successfully made available'
            if (data.toggleAvailableAsset.asset.available === false) {
                successMessage = 'Asset was successfully made unavailable'
            }
            notification.success({
                message: 'Success',
                description: successMessage,
            })
        } catch (e) {
            notification.error({
                message: 'Something went wrong',
                description: `The following error occurred: ${e}`,
            })
        }
    }

    return (
        <AssetsPage
            isLoading={isFetching}
            activeMenuItem={activeMenuItem}
            assets={assets}
            onEditAsset={(asset) => {
                history.push(routes.updateAsset(asset.id))
            }}
            onSuspendAsset={handleToggleSuspendAsset}
            onToggleAvailableAsset={handleToggleAvailableAsset}
        />
    )
}

export default AssetsPageWithState
