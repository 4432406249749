import React from 'react'
import AssetCard from './AssetCard'
import AssetCardWithState from './AssetCardWithState'

const AssetCardWithAdjustment = ({
    asset,
}) => {
    const { productVariations } = asset
    if (productVariations.length === 0) {
        return <AssetCard asset={asset} />
    }

    return <AssetCardWithState asset={asset} />
}

export default AssetCardWithAdjustment
