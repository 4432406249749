import React from 'react'
import SettingsPage from '../SettingsPage'
import AssetsList from './AssetsList'

const AssetsPage = ({
    activeMenuItem,
    isLoading,
    assets,
    onEditAsset,
    onSuspendAsset,
    onToggleAvailableAsset,
}) => (
    <SettingsPage
        activeMenuItem={activeMenuItem}
        isLoading={isLoading}
    >
        <h1 className="font-black text-3xl mb-3">
            Assets
        </h1>
        <AssetsList
            assets={assets}
            onEditAsset={onEditAsset}
            onSuspendAsset={onSuspendAsset}
            onToggleAvailableAsset={onToggleAvailableAsset}
        />
    </SettingsPage>
)

export default AssetsPage
