import { gql, useMutation } from '@apollo/client'

const mutation = gql`
    mutation toggleAvailableAsset($id: Int!) {
        toggleAvailableAsset(id: $id) {
            asset {
                id
                available
            }
        }
    }
`

const useToggleAvailableAssetMutation = (options) => (
    useMutation(mutation, options)
)

export default useToggleAvailableAssetMutation
