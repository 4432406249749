import React from 'react'
import { Input, Select } from 'antd'
import {
    getCountries,
    parsePhoneNumber,
    getCountryPhoneCode,
    getCountryCodeFromString,
} from '../../../util/phone'

const countries = getCountries()

class PhoneInput extends React.Component {
    constructor(props) {
        super(props)
        const {
            value,
            inputRef,
            selectRef,
        } = props

        this.state = {
            countryCode: getCountryCodeFromString(value) || 'BE',
        }

        this.inputRef = inputRef || React.createRef()
        this.selectRef = selectRef || React.createRef()
    }

    render() {
        const {
            name,
            placeholder,
            value,
            onChange,
        } = this.props
        const { countryCode } = this.state
        return (
            <Input
                ref={this.inputRef}
                type="tel"
                name={name}
                placeholder={placeholder}
                className="hasPrefix"
                value={value}
                onChange={(e) => {
                    onChange(parsePhoneNumber(e.target.value, countryCode))
                }}
                addonBefore={(
                    <Select
                        ref={this.selectRef}
                        showSearch
                        dropdownMatchSelectWidth={false}
                        optionFilterProp="children"
                        optionLabelProp="value"
                        value={countryCode}
                        style={{ width: 65 }}
                        onChange={(newCountryCode) => {
                            this.setState({ countryCode: newCountryCode })
                            onChange(getCountryPhoneCode(newCountryCode))
                            this.inputRef.current.focus()
                        }}
                    >
                        {countries.map(({
                            code,
                            name: countryName,
                            phone,
                        }) => (
                            <Select.Option
                                key={code}
                                value={code}
                                title={code}
                            >
                                {countryName} (+{phone})
                            </Select.Option>
                        ))}
                    </Select>
                )}
            />
        )
    }
}

export default PhoneInput
