import React from 'react'
import { gql } from '@apollo/client'
import {
    InputNumber,
    Button,
    Table,
    Input
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import UnitsSelectWithCache from '../../util/form/UnitSelectWithCache'
import CurrencySelectWithCache from '../../util/form/CurrencySelectWithCache'
import FormItem from '../../util/form/FormItem'

const { Column } = Table

const FRAGMENT = gql`
    fragment PriceAdjustmentsTable on ProductVariation {
        id
        name
        unitCode
        currencyCode
        loadingFee
        bioFee
        duties
    }
`

export const ROUNDINGS = [{
    label: '100',
    value: '-4',
}, {
    label: '10',
    value: '-3',
}, {
    label: '0',
    value: '-2',
}, {
    label: '0.1',
    value: '-1',
}, {
    label: '0.01',
    value: '0',
}]

const PriceAdjustmentsTable = ({
    formState,
}) => {
    const {
        values,
        antdErrors,
        getInputProps,
        getNativeInputProps,
        handleAddPriceAdjustment,
        handleRemovePriceAdjustment,
    } = formState
    return (
        <>
            <Table
                rowKey="id"
                pagination={false}
                dataSource={values.productVariations}
                className="mb-3"
            >
                <Column
                    title="Name"
                    dataIndex="name"
                    render={(value, record, index) => (
                        <FormItem
                            className="mb-0"
                            error={antdErrors.get(`productVariations.${index}.name`)}
                        >
                            <Input
                                {...getNativeInputProps(`productVariations.${index}.name`)}
                            />
                        </FormItem>
                    )}
                />
                <Column
                    title="Currency"
                    dataIndex="currencyCode"
                    render={(value, record, index) => (
                        <CurrencySelectWithCache
                            {...getInputProps(`productVariations.${index}.currencyCode`)}
                        />
                    )}
                />
                <Column
                    title="Unit"
                    dataIndex="unitCode"
                    render={(value, record, index) => (
                        <UnitsSelectWithCache
                            {...getInputProps(`productVariations.${index}.unitCode`)}
                        />
                    )}
                />
                <Column
                    title="Loading fee"
                    dataIndex="loadingFee"
                    render={(value, record, index) => (
                        <InputNumber
                            {...getInputProps(`productVariations.${index}.loadingFee`)}
                            min={0}
                            precision={2}
                            step={0.01}
                            decimalSeparator=","
                        />
                    )}
                />
                <Column
                    title="Bio fee"
                    dataIndex="bioFee"
                    render={(value, record, index) => (
                        <InputNumber
                            {...getInputProps(`productVariations.${index}.bioFee`)}
                            min={0}
                            precision={2}
                            step={0.01}
                            decimalSeparator=","
                        />
                    )}
                />
                <Column
                    title="Duties"
                    dataIndex="duties"
                    render={(value, record, index) => (
                        <InputNumber
                            {...getInputProps(`productVariations.${index}.duties`)}
                            min={0}
                            precision={4}
                            step={0.0001}
                            decimalSeparator=","
                        />
                    )}
                />
                <Column
                    render={(value, record, index) => (
                        <DeleteOutlined
                            onClick={() => handleRemovePriceAdjustment(index)}
                        />
                    )}
                />
            </Table>
            <Button
                onClick={handleAddPriceAdjustment}
                type="primary"
            >
                Add price adjustment
            </Button>
        </>
    )
}

PriceAdjustmentsTable.FRAGMENT = FRAGMENT

export default PriceAdjustmentsTable
