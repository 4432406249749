import React from 'react'
import {
    Form, Input, Button, Alert,
} from 'antd'
import FormItem from '../../util/form/FormItem'

const ForgotPasswordForm = ({
    isLoading,
    values,
    errors,
    error,
    onChange,
    onSubmit,
    passwordIsReset,
}) => {
    if (passwordIsReset) {
        return (
            <Alert
                message="A reset password link has been sent to your mail."
                type="success"
                showIcon
            />
        )
    }
    return (
        <Form layout="vertical">
            <FormItem
                label="Email"
                error={errors.email}
            >
                <Input
                    name="email"
                    value={values.email}
                    onChange={onChange}
                />
            </FormItem>

            {typeof error !== 'undefined' && (
                <FormItem>
                    <Alert
                        message={error.message}
                        type="error"
                    />
                </FormItem>
            )}

            <Button
                disabled={isLoading}
                type="primary"
                onClick={onSubmit}
            >
                Send Password Reset Link
            </Button>
        </Form>
    )
}

export default ForgotPasswordForm
