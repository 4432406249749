import React from 'react'
import CustomerAssetLogItem from './CustomerAssetLogItem'

const CustomerAssetDeletedItem = ({
    appEvent
}) => {
    const {
        customer, user, createdAt, payload
    } = appEvent
    const { name, code } = payload
    return (
        <CustomerAssetLogItem
            isDeleted
            createdAt={createdAt}
            user={user}
        >
            <div>
                <strong>{name} ({code})</strong>
                {' '}
                for
                {' '}
                <strong>{customer.name}</strong>
                {' '}
                deleted.
            </div>
        </CustomerAssetLogItem>
    )
}

export default CustomerAssetDeletedItem
