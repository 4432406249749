import { gql, useMutation } from '@apollo/client'
import UpdateCustomerOpeningHoursItem from './UpdateCustomerOpeningHoursItem'

const mutation = gql`
    mutation updateCustomerOpeningHours(
        $customerId: Int!,
        $input: UpdateCustomerOpeningHoursInput!
    ) {
        updateCustomerOpeningHours(
            customerId: $customerId,
            input: $input
        ) {
            customerOpeningHours {
                ...UpdateCustomerOpeningHoursItem
            }
        }
    }
    ${UpdateCustomerOpeningHoursItem.FRAGMENT}
`

const useUpdateCustomerOpeningHoursMutation = (options) => (
    useMutation(mutation, options)
)

export default useUpdateCustomerOpeningHoursMutation
