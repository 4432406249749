import dotProp from 'dot-prop-immutable'
import { parse } from 'query-string'
import { navigate as reachNavigate } from '@reach/router'

export const delay = (time, value) => (
    new Promise((resolve) => {
        setTimeout(resolve, time, value)
    })
)

export const parseQueryString = (search) => (
    parse(search)
)

export const capitalizeFirstLetter = (string) => (string ? string[0].toUpperCase() + string.substring(1) : '')

export const navigate = (route, openInNewTab = false, state) => {
    if (openInNewTab) {
        window.open(route, '_blank')
    } else {
        reachNavigate(route, { state })
    }
}

export const clientFilterOptions = (input, array) => (
    array.filter((i) => i.slug.toLowerCase().includes(input.toLowerCase()))
)

export const sum = (current, next) => current + next

export const countWhere = (key) => (current, obj) => current + obj[key]

export const flatten = (a, b) => [...a, ...b]

export const unique = (array) => [...new Set(array)]

export const where = (...keys) => (...values) => (obj) => keys.every((key) => (key.includes('!') ? !values.includes(obj[key.replace('!', '')]) : values.includes(obj[key])))

export const whereIsNot = (key) => (value) => (obj) => obj[key] !== value

export const whereId = where('id')

export const whereIsNotId = whereIsNot('id')

export const whereUnique = (key) => (currentObject, currentIndex, array) => {
    const value = dotProp.get(currentObject, key)
    if (typeof value === 'undefined') {
        return true
    }
    const foundIndex = array.findIndex((object) => (
        dotProp.get(object, key) === value
    ))
    return foundIndex === currentIndex
}

export const whereCode = where('code')

export const removeFromCollection = (id) => (collection) => collection.filter(whereIsNotId(id))

export const addToCollection = (obj) => (collection) => [...collection, obj]

export const replaceInCollection = (obj) => (collection) => collection.map(
    (i) => (i.id === obj.id ? obj : i),
)

export const sortBy = (key) => (a, b) => {
    if (a === null || b === null) {
        return -1
    }
    const aComp = a[key]
    const bComp = b[key]
    if (aComp < bComp) {
        return -1
    }
    if (aComp > bComp) {
        return 1
    }
    return 0
}
