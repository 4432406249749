import { useQuery, gql } from '@apollo/client'
import AssetCard from './AssetCard'

export const query = gql`
    query assetsPageQuery {
        assets {
            ...AssetCard
        }
    }
    ${AssetCard.FRAGMENT}
`

const useAssetsPageQuery = () => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(query)

    const { assets = {} } = data

    return {
        error,
        isFetching: loading,
        assets,
    }
}

export default useAssetsPageQuery
