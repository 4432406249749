import React from 'react'
import SettingsPage from '../SettingsPage'
import OpeningHoursForm from './OpeningHoursForm'

const OpeningHoursPage = ({
    activeMenuItem,
    isLoading,
    isSubmitting,
    formState,
    onSubmit,
}) => (
    <SettingsPage
        activeMenuItem={activeMenuItem}
        isLoading={isLoading}
    >
        <h1 className="font-black text-3xl mb-3">
            Opening hours
        </h1>
        <OpeningHoursForm
            isSubmitting={isSubmitting}
            formState={formState}
            onSubmit={onSubmit}
        />
    </SettingsPage>
)

export default OpeningHoursPage
