import { useQuery, gql } from '@apollo/client'
import { createQueryUpdater } from '../../../util/graphql'
import CustomerTopBar from '../../layout/CustomerTopBar'
import CustomerAssetsList from './CustomerAssetsList'
import CustomerAssetCreateButton from './CustomerAssetCreateButton'

export const CUSTOMER_ASSETS_PAGE_QUERY = gql`
    query customerAssetsPageQuery($customerId: Int!) {
        customer(id: $customerId) {
            ...CustomerTopBar
        }
        assets {
            ...CustomerAssetCreateButton
        }
        customerAssets(customerId: $customerId) {
            ...CustomerAssetsList
        }
    }
    ${CustomerTopBar.FRAGMENT}
    ${CustomerAssetsList.FRAGMENT}
    ${CustomerAssetCreateButton.FRAGMENT}
`

export const updateCustomerAssetsPageData = createQueryUpdater(CUSTOMER_ASSETS_PAGE_QUERY)

const useCustomerAssetsPageQuery = (options) => {
    const {
        error,
        loading,
        data = {},
        ...result
    } = useQuery(CUSTOMER_ASSETS_PAGE_QUERY, options)

    const {
        assets = [],
        customer = {},
        customerAssets = [],
    } = data

    const refetchQueries = [{
        query: CUSTOMER_ASSETS_PAGE_QUERY,
        ...options,
    }]

    return ({
        error,
        isFetching: loading,
        assets,
        customer,
        customerAssets,
        refetchQueries,
        ...result
    })
}

export default useCustomerAssetsPageQuery
