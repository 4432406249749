import React from 'react'
import { useParams } from 'react-router-dom'
import { notification } from 'antd'
import * as routes from '../../../routes'
import CustomerPageError from '../../layout/CustomerPageError'
import UpdateCustomerAssetPage from './UpdateCustomerAssetPage'
import useUpdateCustomerAssetMutation from './useUpdateCustomerAssetMutation'
import useUpdateCustomerAssetFormState from './useUpdateCustomerAssetFormState'
import useUpdateCustomerAssetPageQuery from './useUpdateCustomerAssetPageQuery'

const activeMenuItem = 'assets'
const UpdateCustomerAssetPageWithState = () => {
    const params = useParams()
    const customerId = parseInt(params.customerId, 10)
    const customerAssetId = parseInt(params.customerAssetId, 10)
    const [updateCustomerAsset, { loading: isSavingAsset }] = useUpdateCustomerAssetMutation()
    const {
        error,
        isFetching,
        customerAsset,
    } = useUpdateCustomerAssetPageQuery({
        variables: {
            customerAssetId,
        },
    })
    const formState = useUpdateCustomerAssetFormState(customerAsset)

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem={activeMenuItem}
            />
        )
    }

    const handleUpdateAsset = async () => {
        const isValid = formState.validate()
        if (isValid) {
            try {
                const input = formState.valuesToInput()
                const { data } = await updateCustomerAsset({
                    variables: {
                        id: customerAssetId,
                        input,
                    }
                })
                notification.success({
                    message: 'Asset Updated!',
                    description: `Asset ${data.updateCustomerAsset.customerAsset.asset.name} successfully updated`,
                })
            } catch (e) {
                notification.error({
                    message: 'Customer not updated!',
                    description: `Something went wrong. The following error occured: ${e}`,
                })
            }
        }
    }

    return (
        <UpdateCustomerAssetPage
            activeMenuItem={activeMenuItem}
            customerId={customerId}
            routes={routes}
            formState={formState}
            isFetching={isFetching}
            isSaving={isSavingAsset}
            onSaveAsset={handleUpdateAsset}
        />
    )
}

export default UpdateCustomerAssetPageWithState
