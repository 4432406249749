import React from 'react'
import { ApolloConsumer, gql } from '@apollo/client'
import { connectionToCollection, PAGE_INFO_FRAGMENT } from '../../../util/graphql'
import State from '../State'

export const CUSTOMERS_QUERY = gql`
    query customers($after: String, $first: Int, $search: String) {
        customersPaginated(
            after: $after,
            first: $first,
            search: {
                nameContains: $search,
            }
        ) {
            edges {
                node {
                    id
                    name
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const CustomerSearch = ({
    after,
    first,
    children,
}) => (
    <State initialState={{ customers: [], isLoading: false }}>
        {({ state, setState }) => (
            <ApolloConsumer>
                {(client) => children({
                    handleSearch: async (search) => {
                        if (search.length >= 3) {
                            setState({ isLoading: true })
                            const { data } = await client.query({
                                query: CUSTOMERS_QUERY,
                                variables: { after, first, search },
                            })
                            setState({
                                customers: connectionToCollection(
                                    data.customersPaginated
                                ),
                                isLoading: false
                            })
                        }
                    },
                    clearSearch: () => {
                        setState({ customers: [] })
                    },
                    customers: state.customers,
                    loading: state.isLoading,
                })}
            </ApolloConsumer>
        )}
    </State>
)
export default CustomerSearch
