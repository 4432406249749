import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { getCurrencySymbol } from '../../../../util/currency'
import { whereId } from '../../../util/index'

export const ASSET_CARD_QUERY = gql`
    query assetCardQuery(
        $assetCode: String!,
        $productVariationId: Int!,
    ) {
        adjustedMarketData(
            assetCode: $assetCode,
            productVariationId: $productVariationId,
        ) {
            status
            sellPrice
            previousSellPrice
            buyPrice
            previousBuyPrice
        }
    }
`

const useFetchAssetCardData = (
    assetCode,
    firstProductVariation,
    pollInterval,
    productVariations,
) => {
    const [productVariationId, setProductVariationId] = useState(firstProductVariation.id)
    const { currencyCode, unitCode } = productVariations.find(whereId(productVariationId))
    const variables = {
        assetCode,
        productVariationId,
    }
    const {
        error,
        loading,
        data = {},
    } = useQuery(ASSET_CARD_QUERY, {
        variables,
        pollInterval,
    })
    const { adjustedMarketData = {} } = data

    return {
        error,
        isFetching: loading,
        adjustedMarketData,
        currencyCode,
        currencySymbol: getCurrencySymbol(currencyCode),
        unitCode,
        productVariationId,
        onChangeProductVariationId: setProductVariationId,
    }
}

export default useFetchAssetCardData
