import { gql } from '@apollo/client'

const CUSTOMER_FRAGMENT = gql`
    fragment Customer on Customer {
        id
        createdAt
        name
        minTradeLimit
        maxTradeLimit
        tradeLimitUnitCode
        tradeLimit {
            minTradeLimit
            maxTradeLimit
            tradeLimitUnitCode
        }
        dailyTradeLimit
    }
`

export default CUSTOMER_FRAGMENT
