import React from 'react'
import CustomerAssetLogItem from './CustomerAssetLogItem'

const CustomerAssetSuspendedItem = ({
    appEvent
}) => {
    const {
        customer, user, createdAt, payload
    } = appEvent
    const { suspended, name, code } = payload
    return (
        <CustomerAssetLogItem
            isCreated={!suspended}
            isDeleted={suspended}
            createdAt={createdAt}
            user={user}
        >
            <div>
                <strong>{name} ({code})</strong>
                {' '}
                for
                {' '}
                <strong>{customer.name}</strong>
                {' '}
                {suspended ? 'suspended' : 'available'}.
            </div>
        </CustomerAssetLogItem>
    )
}

export default CustomerAssetSuspendedItem
