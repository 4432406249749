import React from 'react'
import logo from '../static/images/logo.svg'
import MainCentered from './layout/MainCentered'

const AppUnavailable = () => (
    <MainCentered>
        <img className="mb-6" src={logo} alt="Logo Gunvor Trading Admin" />
        <h3 className="font-black text-3xl">App Unavailable</h3>
        <p className="text-center">
            Gunvor Trading Admin is currently not available.
            <br />Please try again later.
        </p>
    </MainCentered>
)

export default AppUnavailable
