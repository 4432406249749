import { gql } from '@apollo/client'

export const CURRENCY_FRAGMENT = gql`
    fragment Currency on Currency {
        name
        code
        symbol
    }
`

export const UNIT_FRAGMENT = gql`
    fragment Unit on Unit {
        name
        code
    }
`
