import React from 'react'
import { Menu } from 'antd'
import {
    TeamOutlined,
    DollarOutlined,
    SettingOutlined,
    FileDoneOutlined,
} from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import * as routes from '../../routes'

const { SubMenu, Item } = Menu

const NavbarMenu = ({
    assets,
    activeMenuItem,
}) => {
    const history = useHistory()
    const [firstAsset] = assets
    return (
        <Menu
            mode="horizontal"
            selectedKeys={[activeMenuItem]}
            style={{ borderBottom: 'none' }}
        >
            <SubMenu
                key="orders"
                onTitleClick={() => {
                    history.push(routes.assetOrders(firstAsset.id))
                }}
                title={(
                    <span className="submenu-title-wrapper">
                        <FileDoneOutlined /> Orders
                    </span>
                )}
            >
                {assets.map(({ id, name }) => (
                    <Item key={`orders:${id}`}>
                        <Link to={routes.assetOrders(id)}>
                            {name}
                        </Link>
                    </Item>
                ))}
            </SubMenu>
            <Item key="prices">
                <Link to={routes.prices()}>
                    <DollarOutlined /> Prices
                </Link>
            </Item>
            <Item key="customers">
                <Link to={routes.customers()}>
                    <TeamOutlined /> Customers
                </Link>
            </Item>
            <Item key="settings">
                <Link to={routes.users()}>
                    <SettingOutlined /> Settings
                </Link>
            </Item>
        </Menu>
    )
}

export default NavbarMenu
