import React from 'react'
import { notification } from 'antd'
import SettingsPageError from '../../layout/SettingsPageError'
import OpeningHoursPage from './OpeningHoursPage'
import useOpeningHoursPageQuery from './useOpeningHoursPageQuery'
import useUpdateOpeningHoursMutation from './useUpdateOpeningHoursMutation'
import useUpdateOpeningHoursFormState from './useUpdateOpeningHoursFormState'

const activeMenuItem = 'openingHours'

const OpeningHoursPageWithState = () => {
    const {
        error,
        isLoading,
        openingHours,
    } = useOpeningHoursPageQuery()
    const [updateOpeningHours, { loading: isSubmitting }] = useUpdateOpeningHoursMutation()
    const formState = useUpdateOpeningHoursFormState({ openingHours })

    if (typeof error !== 'undefined') {
        return (
            <SettingsPageError
                activeMenuItem={activeMenuItem}
            />
        )
    }

    const handleUpdateOpeningHours = async () => {
        try {
            if (formState.validate()) {
                const input = formState.valuesToInput()
                await updateOpeningHours({
                    variables: {
                        input
                    }
                })
                notification.success({
                    message: 'Success',
                    description: 'You successfully updated the opening hours!',
                })
            }
        } catch (e) {
            notification.error({
                message: 'Something went wrong',
                description: `The following error occurred: ${e}`,
            })
        }
    }

    return (
        <OpeningHoursPage
            activeMenuItem={activeMenuItem}
            formState={formState}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            onSubmit={handleUpdateOpeningHours}
        />
    )
}

export default OpeningHoursPageWithState
