import React from 'react'
import { Link } from 'react-router-dom'
import {
    Affix,
    Button,
    PageHeader,
} from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import CustomerPage from '../../layout/CustomerPage'
import CalculationInfoAlert from '../update-asset/PriceAdjustmentInfoAlert'
import CustomerAssetForm from './UpdateCustomerAssetForm'

const UpdateCustomerAssetPage = ({
    customerId,
    routes,
    isSaving,
    isLoading,
    formState,
    onSaveAsset,
}) => (
    <CustomerPage
        customerId={customerId}
        isLoading={isLoading}
        activeMenuItem="assets"
    >
        <Affix>
            <PageHeader style={{ backgroundColor: 'white' }}>
                <div className="flex justify-between">
                    <Link to={routes.customerAssetSettings(customerId)}>
                        <LeftOutlined className="mr-4" />Back to assets
                    </Link>
                    <Button
                        type="primary"
                        onClick={onSaveAsset}
                        loading={isSaving}
                    >
                        Save asset
                    </Button>
                </div>
            </PageHeader>
        </Affix>
        <CustomerAssetForm
            formState={formState}
        />
        <CalculationInfoAlert />
    </CustomerPage>
)

export default UpdateCustomerAssetPage
