import React from 'react'
import EnvironmentBar from '@wappla/show-env-react'
import Logo from '../util/Logo'
import NavbarUserDropdownMenu from './NavbarUserDropdownMenu'
import NavbarMenu from './NavbarMenu'
import useAppState from '../hooks/useAppState'

const Navbar = ({
    activeMenuItem,
}) => {
    const { assets } = useAppState()
    return (
        <header className="border-b-1 mb-8">
            {process.env.REACT_APP_ENV !== 'production' && (
                <EnvironmentBar env={process.env.REACT_APP_ENV} />
            )}
            <nav className="flex items-center justify-between relative max-w-container my-0 mx-auto px-6">
                <div className="">
                    <Logo height={32} />
                </div>
                <div className="mx-6">
                    <NavbarMenu
                        assets={assets}
                        activeMenuItem={activeMenuItem}
                    />
                </div>
                <div className="justify-end">
                    <NavbarUserDropdownMenu />
                </div>
            </nav>
        </header>
    )
}

export default Navbar
