import { useQuery, gql } from '@apollo/client'
import UpdateCustomerAssetForm from './UpdateCustomerAssetForm'
import CustomerPriceAdjustmentsTable from './CustomerPriceAdjustmentsTable'

export const query = gql`
    query updateCustomerAssetPageQuery($customerAssetId: Int!) {
        customerAsset(customerAssetId: $customerAssetId) {
            ...UpdateCustomerAssetForm
            productVariations {
                ...CustomerPriceAdjustmentsTable
            }
        }
    }
    ${UpdateCustomerAssetForm.FRAGMENT}
    ${CustomerPriceAdjustmentsTable.FRAGMENT}
`

const useUpdateCustomerAssetPageQuery = (options) => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(query, options)

    const { customerAsset = {} } = data

    return ({
        error,
        isFetching: loading,
        customerAsset,
    })
}

export default useUpdateCustomerAssetPageQuery
