import React from 'react'
import roundTo from 'round-to'
import { notification } from 'antd'
import SettingsPageError from '../../layout/SettingsPageError'
import MarketDataPage from './MarketDataPage'
import useFetchMarketDataPageQuery from './useMarketDataPageQuery'
import useUpdateMarketDataMutation from './useUpdateMarketDataMutation'
import { MARKET_DATA_ACCURACY_RATE } from '../../../util/constants'

const activeMenuItem = 'marketData'

const MarketDataPageWithState = () => {
    const {
        error,
        loading,
        allMarketData,
    } = useFetchMarketDataPageQuery()
    const [updateMarketData] = useUpdateMarketDataMutation()
    if (typeof error !== 'undefined') {
        return (
            <SettingsPageError
                activeMenuItem={activeMenuItem}
            />
        )
    }

    const handleUpdateMarketData = async (record) => {
        try {
            const variables = {
                id: record.id,
                input: {
                    buyPrice: roundTo(record.buyPrice * MARKET_DATA_ACCURACY_RATE, 0),
                }
            }
            await updateMarketData({ variables })
            notification.success({
                message: 'Market data updated!',
                description: `${record.assetCurrencyCode} was successfully updated!`,
            })
        } catch (e) {
            notification.error({
                message: 'Market data not updated!',
                description: `Something went wrong. The following error occured: ${e}`,
            })
        }
    }

    return (
        <MarketDataPage
            isLoading={loading}
            activeMenuItem={activeMenuItem}
            allMarketData={allMarketData}
            onUpdateRecord={handleUpdateMarketData}
        />
    )
}

export default MarketDataPageWithState
