import React from 'react'
import { Select } from 'antd'

const { Option } = Select

const CustomerSelect = ({
    value = null,
    customers,
    ...props
}) => (
    <Select
        value={value}
        defaultValue={null}
        {...props}
    >
        <Option key="allCustomers" value={null}>All Customers</Option>
        {customers.map((customer) => (
            <Option key={customer.id} value={customer.id}>{customer.name}</Option>
        ))}
    </Select>
)

export default CustomerSelect
