import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { notification, Modal } from 'antd'
import createCustomer from '../../../api/createCustomer'
import * as routes from '../../../routes'
import CustomerForm from './CustomerForm'
import { CUSTOMERS_PAGE_QUERY } from './useFetchCustomersPageData'
import useCreateCustomerForm from './useCreateCustomerForm'

const CreateCustomerModalWithState = ({
    history,
    isVisible,
    onCreateCustomerComplete,
    closeModal,
}) => {
    const [isCreatingCustomer, setIsCreatingCustomer] = useState(false)
    const apolloClient = useApolloClient()
    const formstate = useCreateCustomerForm()

    const handleSubmit = async () => {
        const isValid = formstate.validate()
        if (isValid) {
            setIsCreatingCustomer(true)
            try {
                const { data } = await createCustomer(
                    apolloClient,
                    formstate.values,
                    [{ query: CUSTOMERS_PAGE_QUERY, variables: { orderBy: { name: 'ASC' } } }],
                )
                notification.success({
                    message: 'Customer Created!',
                    description: `${data.createCustomer.customer.name} was successfully created!`,
                })
                history.push(routes.customerSettings(data.createCustomer.customer.id))
            } catch (e) {
                notification.error({
                    message: 'Customer not created!',
                    description: `Something went wrong. The following error occured: ${e}`,
                })
            }
            setIsCreatingCustomer(false)
            onCreateCustomerComplete()
        }
    }

    return (
        <Modal
            title="Create Customer"
            visible={isVisible}
            onOk={handleSubmit}
            closable={!isCreatingCustomer}
            maskClosable={!isCreatingCustomer}
            confirmLoading={isCreatingCustomer}
            onCancel={closeModal}
        >
            <CustomerForm
                formstate={formstate}
                onFinish={handleSubmit}
            />
        </Modal>
    )
}

export default CreateCustomerModalWithState
