import React from 'react'
import OrdersSearchInput from './OrdersSearchInput'
import CustomerOrdersTableFilter from './CustomerOrdersTableFilter'

const CustomerOrdersTableHeader = ({
    filters,
    onSearchOrder,
    onResetOrderSearch,
    onResetTableFilter,
}) => {
    const {
        orderId,
    } = filters

    const appliedFilters = []
    if (orderId !== null) {
        appliedFilters.push(`Ref. ${orderId}`)
    }

    return (
        <div className="flex justify-between">
            <div className="flex mt-2 h-8 mb-4">
                <CustomerOrdersTableFilter
                    filters={filters}
                    onResetTableFilter={onResetTableFilter}
                />
            </div>
            <div className="h-8 mb-4">
                <OrdersSearchInput
                    onSearchOrder={onSearchOrder}
                    searchOrderRef={orderId}
                    onResetOrderSearch={onResetOrderSearch}
                />
            </div>
        </div>
    )
}

export default CustomerOrdersTableHeader
