import React from 'react'
import SettingsPage from '../SettingsPage'
import MarketDataTable from './MarketDataTable'

const MarketDataPage = ({
    activeMenuItem,
    allMarketData,
    isLoading,
    onUpdateRecord,
}) => (
    <SettingsPage
        activeMenuItem={activeMenuItem}
        isLoading={isLoading}
    >
        <h1 className="font-black text-3xl mb-3">
            Market data
        </h1>
        <MarketDataTable
            data={allMarketData}
            onUpdateRecord={onUpdateRecord}
        />
    </SettingsPage>
)

export default MarketDataPage
