import { gql, useMutation } from '@apollo/client'

const mutation = gql`
    mutation deleteCustomerAsset($id: Int!) {
        deleteCustomerAsset(id: $id)
    }
`

const useDeleteCustomerAssetMutation = (options) => (
    useMutation(mutation, options)
)

export default useDeleteCustomerAssetMutation
