import React from 'react'
import createStore from 'unistore'
import { ApolloProvider, ApolloProvider as ApolloHooksProvider } from '@apollo/client'
import { Provider as UnistoreProvider } from 'unistore/react'

import AppErrorBoundary from './AppErrorBoundary'

const unistore = createStore({
    openModals: {},
    openDrawers: {},
})

const AppProviders = ({
    apolloClient,
    children,
}) => (
    <UnistoreProvider store={unistore}>
        <ApolloProvider client={apolloClient}>
            <ApolloHooksProvider client={apolloClient}>
                <AppErrorBoundary>
                    {children}
                </AppErrorBoundary>
            </ApolloHooksProvider>
        </ApolloProvider>
    </UnistoreProvider>
)

export default AppProviders
