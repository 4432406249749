import React from 'react'
import { END_PRICE_ACCURACY } from '@gunvor-trading/shared/utils/constants'
import UnitValue from '../../util/UnitValue'
import CurrencyValue from '../../util/CurrencyValue'

const OrderSummary = ({
    side,
    assetName,
    customerName,
    unitCode,
    currencyCode,
    spotPrice,
    quantity,
}) => {
    const totalPrice = spotPrice * quantity
    return (
        <div className="calculationInfo">
            <h3 className="text-xl">Order summary</h3>
            <ul>
                <li>
                    Side:
                    {' '}
                    <strong>{side}</strong>
                </li>
                <li>
                    Metal:
                    {' '}
                    <strong>{assetName}</strong>
                </li>
                {typeof customerName !== 'undefined' && (
                    <li>
                        Customer:
                        {' '}
                        <strong>{customerName}</strong>
                    </li>
                )}
                <li>
                    Quantity:
                    {' '}
                    <UnitValue
                        isBold
                        formatValue={false}
                        value={quantity}
                        unitCode={unitCode}
                    />
                </li>
                <li>
                    Spot Price:
                    {' '}
                    <CurrencyValue
                        isBold
                        formatValue={false}
                        value={spotPrice}
                        currencyCode={currencyCode}
                    />
                </li>
                <li>
                    Total Price:
                    {' '}
                    <CurrencyValue
                        isBold
                        formatValue={false}
                        value={Math.round(totalPrice * END_PRICE_ACCURACY) / END_PRICE_ACCURACY}
                        currencyCode={currencyCode}
                    />
                </li>
            </ul>
        </div>
    )
}

export default OrderSummary
