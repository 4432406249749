import { useQuery, gql } from '@apollo/client'
import AssetForm from './AssetForm'
import PriceAdjustmentsTable from './PriceAdjustmentsTable'

const query = gql`
    query updateAssetPageQuery($id: Int!) {
        asset(id: $id) {
            ...AssetForm
            productVariations {
                ...PriceAdjustmentsTable
            }
        }
    }
    ${AssetForm.FRAGMENT}
    ${PriceAdjustmentsTable.FRAGMENT}
`

const useUpdateAssetPageQuery = (options) => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(query, options)

    const { asset = {} } = data

    return ({
        error,
        isFetching: loading,
        asset,
    })
}

export default useUpdateAssetPageQuery
