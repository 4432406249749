import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { countries } from 'countries-list'

export const getCountrySelectOptions = () => Object.keys(countries).map((countryCode) => {
    const country = countries[countryCode]
    return ({
        value: countryCode,
        label: `${countryCode} (${country.name} +${country.phone})`
    })
})

export const getCountries = () => (
    Object.keys(countries).map((code) => ({
        code,
        ...countries[code]
    }))
)

export const parsePhoneNumber = (value, countryCode) => {
    let formattedValue = value
    const phoneNumber = parsePhoneNumberFromString(value, countryCode)
    if (typeof phoneNumber !== 'undefined') {
        formattedValue = phoneNumber.number
    }
    return formattedValue
}

export const getCountryPhoneCode = (countryCode) => (
    `+${countries[countryCode.toUpperCase()].phone}`
)

export const getCountryCodeFromString = (value) => {
    const phoneNumber = parsePhoneNumberFromString(value)
    if (typeof phoneNumber === 'undefined') {
        return null
    }
    const countryCode = phoneNumber.country
    return countryCode
}
