import React from 'react'
import {
    Row,
    Col,
    Form,
    Button,
} from 'antd'
import { calculateStep } from '@gunvor-trading/shared/utils/units'
import { getRoundingForUnit } from '../../../util/units'
import FormItem from '../../util/form/FormItem'
import InputNumber from '../../util/form/InputNumber'
import AssetSelect from '../../util/form/AssetSelect'
import CustomerSearchSelect from '../../util/form/CustomerSearchSelect'
import CustomerMarketDataContainer from './order-modal/CustomerMarketDataContainer'
import ProductVariationSelect from '../../util/form/ProductVariationSelect'
import { roundCurrency } from '../../../util/currency'

const CreateOrderForm = ({
    formState,
    asset,
    customerAssets = [],
    onSearchCustomers,
    onSubmit,
    isFetching,
    productVariation,
    onChangeCustomer,
    onChangeProductVariation,
}) => {
    const {
        values,
        antdErrors,
        handleChange,
        getInputProps,
    } = formState
    const { currencyCode, unitCode } = productVariation || {}
    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={8}>
                    <FormItem
                        isRequired
                        label="Customer"
                        error={antdErrors.customerId}
                    >
                        <CustomerSearchSelect
                            disabled={isFetching}
                            value={values.customerId}
                            customer={values.customer}
                            onSearch={onSearchCustomers}
                            onChange={onChangeCustomer}
                            className="w-full"
                        />
                    </FormItem>
                </Col>
                <Col span={8}>
                    <AssetSelect
                        isRequired
                        disabled
                        label="Asset"
                        {...getInputProps('assetId')}
                        error={antdErrors.get('assetId')}
                        assets={customerAssets}
                        className="w-full"
                    />
                </Col>
                <Col span={8}>
                    <ProductVariationSelect
                        isRequired
                        label="Product variation"
                        {...getInputProps('productVariationId')}
                        error={antdErrors.get('productVariationId')}
                        productVariations={asset.productVariations}
                        onChange={onChangeProductVariation}
                        className="w-full"
                    />
                </Col>
            </Row>
            <CustomerMarketDataContainer
                customerId={values.customerId}
                side={values.side}
                productVariationId={values.productVariationId}
                assetCode={asset.code}
                unitCode={unitCode}
                currencyCode={currencyCode}
                onCompleteMarketData={({ spotPrice, currencyConversion }) => {
                    handleChange('spotPrice', Math.round(spotPrice / 10) / 10)
                    handleChange('currencyConversion', currencyConversion)
                }}
            />
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Quantity"
                        error={antdErrors.get('quantity')}
                    >
                        <InputNumber
                            min={0}
                            step={calculateStep(false, unitCode)}
                            precision={getRoundingForUnit(unitCode)}
                            disabled={isFetching}
                            {...getInputProps('quantity')}
                            addonAfter={unitCode}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Price"
                        error={antdErrors.get('spotPrice')}
                    >
                        <InputNumber
                            {...getInputProps('spotPrice')}
                            value={roundCurrency(values.spotPrice, 2)}
                            min={0}
                            precision={2}
                            disabled={isFetching || typeof values.currencyCode === 'undefined' || typeof values.unitCode === 'undefined'}
                            addonAfter={currencyCode}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Button
                type="primary"
                className="w-full"
                onClick={onSubmit}
                disabled={(
                    isFetching
                    || typeof values.quantity === 'undefined'
                    || values.quantity <= 0
                    || typeof values.spotPrice === 'undefined'
                    || values.spotPrice <= 0
                    || productVariation === null
                )}
            >
                Create order
            </Button>
        </Form>
    )
}

export default CreateOrderForm
