import { gql } from '@apollo/client'
import {
    UNIT_FRAGMENT,
    CURRENCY_FRAGMENT,
} from '../../components/fragments/ConfigFragments'
import ME_FRAGMENT from '../../components/fragments/MeFragment'

export const INITIAL_APP_DATA_QUERY = gql`
    query initAppDataQuery {
        me {
            ...Me
        }
        currencies {
            ...Currency
        }
        units {
            ...Unit
        }
        assets {
            id
            name
            code
            suspended
        }
    }
    ${ME_FRAGMENT}
    ${CURRENCY_FRAGMENT}
    ${UNIT_FRAGMENT}
`

export default async function getInitialData(apolloClient) {
    const query = INITIAL_APP_DATA_QUERY
    const { data } = await apolloClient.query({
        query,
    })
    return data
}
