import roundTo from 'round-to'
import { PRICE_ADJUSTMENT_ACCURACY, END_PRICE_ACCURACY } from './constants'

// TODO: accept unitCode and convert value
export const formatCurrency = (value) => parseFloat(value / 100).toFixed(2)

export const addZeroes = (num, rounding) => (
    Number(num).toFixed(Math.max(num.split('.')[1]?.length, rounding) || rounding)
)

export const roundCurrency = (value, rounding = 2) => addZeroes(`${roundTo(value, rounding)}`, rounding)

export const getCurrencySymbol = (currecyCode) => {
    if (currecyCode === 'EUR') {
        return '€'
    }
    if (currecyCode === 'USD') {
        return '$'
    }
    return '?'
}

export const calculateSellPrice = (basePrice, adjustment) => {
    const {
        discount = 0, step, rounding, priceAdjustment,
    } = adjustment
    const finalStep = step !== null ? step : priceAdjustment.step
    const finalRounding = rounding !== null ? rounding : priceAdjustment.rounding
    return roundTo.down(
        (basePrice * PRICE_ADJUSTMENT_ACCURACY - finalStep - discount)
        / PRICE_ADJUSTMENT_ACCURACY, finalRounding
    )
}

export const calculateBuyPrice = (basePrice, adjustment) => {
    const {
        premium = 0, step, rounding, priceAdjustment,
    } = adjustment
    const finalStep = step !== null ? step : priceAdjustment.step
    const finalRounding = rounding !== null ? rounding : priceAdjustment.rounding
    return roundTo.up(
        (basePrice * PRICE_ADJUSTMENT_ACCURACY + finalStep + premium)
        / PRICE_ADJUSTMENT_ACCURACY, finalRounding
    )
}

export const formatCurrencyNotation = (amount, currencyCode) => {
    const roundedAmount = Math.round(amount / END_PRICE_ACCURACY) * END_PRICE_ACCURACY
    const valueStringArray = roundedAmount.toString().split('').reverse()
    const primarySeparator = currencyCode === 'EUR' ? ',' : '.'
    const secondarySeparator = currencyCode === 'EUR' ? '.' : ','
    return valueStringArray.reduce((previousValueString, currentChar, index) => {
        if (index === 1) {
            if (valueStringArray.length <= 2) {
                return `0${primarySeparator}${currentChar}${previousValueString}`
            }
            return primarySeparator + currentChar + previousValueString
        }
        if (index > 2 && (index - 2) % 3 === 0) {
            return currentChar + secondarySeparator + previousValueString
        }
        return currentChar + previousValueString
    }, '')
}

export const getDecimalSeparator = (formattingStyle) => {
    let decimalSeparator = ','
    if (formattingStyle === 'american') {
        decimalSeparator = '.'
    }
    return decimalSeparator
}

export const calculatePriceAdjustmentValuesForForm = (priceAdjustment) => ({
    discount: priceAdjustment.discount === null
        ? null : priceAdjustment.discount / PRICE_ADJUSTMENT_ACCURACY,
    premium: priceAdjustment.premium === null
        ? null : priceAdjustment.premium / PRICE_ADJUSTMENT_ACCURACY,
    step: priceAdjustment.step === null
        ? null : priceAdjustment.step / PRICE_ADJUSTMENT_ACCURACY,
})

export const calculatePriceAdjustmentValuesForDb = (priceAdjustment) => ({
    discount: priceAdjustment.discount === null
        ? null : roundTo(priceAdjustment.discount * PRICE_ADJUSTMENT_ACCURACY, 0),
    premium: priceAdjustment.premium === null
        ? null : roundTo(priceAdjustment.premium * PRICE_ADJUSTMENT_ACCURACY, 0),
    step: priceAdjustment.step === null
        ? null : roundTo(priceAdjustment.step * PRICE_ADJUSTMENT_ACCURACY, 0),
})

export const getCorrectedCurrencyCodeForDb = (currencyCode) => currencyCode.substr(0, currencyCode.indexOf(' '))
