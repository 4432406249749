import useFormState, { createFormValidation } from '../../hooks/useFormState'
import { formatTimeString, formatToDbTime } from '../../../util/dates'
import { formatTimeToFormValue } from '../opening-hours/useUpdateOpeningHoursFormState'

const toFormValues = ({
    customerOpeningHours,
}) => ({
    customerOpeningHours: customerOpeningHours
        .map(({
            id,
            isOpen,
            openingTime,
            closingTime,
            openingHour,
        }) => ({
            id,
            isOpen,
            day: openingHour.day,
            openingTime: formatTimeToFormValue(openingTime),
            closingTime: formatTimeToFormValue(closingTime),
            openingHour: {
                ...openingHour,
                openingTime: formatTimeToFormValue(openingHour.openingTime),
                closingTime: formatTimeToFormValue(openingHour.closingTime),
            },
        }))
        .sort((a, b) => a.day - b.day)
})

const validation = createFormValidation([{
    path: 'customerOpeningHours.*.openingTime',
    validate: (openingTime, { isOpen, closingTime }) => (
        !isOpen || formatTimeString(closingTime) > formatTimeString(openingTime)
    ),
    message: 'Opening time must be before closing time.',
}])

const valuesToInput = ({
    customerOpeningHours,
}) => ({
    customerOpeningHours: customerOpeningHours.map(({
        id,
        isOpen,
        openingTime,
        closingTime,
    }) => ({
        id,
        isOpen,
        openingTime: openingTime !== null ? formatToDbTime(openingTime) : null,
        closingTime: closingTime !== null ? formatToDbTime(closingTime) : null,
    }))
})

const useUpdateCustomerOpeningHoursFormState = (initialValues, options) => {
    const formState = useFormState(
        toFormValues(initialValues),
        {
            validation,
            valuesToInput,
            ...options,
        }
    )

    const handleChangeIsOpen = (path, checked) => {
        const value = formState.getValue(path)
        formState.handleChange(path, {
            ...value,
            isOpen: checked,
            openingTime: null,
            closingTime: null,
        })
    }

    const handleAddCustomerOpeningHour = (path) => {
        const value = formState.getValue(path)
        formState.handleChange(path, {
            ...value,
            isOpen: value.openingHour.isOpen,
            openingTime: value.openingHour.openingTime,
            closingTime: value.openingHour.closingTime,
        })
    }

    const handleRemoveCustomerOpeningHour = (path) => {
        const value = formState.getValue(path)
        formState.handleChange(path, {
            ...value,
            isOpen: null,
            openingTime: null,
            closingTime: null,
        })
    }

    return {
        ...formState,
        handleChangeIsOpen,
        handleAddCustomerOpeningHour,
        handleRemoveCustomerOpeningHour,
    }
}

export default useUpdateCustomerOpeningHoursFormState
