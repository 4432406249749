import { useQuery, gql } from '@apollo/client'
import CustomerTopBar from '../../layout/CustomerTopBar'

export const CUSTOMER_PRICES_PAGE_QUERY = gql`
    query pricesPageQuery($customerId: Int!) {
        customer(id: $customerId) {
            ...CustomerTopBar
        }
        customerAssets(customerId: $customerId) {
            id
            available
            asset {
                id
                name
                code
            }
            suspended
            productVariations {
                id
                name
                currencyCode
                unitCode
            }
        }
    }
    ${CustomerTopBar.FRAGMENT}
`

const useFetchCustomerPricesPageData = (customerId) => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(CUSTOMER_PRICES_PAGE_QUERY, {
        variables: {
            customerId,
        },
    })
    const { customerAssets = [], customer = {} } = data

    const transformedCustomerAssets = customerAssets.map((customerAsset) => {
        const {
            id,
            suspended,
            available,
            asset,
            productVariations,
        } = customerAsset
        const {
            name,
            code,
        } = asset

        return {
            id,
            name,
            code,
            suspended,
            available,
            productVariations,
        }
    })

    return {
        error,
        isFetching: loading,
        customerAssets: transformedCustomerAssets,
        customer,
    }
}

export default useFetchCustomerPricesPageData
