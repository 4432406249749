import * as cache from '../../util/cache'

const getExportedOrders = async (from, till, customerId, assetId) => {
    const {
        REACT_APP_SERVER_URI: URI,
    } = process.env
    const url = `${URI}/exportOrders?from=${from}&till=${till}&customerId=${customerId}&assetId=${assetId}`
    const accessToken = await cache.getAccessToken()

    const response = await fetch(url, {
        method: 'GET',
        responseType: 'blob',
        headers: {
            authorization: accessToken,
        },
    })
    const file = new Blob([await response.blob()], { type: 'text/csv' })
    const fileUrl = URL.createObjectURL(file)
    window.open(fileUrl)
}

export default getExportedOrders
