import { gql, useQuery } from '@apollo/client'
import CustomerTopBar from '../../layout/CustomerTopBar'
import UpdateCustomerOpeningHoursItem from './UpdateCustomerOpeningHoursItem'

const query = gql`
    query customerOpeningsHoursPageQuery($customerId: Int!) {
        customerOpeningHours(customerId: $customerId) {
            ...UpdateCustomerOpeningHoursItem
        }
        customer(id: $customerId) {
            ...CustomerTopBar
        }
    }
    ${CustomerTopBar.FRAGMENT}
    ${UpdateCustomerOpeningHoursItem.FRAGMENT}
`

const useCustomerOpeningHoursPageQuery = (options) => {
    const {
        data = {},
        error,
        loading: isLoading,
        ...result
    } = useQuery(query, options)

    let customer = {}
    let customerOpeningHours = []
    if (typeof error !== 'undefined') {
        return {
            error,
            isLoading,
            customer,
            customerOpeningHours
        }
    }

    if (isLoading) {
        return {
            isLoading,
            customer,
            customerOpeningHours,
        }
    }

    ({ customer, customerOpeningHours } = data)

    return {
        isLoading,
        customer,
        customerOpeningHours,
        ...result
    }
}

export default useCustomerOpeningHoursPageQuery
