import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import {
    Popconfirm, Button, notification, Tooltip
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

const UsersTableDeleteButtonWithState = ({
    userId,
    deleteUserMutation,
    onDeleteComplete = () => {},
    customerId
}) => {
    const [isDeletingUser, setIsDeletingUser] = useState(false)
    const apolloClient = useApolloClient()
    const onDeleteUser = async () => {
        try {
            setIsDeletingUser(true)
            await deleteUserMutation(apolloClient, userId, customerId)
            notification.success({
                message: 'User deleted!',
                description: 'The user was successfully deleted',
            })
            setIsDeletingUser(false)
            onDeleteComplete()
        } catch (e) {
            notification.error({
                message: 'User not deleted!',
                description: `Something went wrong. The following error occured: ${e}`,
            })
            setIsDeletingUser(false)
        }
    }

    return (
        <Tooltip title="Delete user">
            <Popconfirm
                title="Are you sure you want to delete this user?"
                onConfirm={onDeleteUser}
                okText="Yes"
                cancelText="No"
            >
                <Button
                    ghost
                    loading={isDeletingUser}
                    icon={<DeleteOutlined />}
                    type="danger"
                    shape="circle"
                    size="small"
                    className="border-none"
                />
            </Popconfirm>
        </Tooltip>

    )
}

export default UsersTableDeleteButtonWithState
