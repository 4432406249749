import React from 'react'
import CustomerPage from '../../layout/CustomerPage'
import CustomerTopBar from '../../layout/CustomerTopBar'
import UpdateCustomerOpeningHoursForm from './UpdateCustomerOpeningHoursForm'

const CustomerOpeningHoursPage = ({
    activeMenuItem,
    customer,
    isLoading,
    isUpdating,
    formState,
    onUpdateCustomerOpeningHours,
}) => (
    <CustomerPage
        customerId={customer.id}
        isLoading={isLoading}
        activeMenuItem={activeMenuItem}
    >
        <CustomerTopBar
            customerName={customer.name}
        />
        <UpdateCustomerOpeningHoursForm
            isUpdating={isUpdating}
            formState={formState}
            onUpdate={onUpdateCustomerOpeningHours}
        />
    </CustomerPage>
)

export default CustomerOpeningHoursPage
