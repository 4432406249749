import React from 'react'
import { Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import ConfirmInputModal from '../../util/ConfirmInputModal'
import createModalState from '../../hoc/createModalState'

const CreateConfirmModalState = createModalState('confirmModal')

const DeleteCustomerButton = ({
    customer,
    onCustomerDeleteConfirm,
    isDeletingCustomer,
}) => (
    <CreateConfirmModalState>
        {({ isOpen, open, close }) => (
            <>
                <ConfirmInputModal
                    title={`Are you sure you want to delete ${customer.name}?`}
                    confirmText={`Delete ${customer.name}`}
                    cancelText="Cancel"
                    safeWord={customer.name}
                    onConfirm={() => {
                        onCustomerDeleteConfirm()
                        close()
                    }}
                    isOpen={isOpen}
                    close={close}
                >
                    <p>
                        Removing a customer cannot be undone.
                        <br />
                        Please type the name of the customer to confirm.
                    </p>
                </ConfirmInputModal>

                <Button
                    ghost
                    loading={isDeletingCustomer}
                    icon={<DeleteOutlined />}
                    type="danger"
                    onClick={open}
                >
                    Delete customer
                </Button>
            </>
        )}
    </CreateConfirmModalState>
)

export default withRouter(DeleteCustomerButton)
