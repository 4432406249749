import React, { useState } from 'react'
import CustomerOrdersTable from './CustomerOrdersTable'
import CustomerOrdersTableHeader from './CustomerOrdersTableHeader'

const transformFiltersToWhere = (filters) => {
    const {
        orderId,
    } = filters

    if (orderId === null || orderId === '') {
        return {}
    }
    return { id: parseInt(orderId, 10) }
}

const INITIAL_FILTERS = {
    orderId: null,
}

const CustomerOrdersTableWithState = ({
    customerId,
    refetch,
    customerOrders,
    isFetchingMore,
    hasMore,
    onFetchMore,
    onEditOrder,
    onExportOrders,
}) => {
    const [isRefetching, setIsRefetching] = useState(false)
    const [filters, setFilters] = useState(INITIAL_FILTERS)

    const handleFilterOrdersTable = async (where) => {
        setIsRefetching(true)
        await refetch({
            customerId,
            where,
        })
        setIsRefetching(false)
    }

    const resetTableFilters = async () => {
        setFilters(INITIAL_FILTERS)
        await handleFilterOrdersTable(transformFiltersToWhere(INITIAL_FILTERS))
    }

    return (
        <>
            <CustomerOrdersTableHeader
                filters={filters}
                onSearchOrder={async (orderId) => {
                    const newFilters = {
                        ...INITIAL_FILTERS,
                        orderId,
                    }
                    setFilters(newFilters)
                    await handleFilterOrdersTable(transformFiltersToWhere(newFilters))
                }}
                onResetOrderSearch={resetTableFilters}
                onResetTableFilter={resetTableFilters}
            />
            <CustomerOrdersTable
                loading={isRefetching}
                filters={filters}
                isFetchingMore={isFetchingMore}
                hasMore={hasMore}
                customerOrders={customerOrders}
                onFetchMore={onFetchMore}
                onEditOrder={onEditOrder}
                onExportOrders={onExportOrders}
            />
        </>
    )
}

export default CustomerOrdersTableWithState
