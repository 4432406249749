import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import usePermissions from '../../hooks/usePermisssions'

const AssetEditButton = ({
    asset,
    onClick,
}) => {
    const { isAdmin } = usePermissions()
    return (
        <Tooltip title="Edit asset">
            <EditOutlined
                onClick={() => isAdmin && onClick(asset)}
                style={{ cursor: !isAdmin && 'not-allowed' }}
            />
        </Tooltip>
    )
}

export default AssetEditButton
