import { gql, useMutation } from '@apollo/client'
import OpeningHoursItem from './OpeningHoursItem'

const mutation = gql`
    mutation updateOpeningHours($input: UpdateOpeningHoursInput!) {
        updateOpeningHours(input: $input) {
            openingHours {
                ...OpeningHoursItem
            }
        }
    }
    ${OpeningHoursItem.FRAGMENT}
`

const useUpdateOpeningHoursMutation = (options) => (
    useMutation(mutation, options)
)

export default useUpdateOpeningHoursMutation
