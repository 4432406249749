import roundTo from 'round-to'
import { getRoundingForUnit, unitTo } from '@gunvor-trading/shared/utils/units'
import * as constants from './constants'

// @TODO: remove export once all functions have moved
export * from '@gunvor-trading/shared/utils/units'

const {
    UNIT_CODE_METRIC_TON: TON,
    UNIT_CODE_CUBM: CUBM,
    UNIT_CODE_LITER: L,
    UNIT_CODE_KILOGRAM: KG,
} = constants

export const getPreferredUnits = (code) => {
    let units = []
    switch (code) {
        case 'settings':
            units = [L, CUBM, KG, TON]
            break
        default:
            units = [code]
    }
    return units
}

export const getInputStep = (unitCode) => {
    let inputStep = 0.01
    if (unitCode === 'g') {
        inputStep = 0.00001
    }
    return inputStep
}

export const getPrecision = (unitCode) => {
    let precision = 2
    if (unitCode === 'g') {
        precision = 5
    }
    return precision
}

export const convertAndRoundQuantity = (quantity, currentUnit, newUnit) => {
    const convertedQuantity = unitTo(quantity, currentUnit, newUnit)
    const roundedQuantity = roundTo(convertedQuantity, getRoundingForUnit(newUnit))
    return roundedQuantity
}
