import React from 'react'
import { Select } from 'antd'
import { getProductVariationOption } from '../../../../util/productVariations'

const { Option } = Select

const AssetCardSelect = ({
    productVariationId,
    onChangeProductVariationId,
    productVariations,
}) => (
    <div className="ml-4">
        <Select
            value={productVariationId}
            onChange={onChangeProductVariationId}
        >
            {productVariations.map((adjustment) => (
                <Option
                    key={adjustment.id}
                    value={adjustment.id}
                >
                    {getProductVariationOption(adjustment)}
                </Option>
            ))}
        </Select>
    </div>
)

export default AssetCardSelect
