export const USER_CREATED = 'USER_CREATED'
export const USER_UPDATED = 'USER_UPDATED'
export const USER_DELETED = 'USER_DELETED'

export const ASSET_UPDATED = 'ASSET_UPDATED'
export const ASSET_SUSPENDED = 'ASSET_SUSPENDED'

export const CUSTOMER_CREATED = 'CUSTOMER_CREATED'
export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED'
export const CUSTOMER_DELETED = 'CUSTOMER_DELETED'

export const TRADE_CREATED = 'TRADE_CREATED'
export const MARKET_TRADE_CREATED = 'MARKET_TRADE_CREATED'
export const CUSTOMER_TRADE_CREATED = 'CUSTOMER_TRADE_CREATED'

export const CUSTOMER_ASSET_CREATED = 'CUSTOMER_ASSET_CREATED'
export const CUSTOMER_ASSET_UPDATED = 'CUSTOMER_ASSET_UPDATED'
export const CUSTOMER_ASSET_DELETED = 'CUSTOMER_ASSET_DELETED'
export const CUSTOMER_ASSET_SUSPENDED = 'CUSTOMER_ASSET_SUSPENDED'

export const APP_SETTINGS_UPDATED = 'APP_SETTINGS_UPDATED'
