import { gql } from '@apollo/client'
import { createUnauthorizedApolloClient } from '../createApolloClient'

export const HEALTH_QUERY = gql`
    query health {
        health {
            adminAppIsAvailable
        },
    }
`

export default async function getAppHealth() {
    const apolloClient = createUnauthorizedApolloClient()
    const query = HEALTH_QUERY
    const { data } = await apolloClient.query({
        query,
    })
    return data
}
