export const login = () => '/login'

export const forgotPassword = () => '/forgot-password'

export const choosePassword = () => '/choose-password/:email/:resetToken'

export const asset = (id = ':assetId') => `/assets/${id}`

export const assetOrders = (id = ':assetId') => `/orders/${id}`

export const home = () => '/'

export const customers = () => '/customers'

export const customer = (id = ':customerId') => `/customer/${id}`

export const customerBalance = (id = ':customerId') => `${customer(id)}/balance`

export const customerTrades = (id = ':customerId') => `${customer(id)}/trades`

export const customerOrders = (id = ':customerId') => `${customer(id)}/orders`

export const customerUsers = (id = ':customerId') => `${customer(id)}/users`

export const customerSettings = (id = ':customerId') => `${customer(id)}/settings`

export const customerAssetSettings = (id = ':customerId') => `${customer(id)}/settings/assets`

export const currenex = () => '/currenex'

export const users = () => '/settings/users'

export const assets = () => '/settings/assets'

export const logs = () => '/settings/logs'

export const appSettings = () => '/settings/appSettings'

export const updateAsset = (id = ':assetId') => `/settings/assets/${id}`

export const updateCustomerAsset = (customerId = ':customerId', assetId = ':customerAssetId') => `${customer(customerId)}/settings/assets/${assetId}`

export const prices = () => '/prices'

export const customerPrices = (id = ':customerId') => `${customer(id)}/prices`
