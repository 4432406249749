import React from 'react'
import CustomerPage from '../../layout/CustomerPage'
import CustomerAssetCardWithAdjustment from './CustomerAssetCardWithAdjustment'
import CustomerTopBar from '../../layout/CustomerTopBar'

const CustomerPricesPage = ({
    customer,
    customerAssets,
    isFetching,
}) => (
    <CustomerPage
        customerId={customer.id}
        isLoading={isFetching}
        activeMenuItem="prices"
    >
        <CustomerTopBar
            customerName={customer.name}
        />
        <div className="flex flex-col">
            {customerAssets.map((asset) => (
                <CustomerAssetCardWithAdjustment
                    key={asset.id}
                    asset={asset}
                    customerId={customer.id}
                />
            ))}
        </div>
    </CustomerPage>
)

export default CustomerPricesPage
