import { createFormValidation } from 'use-form-state'
import * as constants from '@gunvor-trading/shared/utils/constants'
import useFormState from '../../hooks/useFormState'

const {
    UNIT_CODE_METRIC_TON: TON,
} = constants

const appSettingsToFormValues = (appSettings) => ({
    minTradeLimit: appSettings.minTradeLimit || 0,
    maxTradeLimit: appSettings.maxTradeLimit || 0,
    tradeLimitUnitCode: appSettings.tradeLimitUnitCode || TON,
})

const validation = createFormValidation([{
    path: 'minTradeLimit',
    validate: (minTradeLimit) => typeof minTradeLimit !== 'undefined' && minTradeLimit > 0,
    message: 'Minimum trade limit must be larger than 0',
}, {
    path: 'maxTradeLimit',
    validate: (maxTradeLimit) => typeof maxTradeLimit !== 'undefined' && maxTradeLimit > 0,
    message: 'Maximum trade limit must be larger than 0',
}])

const useUpdateAppSettingsForm = (values, options) => {
    const formState = useFormState(
        appSettingsToFormValues(values),
        {
            validation,
            ...options,
        },
    )

    return {
        ...formState,
    }
}

export default useUpdateAppSettingsForm
