import { useQuery, gql } from '@apollo/client'
import { useState } from 'react'
import { getCurrencySymbol } from '../../../util/currency'
import { whereId } from '../../util/index'

export const CUSTOMER_ASSET_CARD_QUERY = gql`
    query customerAssetCardQuery(
        $customerId: Int!,
        $assetCode: String!,
        $productVariationId: Int!,
    ) {
        adjustedCustomerMarketData(
            customerId: $customerId,
            assetCode: $assetCode,
            productVariationId: $productVariationId,
        ) {
            status
            sellPrice
            previousSellPrice
            buyPrice
            previousBuyPrice
        }
    }
`

const useFetchCustomerAssetCardData = (
    customerId,
    assetCode,
    firstProduct,
    pollInterval,
    priceAdjustments,
) => {
    const [productVariationId, setProductVariationId] = useState(firstProduct.id)
    const { currencyCode, unitCode } = priceAdjustments.find(whereId(productVariationId))
    const variables = {
        customerId,
        assetCode,
        productVariationId,
    }
    const {
        error,
        loading,
        data = {},
    } = useQuery(CUSTOMER_ASSET_CARD_QUERY, {
        variables,
        pollInterval,
    })
    const { adjustedCustomerMarketData = {} } = data

    return {
        error,
        isFetching: loading,
        adjustedCustomerMarketData,
        currencyCode,
        currencySymbol: getCurrencySymbol(currencyCode),
        unitCode,
        productVariationId,
        onChangeProductVariationId: setProductVariationId,
    }
}

export default useFetchCustomerAssetCardData
