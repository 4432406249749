import React from 'react'
import SettingsPage from '../SettingsPage'
import LogTimeline from './LogTimeline'

const LogsPage = ({
    hasMore,
    loading,
    isFetchingMore,
    activeMenuItem,
    assets,
    appEvents,
    handleFetchMore,
}) => (
    <SettingsPage
        activeMenuItem={activeMenuItem}
        isLoading={loading}
    >
        <h2 className="font-black text-3xl mb-6">
            Logs
        </h2>
        <LogTimeline
            assets={assets}
            appEvents={appEvents}
            isLoading={loading}
            hasMore={hasMore}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
        />
    </SettingsPage>
)

export default LogsPage
