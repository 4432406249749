import React from 'react'
import AssetCard from '../prices/assetcard/AssetCard'
import CustomerAssetCardWithState from './CustomerAssetCardWithState'

const CustomerAssetCardWithAdjustment = ({
    asset,
    customerId,
}) => {
    const { productVariations } = asset
    if (productVariations.length === 0) {
        return <AssetCard asset={asset} />
    }

    return (
        <CustomerAssetCardWithState
            asset={asset}
            customerId={customerId}
        />
    )
}

export default CustomerAssetCardWithAdjustment
