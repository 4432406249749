import React from 'react'
import { gql } from '@apollo/client'
import * as appEventsTypes from '../../../util/appEvents'
import UserCreatedLogItem from './items/user/UserCreatedItem'
import UserUpdatedItem from './items/user/UserUpdatedItem'
import UserDeletedItem from './items/user/UserDeletedItem'
import TimelineItem from './items/timeline/TimelineItem'
import AssetSuspendedItem from './items/asset/AssetSuspendedItem'
import AssetUpdatedItem from './items/asset/AssetUpdatedItem'
import CustomerCreatedItem from './items/customer/CustomerCreatedItem'
import CustomerUpdatedItem from './items/customer/CustomerUpdatedItem'
import CustomerDeletedItem from './items/customer/CustomerDeletedItem'
import OrderCreatedItem from './items/trade/OrderCreatedItem'
import CustomerAssetCreatedItem from './items/customer-asset/CustomerAssetCreatedItem'
import CustomerAssetUpdatedItem from './items/customer-asset/CustomerAssetUpdatedItem'
import CustomerAssetDeletedItem from './items/customer-asset/CustomerAssetDeletedItem'
import CustomerAssetSuspendedItem from './items/customer-asset/CustomerAssetSuspendedItem'
import AppSettingsUpdatedItem from './items/appSettings/AppSettingsUpdatedItem'

const {
    USER_CREATED,
    USER_UPDATED,
    USER_DELETED,
    ASSET_UPDATED,
    ASSET_SUSPENDED,
    CUSTOMER_CREATED,
    CUSTOMER_UPDATED,
    CUSTOMER_DELETED,
    CUSTOMER_TRADE_CREATED,
    CUSTOMER_ASSET_CREATED,
    CUSTOMER_ASSET_UPDATED,
    CUSTOMER_ASSET_DELETED,
    CUSTOMER_ASSET_SUSPENDED,
    APP_SETTINGS_UPDATED,
} = appEventsTypes

export const LOG_APP_EVENT_FRAGMENT = gql`
    fragment LogAppEvent on AppEvent {
        id
        createdAt
        type
        payload
        user {
            id
            fullName
        }
        customer {
            id
            name
        }
    }
`

const LogTimelineItem = ({
    assets,
    appEvent,
}) => {
    const { type } = appEvent
    if (type === USER_CREATED) {
        return <UserCreatedLogItem appEvent={appEvent} />
    }
    if (type === USER_UPDATED) {
        return <UserUpdatedItem appEvent={appEvent} />
    }
    if (type === USER_DELETED) {
        return <UserDeletedItem appEvent={appEvent} />
    }
    if (type === ASSET_SUSPENDED) {
        return <AssetSuspendedItem appEvent={appEvent} />
    }
    if (type === ASSET_UPDATED) {
        return <AssetUpdatedItem appEvent={appEvent} />
    }
    if (type === CUSTOMER_CREATED) {
        return <CustomerCreatedItem appEvent={appEvent} />
    }
    if (type === CUSTOMER_UPDATED) {
        return <CustomerUpdatedItem appEvent={appEvent} />
    }
    if (type === CUSTOMER_DELETED) {
        return <CustomerDeletedItem appEvent={appEvent} />
    }
    if (type === CUSTOMER_TRADE_CREATED) {
        return (
            <OrderCreatedItem
                assets={assets}
                appEvent={appEvent}
            />
        )
    }
    if (type === CUSTOMER_ASSET_CREATED) {
        return <CustomerAssetCreatedItem appEvent={appEvent} />
    }
    if (type === CUSTOMER_ASSET_DELETED) {
        return <CustomerAssetDeletedItem appEvent={appEvent} />
    }
    if (type === CUSTOMER_ASSET_UPDATED) {
        return <CustomerAssetUpdatedItem appEvent={appEvent} />
    }
    if (type === CUSTOMER_ASSET_SUSPENDED) {
        return <CustomerAssetSuspendedItem appEvent={appEvent} />
    }
    if (type === APP_SETTINGS_UPDATED) {
        return <AppSettingsUpdatedItem appEvent={appEvent} />
    }
    return (
        <TimelineItem
            createdAt={appEvent.createdAt}
            user={appEvent.user}
        />
    )
}

export default LogTimelineItem
