import React from 'react'
import { notification } from 'antd'
import { useParams } from 'react-router-dom'
import CustomerPageError from '../../layout/CustomerPageError'
import CustomerOpeningHoursPage from './CustomerOpeningHoursPage'
import useCustomerOpeningHoursPageQuery from './useCustomerOpeningHoursPageQuery'
import useUpdateCustomerOpeningHoursMutation from './useUpdateCustomerOpeningHoursMutation'
import useUpdateCustomerOpeningHoursFormState from './useUpdateCustomerOpeningHoursFormState'

const activeMenuItem = 'openingHours'

const CustomerOpeningHoursPageWithState = () => {
    const params = useParams()
    const customerId = parseInt(params.customerId, 10)
    const {
        error,
        isLoading,
        customer,
        customerOpeningHours,
    } = useCustomerOpeningHoursPageQuery({ variables: { customerId } })
    const formState = useUpdateCustomerOpeningHoursFormState({ customerOpeningHours })
    const [
        updateCustomerOpeningHours,
        { loading: isUpdating },
    ] = useUpdateCustomerOpeningHoursMutation()

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem={activeMenuItem}
            />
        )
    }

    const handleUpdateCustomerOpeningHours = async () => {
        if (formState.validate()) {
            try {
                const input = formState.valuesToInput()
                await updateCustomerOpeningHours({
                    variables: {
                        customerId,
                        input,
                    }
                })
                notification.success({
                    message: 'Customer opening hours updated!',
                    description: 'You successfully updated the customer opening hours!',
                })
            } catch (e) {
                notification.error({
                    message: 'Customer opening hours not updated!',
                    description: `Something went wrong. The following error occured: ${e}`,
                })
            }
        }
    }

    return (
        <CustomerOpeningHoursPage
            activeMenuItem={activeMenuItem}
            customer={customer}
            isLoading={isLoading}
            isUpdating={isUpdating}
            formState={formState}
            onUpdateCustomerOpeningHours={handleUpdateCustomerOpeningHours}
        />
    )
}

export default CustomerOpeningHoursPageWithState
