import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import AppWithState from './components/AppWithState'
import initializeSentry from './services/sentry/initSentry'
import getApolloClient from './apollo/getApolloClient'

initializeSentry()

const history = createBrowserHistory()
const apolloClient = getApolloClient()

ReactDOM.render(
    <AppWithState
        history={history}
        apolloClient={apolloClient}
    />,
    document.getElementById('root'),
)
