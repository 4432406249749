import { gql, useMutation } from '@apollo/client'
import CustomerAssetsList from './CustomerAssetsList'

const mutation = gql`
    mutation createCustomerAsset($customerId: Int!, $assetId: Int!) {
        createCustomerAsset(customerId: $customerId, assetId: $assetId) {
            customerAsset {
                ...CustomerAssetsList
            }
        }
    }
    ${CustomerAssetsList.FRAGMENT}
`

const useCreateCustomerAssetMutation = (options) => (
    useMutation(mutation, options)
)

export default useCreateCustomerAssetMutation
