import React from 'react'
import {
    CaretUpOutlined,
    CaretDownOutlined,
} from '@ant-design/icons'
import * as constants from '../../../../util/constants'
import { formatCurrencyNotation } from '../../../../util/currency'

const { SIDE_BUY } = constants

const AssetCardPrice = ({
    side,
    currencyCode,
    currencySymbol,
    unitCode,
    previousPrice,
    price,
}) => {
    let priceHeader = 'CUSTOMER SELLS AT'
    if (side === SIDE_BUY) {
        priceHeader = 'CUSTOMER BUYS AT'
    }

    let icon = null
    let profitableClass = ''
    if (previousPrice !== null) {
        if (price < previousPrice) {
            icon = <CaretDownOutlined />
            if (side === SIDE_BUY) {
                profitableClass = 'text-green-500'
            } else {
                profitableClass = 'text-red-500'
            }
        }
        if (price > previousPrice) {
            icon = <CaretUpOutlined />
            if (side === SIDE_BUY) {
                profitableClass = 'text-red-500'
            } else {
                profitableClass = 'text-green-500'
            }
        }
    }

    return (
        <>
            <div className="flex">
                <p className="text-gray-500">{priceHeader}</p>
                <div className={`ml-1 ${profitableClass}`}>
                    {icon}
                </div>
            </div>
            <div className="flex items-start">
                <span className="mt-2">{currencySymbol}</span>
                <h1 className={`text-3xl mx-2 font-bold ${profitableClass}`}>
                    {formatCurrencyNotation(price, currencyCode)}
                </h1>
                <span className="mt-2">{unitCode}</span>
            </div>
        </>
    )
}

export default AssetCardPrice
