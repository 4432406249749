import React from 'react'
import moment from 'moment'
import { Table, Button, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import deleteCustomerUser from '../../../../api/deleteCustomerUser'
import UsersTableDeleteButtonWithState from '../../users/table/UsersTableDeleteButtonWithState'
import UsersTableResendMailButton from '../../users/table/UsersTableResendMailButton'
import HasPermissions from '../../../util/HasPermissions'
import TableActions from '../../../util/TableActions'

const CustomerUsersTable = ({
    users,
    onDeleteComplete,
    onUpdateCustomerUser,
    onResendEmailInvitation,
    customerId,
}) => {
    const customers = [{
        title: 'Name',
        key: 'name',
        render: (text, record) => `${record.firstName} ${record.lastName}`,
        width: 160,
    }, {
        title: 'Email',
        dataIndex: 'email',
        width: 220,
    }, {
        title: 'Phone',
        dataIndex: 'phone',
        width: 140,
    }, {
        title: 'Invited',
        dataIndex: 'createdAt',
        render: (createdAt) => moment(createdAt)
            .format('DD/MM/YY'),
        width: 90,
    }, {
        title: 'Action',
        dataIndex: 'id',
        align: 'center',
        width: 120,
        render: (id, user) => (
            <HasPermissions
                roles={[
                    HasPermissions.ROLES.ADMIN,
                ]}
            >
                <TableActions>
                    <Tooltip title="Edit user">
                        <Button
                            ghost
                            icon={<EditOutlined />}
                            type="primary"
                            shape="circle"
                            size="small"
                            className="border-none"
                            onClick={() => onUpdateCustomerUser(user)}
                        />
                    </Tooltip>
                    <UsersTableDeleteButtonWithState
                        userId={user.id}
                        deleteUserMutation={deleteCustomerUser}
                        onDeleteComplete={onDeleteComplete}
                        customerId={customerId}
                    />
                    <UsersTableResendMailButton
                        onResendEmailInvitation={() => onResendEmailInvitation(user)}
                    />
                </TableActions>
            </HasPermissions>
        ),
    }]

    return (
        <Table
            useFixedHeader
            rowKey="id"
            columns={customers}
            dataSource={users}
            pagination={false}
        />
    )
}

export default CustomerUsersTable
