import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import MarketDataPlaceholder from './MarketDataPlaceholder'

const MarketDataUnavailable = ({
    assetName,
}) => (
    <MarketDataPlaceholder
        assetName={assetName}
        title="Unavailable"
        text={<>This asset is currently not available. Please try again later.</>}
        icon={(
            <ExclamationCircleOutlined
                className="text-3xl mr-4"
            />
        )}
    />
)

export default MarketDataUnavailable
