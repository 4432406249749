import React from 'react'
import { List } from 'antd'
import { gql } from '@apollo/client'
import AssetCard from '../assets/AssetCard'
import AssetEditButton from '../assets/AssetEditButton'
import AssetSuspendButton from '../assets/AssetSuspendButton'
import CustomerAssetDeleteButton from './CustomerAssetDeleteButton'

const FRAGMENT = gql`
    fragment CustomerAssetsList on CustomerAsset {
        id
        density
        suspended
        available
        premium
        conversionRateExtra
        asset {
            ...AssetCard
        }
    }
    ${AssetCard.FRAGMENT}
`

const toAsset = ({
    asset,
    premium,
    density,
    conversionRateExtra,
    ...customerAsset
}) => ({
    ...customerAsset,
    name: asset.name,
    code: asset.code,
    premium: premium === null ? asset.premium : premium,
    density: density === null ? asset.density : density,
    conversionRateExtra: conversionRateExtra === null ? (
        asset.conversionRateExtra
    ) : (
        conversionRateExtra
    ),
})

const CustomerAssetsList = ({
    customerAssets,
    isDeletingCustomerAsset,
    onEditCustomerAsset,
    onDeleteCustomerAsset,
    onToggleSuspendCustomerAsset,
}) => (
    <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={customerAssets.map(toAsset)}
        renderItem={(asset) => (
            <List.Item>
                <AssetCard
                    asset={asset}
                    actions={[
                        <AssetEditButton
                            asset={asset}
                            onClick={onEditCustomerAsset}
                        />,
                        <AssetSuspendButton
                            suspended={asset.suspended}
                            onClick={() => onToggleSuspendCustomerAsset(asset)}
                        />,
                        <CustomerAssetDeleteButton
                            asset={asset}
                            isDeletingCustomerAsset={isDeletingCustomerAsset}
                            onDeleteCustomerAsset={onDeleteCustomerAsset}
                        />,
                    ]}
                />
            </List.Item>
        )}
    />
)

CustomerAssetsList.FRAGMENT = FRAGMENT

export default CustomerAssetsList
