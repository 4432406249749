import React from 'react'
import { gql } from '@apollo/client'
import { Button, Menu, Dropdown } from 'antd'
import HasPermissions from '../../util/HasPermissions'

const FRAGMENT = gql`
    fragment CustomerAssetCreateButton on Asset {
        id
        name
    }
`

const CustomerAssetCreateButton = ({
    disabled,
    assets,
    loading,
    onCreateCustomerAsset,
}) => (
    <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
        <Dropdown
            trigger={['click']}
            disabled={disabled}
            overlay={(
                <Menu>
                    {assets.map((asset) => (
                        <Menu.Item
                            key={asset.id}
                            onClick={() => onCreateCustomerAsset(asset.id)}
                        >
                            {asset.name}
                        </Menu.Item>
                    ))}
                </Menu>
            )}
        >
            <Button
                loading={loading}
                type="primary"
            >
                Add Asset
            </Button>
        </Dropdown>
    </HasPermissions>
)

CustomerAssetCreateButton.FRAGMENT = FRAGMENT

export default CustomerAssetCreateButton
