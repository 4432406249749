import React from 'react'
import clsx from 'clsx'

const TableActions = ({
    children,
}) => (
    <div className="flex justify-center">
        {children.map((child, index) => (
            <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={
                    clsx(index !== children.length - 1 && 'mr-2')
                }
            >
                {child}
            </div>
        ))}
    </div>
)

export default TableActions
