import { gql, useQuery } from '@apollo/client'

const QUERY = gql`
    query customerMarketData(
        $customerId: Int!,
        $assetCode: String!,
        $productVariationId: Int!,
    ) {
        adjustedCustomerMarketData(
            customerId: $customerId,
            assetCode: $assetCode,
            productVariationId: $productVariationId,
        ) {
            status
            sellPrice
            buyPrice
            currencyConversion
        }
    }
`

const useCustomerMarketDataQuery = (options) => (
    useQuery(QUERY, options)
)

export default useCustomerMarketDataQuery
