import React from 'react'
import { Card } from 'antd'
import classNames from 'classnames'
import { gql } from '@apollo/client'
import Tag from '../../util/Tag'
import { ADJUSTMENT_ACCURACY, PRICE_ACCURACY, CONVERSION_ACCURACY } from '../../../util/constants'

const FRAGMENT = gql`
    fragment AssetCard on Asset {
        id
        name
        code
        density
        suspended
        available
        premium
        conversionRateExtra
    }
`

const AssetCard = ({
    asset,
    actions,
}) => (
    <Card
        className={classNames({ suspended: asset.suspended })}
        title={asset.name}
        extra={(!asset.available && <Tag>UNAVAILABLE</Tag>)
            || (asset.suspended && <Tag>SUSPENDED</Tag>)}
        actions={actions}
    >
        <div className="w-full flex justify-between">
            <span>Name:</span>
            <strong>{asset.name}</strong>
        </div>
        <div className="w-full flex justify-between">
            <span>Code:</span>
            <strong>{asset.code} </strong>
        </div>
        <div className="w-full flex justify-between">
            <span>Premium:</span>
            <span><strong>{asset.premium / PRICE_ACCURACY}</strong> USD/MTON</span>
        </div>
        <div className="w-full flex justify-between">
            <span>Conversion rate extra:</span>
            <strong>{asset.conversionRateExtra / CONVERSION_ACCURACY}</strong>
        </div>
        <div className="w-full flex justify-between">
            <span>Density:</span>
            <strong>{asset.density / ADJUSTMENT_ACCURACY}</strong>
        </div>
    </Card>
)

AssetCard.FRAGMENT = FRAGMENT

export default AssetCard
